.slider {
  padding: 2rem 0;
  position: relative;
}

.slider .container {
  padding: 0 1rem 2rem;
}

@media only screen and (max-width: 767px) {
  .ws .slider .container {
    padding: 0 1rem;
  }
}

.two-columns-slider {
  width: 100%;
  padding: 2rem 0;
}

.two-columns-slider .swiper-container {
  margin: 0 50px;
}

.two-columns-slider .swiper-wrapper {
  align-items: center;
}

.two-columns-slider .swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
}

.two-columns-slider-1 {
  max-width: 960px;
  margin: 0 auto;
}

.two-columns-slider > div:not(.backgroundShape) {
  position: relative;
}

.swiperSlider__button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 50px;
  height: 50px;
  background: var(--blue-color);
  border: none;
  color: var(--white-color);
  padding: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.4s ease;
  z-index: 10;
}

.swiperSlider__button svg {
  width: 100%;
  max-height: 100%;
}

.swiperSlider__button:hover {
  cursor: pointer;
  background: var(--bunting-color-95);
}

.swiperSlider__prev {
  left: 0;
}

.swiperSlider__next {
  right: 0;
}

.coll .swiperSlider__button {
  background: var(--primary-color);
  color: var(--white-color);
}

.coll .swiperSlider__button:hover {
  background: var(--primary-color-hover);
}

.rc .swiperSlider__button {
  background: var(--primary-color);
  color: var(--white-color);
}

.rc .swiperSlider__button:hover {
  background: var(--primary-color-hover);
}

/* Slider Two Columns styles */
@media only screen and (min-width: 768px) {
  .two-columns-slider {
    padding: 3rem 0;
  }
}

.ws .two-columns-slider .container,
.ws .two-columns-slider .swiper-pagination {
  max-width: 960px;
}

.ws .two-columns-slider.full-width {
  padding: 0;
}

.ws .two-columns-slider.full-width .swiper-slide .container {
  max-width: 100%;
  width: 100%;
  padding: 0;
}

@media only screen and (min-width: 768px) {
  .ws .two-columns-slider.full-width .two-columns-slider__image > div {
    height: 700px;
    box-shadow: none;
  }
}

.ws .two-columns-slider.full-width .col-sm:first-child {
  align-items: center;
}

.ws .two-columns-slider.full-width .col-sm:first-child > div {
  max-width: 450px;
}

@media only screen and (max-width: 767px) {
  .ws .two-columns-slider.full-width .col-sm:first-child > div {
    padding: 1rem;
  }

  .ws .two-columns-slider.full-width .col-sm > div p {
    font-size: 1rem;
  }
}

@media only screen and (min-width: 768px) {
  .ws .two-columns-slider.full-width .col-sm:first-child > div:after {
    content: '';
    position: absolute;
    right: -1rem;
    top: 0;
    width: 100px;
    height: 100px;
    background: var(--primary-color);
    border-radius: 0 0 0 100px;
  }
}

@media only screen and (max-width: 768px) {
  .ws .two-columns-slider .swiper-pagination {
    display: none;
  }
}

.ws .two-columns-slider.full-width .swiper-pagination {
  width: 400px;
  justify-content: center;
  margin: 0;
  display: flex;
  position: absolute;
  left: 25%;
  bottom: 3rem;
  transform: translateX(-50%);
}

.two-columns-slider .col-sm {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.two-columns-slider .col-sm:first-child {
  transition: all 0.2s ease;
}

.two-columns-slider .swiper-slide h2 {
  color: var(--primary-color);
  margin: 0;
  font-size: 3.5rem;
  font-family: 'Inter', sans-serif;
}

@media only screen and (min-width: 768px) {
  .two-columns-slider .swiper-slide h2 {
    font-size: 5rem;
  }
}

.ws .two-columns-slider.full-width .swiper-slide h2 {
  color: var(--bunting-color);
}

.two-columns-slider .swiper-slide p {
  font-size: 1.5rem;
  color: var(--primary-color);
  margin: 0;
}

.two-columns-slider .swiper-slide p span {
  color: var(--primary-color);
  font-size: 2rem;
}

.two-columns-slider__image {
  transition: all 0.4s ease;
}

.two-columns-slider__image svg {
  max-height: 180px;
}

.two-columns-slider__image > div {
  box-shadow: 0 0 10px 1px #fff;
  height: 180px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

@media only screen and (max-width: 767px) {
  .two-columns-slider__image svg {
    max-width: 90%;
    padding: 20px 0;
  }
}

@media only screen and (max-width: 576px) {
  .swiper-slide-prev .two-columns-slider__image,
  .swiper-slide-next .two-columns-slider__image {
    padding: 0 1rem 0.5rem;
  }
}

@media only screen and (min-width: 768px) {
  .two-columns-slider__image > div {
    height: 270px;
  }
}

@media only screen and (max-width: 767px) {
  .ws .two-columns-slider.full-width .two-columns-slider__image {
    padding: 0 2rem;
  }
}

.two-columns-slider .swiper-button-prev,
.swiper-button-next {
  color: var(--primary-color);
}

/* Collision updates */
.coll .two-columns-slider .swiper-slide h2,
.coll .two-columns-slider .swiper-slide p {
  color: var(--black-color);
}

/* Collision updates */
.rc .two-columns-slider .swiper-slide h2,
.rc .two-columns-slider .swiper-slide p {
  color: var(--black-color);
}

/* Image text modal styles */
.image-text-modal-slider.full-width {
  padding: 0;
  background: var(--bunting-color);
}

@media only screen and (min-width: 1024px) {
  .image-text-modal-slider.full-width .image-text-modal-slider__image {
    height: 600px;
  }
}

.image-text-modal-slider.full-width .swiper-pagination {
  width: auto;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 2rem;
  padding: 0;
}

.image-text-modal-slider.full-width .swiper-pagination-bullet {
  background: var(--white-color);
}

.image-text-modal-slider .container {
  max-width: 960px;
  padding: 0 1rem;
}

.image-text-modal-slider.full-width .swiper-slide .container {
  max-width: 100%;
  width: 100%;
  padding: 0;
}

.image-text-modal-slider .row {
  position: relative;
}

.image-text-modal-slider .swiper-wrapper {
  display: flex;
  align-items: flex-start;
}

@media only screen and (min-width: 768px) {
  .image-text-modal-slider .swiper-slide,
  .image-text-modal-slider .swiper-slide > div,
  .image-text-modal-slider .container,
  .image-text-modal-slider .row,
  .image-text-modal-slider__image {
    min-height: 450px;
  }
}

.image-text-modal-slider .col-sm {
  display: flex;
}

.image-text-modal-slider__image {
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 250px;
  box-shadow: 0px 0px 3px 3px rgba(255, 255, 255, 0.25);
}

@media only screen and (min-width: 768px) {
  .image-text-modal-slider__image {
    min-height: 450px;
    width: 60%;
    box-shadow: none;
  }
}

@media only screen and (min-width: 1024px) {
  .image-text-modal-slider__image {
    height: 550px;
    width: 60%;
  }
}

.image-text-modal-slider__content {
  padding: 1rem 0;
}

@media only screen and (max-width: 767px) {
  .image-text-modal-slider__content p {
    margin-bottom: 0;
  }

  .image-text-modal-slider .col-sm {
    flex-direction: column;
  }

  .image-text-modal-slider__content {
    opacity: 0;
    transition: all 0.4s ease;
  }

  .swiper-slide-active .image-text-modal-slider__content {
    opacity: 1;
  }

  .slider.image-text-modal-slider .swiperSlider__button {
    top: 170px;
  }
}

.image-text-modal-slider__content h2 {
  color: var(--secondary-color);
}

.ws .image-text-modal-slider__content h2,
.ws .image-text-modal-slider__content h2 a {
  color: var(--bunting-color);
  font-family: 'alverata', serif;
}

.ws .image-text-modal-slider__content h2 a:hover {
  color: var(--butterfly-color);
}

@media only screen and (max-width: 767px) {
  .image-text-modal-slider.full-width .image-text-modal-slider__content {
    padding: 1rem 2rem;
  }

  .image-text-modal-slider.full-width .image-text-modal-slider__content h2,
  .image-text-modal-slider.full-width .image-text-modal-slider__content h2 a {
    color: var(--white-color);
  }
}

.image-text-modal-slider .swiper-pagination {
  max-width: 960px;
  width: 100%;
  text-align: left;
  margin: 0 auto;
  position: initial;
  padding: 1rem 0 0 2rem;
  height: 36px;
}

.image-text-modal-slider__readMore {
  padding: 0;
  border: none;
  background: transparent;
  transition: all 0.2s ease;
  font-size: 1rem;
  color: var(--tundora-color);
}

.image-text-modal-slider__readMore i {
  transition: all 0.2s ease;
}

.image-text-modal-slider__readMore:hover {
  opacity: 0.8;
  cursor: pointer;
}

.image-text-modal-slider__readMore:hover i {
  margin-left: 5px;
}

@media only screen and (min-width: 768px) {
  .image-text-modal-slider__content {
    margin-left: -20%;
    background: rgba(255, 255, 255, 0.8);
    width: 60%;
    padding: 3rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .image-text-modal-slider__content > div {
    max-width: 400px;
  }

  .image-text-modal-slider.full-width .image-text-modal-slider__content {
    background: rgba(25, 27, 76, 0.5);
    color: var(--white-color);
  }

  .rc .image-text-modal-slider.full-width .image-text-modal-slider__content {
    background: rgba(239, 67, 67, 0.5);
  }

  .image-text-modal-slider.full-width .image-text-modal-slider__content h2,
  .image-text-modal-slider.full-width .image-text-modal-slider__content h2 a {
    color: var(--white-color);
  }

  .coll .image-text-modal-slider.full-width .col-sm {
    background: var(--black-color);
  }

  .coll .image-text-modal-slider.full-width .image-text-modal-slider__content {
    background: rgba(0, 0, 0, 0.4);
  }
}

.ws .image-text-modal-slider .swiper-pagination {
  padding: 1.5rem 0 0 2.5rem;
  height: 44px;
}

.ws .image-text-modal-slider .swiper-pagination-bullet {
  background: var(--sun-color);
}

@media only screen and (max-width: 767px) {
  .ws .image-text-modal-slider .backgroundShape__container {
    display: none;
  }

  .ws .image-text-modal-slider__content {
    align-items: flex-end;
    display: flex;
    padding: 1rem;
  }

  .image-text-modal-slider.full-width .image-text-modal-slider__content {
    position: absolute;
    background: rgba(25, 27, 76, 0.7);
    height: 100%;
    color: #fff;
  }
}

/* Logo soup styles */
.logo-soup-slider {
  position: relative;
  overflow: hidden;
  padding: 1rem 0;
}

.logo-soup-slider__wrap {
  position: relative;
}

.slider.logo-soup-slider .container {
  padding: 0 2rem;
}

.logo-soup-slider h2 {
  padding: 0;
  font-weight: normal;
}

.ws .logo-soup-slider h2 {
  font-family: 'Inter', sans-serif;
}

.logo-soup-slider h3 {
  padding: 0;
}

.logo-soup-slider .swiper-wrapper {
  align-items: center;
}

.logo-soup-slider .swiper-slide {
  display: flex;
  justify-content: center;
}

.logo-soup-slider .swiper-slide > div {
  padding: 20px 0;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
}

.logo-soup-slider div img {
  max-width: 85%;
}

@media only screen and (min-width: 768px) {
  .logo-soup-slider {
    padding: 3rem 0;
  }

  .logo-soup-slider div img {
    max-width: 110px;
    max-height: 60px;
  }

  .logo-soup-slider .swiper-slide > div {
    padding: 40px 0;
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }

  .ws .logo-soup-slider {
    padding: 3rem 0;
  }
}

@media only screen and (min-width: 1024px) {
  .logo-soup-slider div img {
    max-width: 135px;
    max-height: 65px;
  }
}

@media only screen and (min-width: 1280px) {
  .logo-soup-slider {
    padding: 4rem 0;
  }

  .logo-soup-slider div img {
    max-width: 190px;
  }
}

.ws .bg-blue.logo-soup-slider h3 {
  color: var(--white-color);
}

.logo-soup-slider.venture-green-blue-bg {
  color: var(--white-color);

  h2 {
    color: var(--white-color);
  }

  .swiperSlider__button {
    background: $venture-purple;
    color: var(--white-color);
  }
}

.logo-soup-slider.venture-purple-bg {
  h2 {
    color: var(--white-color);
  }
}

.coll .orange-bg .swiperSlider__button {
  background-color: var(--black-color);
  color: var(--white-color);
}

.coll .orange-bg .swiperSlider__button:hover {
  opacity: 0.9;
}

/* Video slider */
.video-slider {
  overflow: hidden;
  max-width: 100%;
}
.video-slider span h2 {
  font-weight: normal;
}

.video-slider .videoWrapper {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 20px;
  height: 0;
  overflow: hidden;
}

.video-slider .swiper-container:nth-of-type(1) iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.video-slider .swiper-container:nth-of-type(2) {
  padding-top: 20px;
}

.video-slider .swiper-container:nth-of-type(2) .swiper-wrapper .overlay svg {
  color: #ee3d4c;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30px;
  height: auto;
}

.video-slider .swiper-container:nth-of-type(2) .swiper-wrapper .overlay:hover {
  cursor: pointer;
}

.video-slider .swiper-container:nth-of-type(2) .swiper-wrapper .swiper-slide {
  width: auto;
  opacity: 0.8;
  margin-right: 20px;
}

.video-slider .swiper-container:nth-of-type(2) .swiper-wrapper .swiper-slide img {
  height: 200px;
}

.video-slider .swiper-container:nth-of-type(2) .swiper-wrapper .swiper-slide.swiper-slide-active {
  opacity: 1;
}

@media only screen and (max-width: 768px) {
  .video-slider .swiper-container:nth-of-type(2) {
    display: none;
  }
}

/* multiple-slides */
.slider.multiple-slides {
  overflow: hidden;
}

@media only screen and (min-width: 768px) {
  .slider.multiple-slides .multiple-slides__gal-wrap,
  .slider.multiple-slides .multiple-slides__thumb-wrap {
    max-width: 100%;
  }
}

@media only screen and (min-width: 992px) {
  .slider.multiple-slides .multiple-slides__gal-wrap,
  .slider.multiple-slides .multiple-slides__thumb-wrap {
    max-width: 960px;
  }
}

@media only screen and (min-width: 1200px) {
  .slider.multiple-slides .multiple-slides__gal-wrap,
  .slider.multiple-slides .multiple-slides__thumb-wrap {
    max-width: 1240px;
  }
}

.coll .slider.multiple-slides .multiple-slides__gal-wrap,
.coll .slider.multiple-slides .multiple-slides__thumb-wrap,
.coll .slider.multiple-slides .multiple-slides__gal-wrap > .container,
.coll .slider.multiple-slides .multiple-slides__thumb-wrap > .container,
.rc .slider.multiple-slides .multiple-slides__gal-wrap,
.rc .slider.multiple-slides .multiple-slides__thumb-wrap,
.rc .slider.multiple-slides .multiple-slides__gal-wrap > .container,
.rc .slider.multiple-slides .multiple-slides__thumb-wrap > .container {
  width: 100%;
  max-width: none;
}

// removed for now
// .coll .slider.multiple-slides .multiple-slides__thumb-wrap .swiper-slide-active,
// .rc .slider.multiple-slides .multiple-slides__thumb-wrap .swiper-slide-active {
//     border: 1px solid var(--primary-color);
// }

@media only screen and (max-width: 767px) {
  .slider.multiple-slides {
    padding-bottom: 0;
  }
}

.slider.multiple-slides .multiple-slides__gal-wrap,
.slider.multiple-slides .multiple-slides__thumb-wrap {
  margin: 0 auto;
}

.slider.multiple-slides .swiper-pagination-bullet {
  background: var(--orange-color);
}

.coll .slider.multiple-slides .swiper-pagination-bullet {
  background: var(--white-color);
}

.coll .inverted-color.slider.multiple-slides .swiper-pagination-bullet {
  background: var(--primary-color);
}

.slider.multiple-slides .container > div:not(.newSwirl) {
  position: relative;
}

.slider.multiple-slides .container > div .dot-container {
  height: 250px;
}

.slider.multiple-slides .swiper-wrapper {
  align-items: flex-start;
}

@media only screen and (max-width: 991px) {
  .slider.multiple-slides .multiple-slides__thumb-wrap .container {
    padding-right: 50px;
    padding-left: 50px;
  }
}

@media only screen and (max-width: 768px) {
  .slider.multiple-slides .multiple-slides__thumb-wrap .container {
    padding-right: 35px;
    padding-left: 35px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1300px) {
  .slider.multiple-slides .multiple-slides__thumb-wrap .container {
    padding-right: 3rem;
    padding-left: 3rem;
  }
}

.slider.multiple-slides .multiple-slides__thumb {
  height: 150px;
}

@media only screen and (min-width: 768px) {
  .slider.multiple-slides .multiple-slides__thumb {
    height: 220px;
  }
}

.slider.multiple-slides .multiple-slides__thumb-wrap {
  max-width: 100%;
  position: relative;
  margin-bottom: 1rem;
}

.slider.multiple-slides .multiple-slides__thumb-wrap .container {
  padding-bottom: 0;
}

.slider.multiple-slides .multiple-slides__thumb-prev {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background: var(--bunting-color);
  border: none;
  color: var(--white-color);
  height: 35px;
  width: 35px;
  z-index: 1;
}

.slider.multiple-slides .multiple-slides__thumb-prev:after {
  content: '\f053';
  font-family: 'Font Awesome 5 Pro';
  font-size: 1.5rem;
}

@media only screen and (min-width: 768px) {
  .slider.multiple-slides .multiple-slides__thumb-prev {
    height: 50px;
    width: 50px;
  }

  .slider.multiple-slides .multiple-slides__thumb-prev:after {
    font-size: 2rem;
  }

  .slider.multiple-slides .multiple-slides__thumb-wrap {
    margin-bottom: 3rem;
  }
}

.slider.multiple-slides .multiple-slides__thumb-next {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  background: var(--bunting-color);
  border: none;
  color: var(--white-color);
  height: 35px;
  width: 35px;
  z-index: 1;
}

.slider.multiple-slides .multiple-slides__thumb-next:after {
  content: '\f054';
  font-family: 'Font Awesome 5 Pro';
  font-size: 1.5rem;
}

@media only screen and (min-width: 768px) {
  .slider.multiple-slides .multiple-slides__thumb-next {
    height: 50px;
    width: 50px;
  }

  .slider.multiple-slides .multiple-slides__thumb-next:after {
    font-size: 2rem;
  }
}

.slider.multiple-slides .multiple-slides__thumb-prev:hover,
.slider.multiple-slides .multiple-slides__thumb-next:hover {
  cursor: pointer;
}

.coll .slider.multiple-slides .multiple-slides__thumb-prev,
.coll .slider.multiple-slides .multiple-slides__thumb-next {
  color: var(--black-color);
  background: var(--primary-color);
}

.slider.multiple-slides .multiple-slides__thumb > div {
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center center;
  position: relative;
}

.slider.multiple-slides .multiple-slides__thumb span {
  position: absolute;
  left: 1rem;
  bottom: 1rem;
  color: var(--white-color);
  font-size: 1rem;
  font-weight: 400;
  text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.9);
}

.coll .slider.multiple-slides .multiple-slides__gal-wrap .container {
  padding: 0;
}

.rc .slider.multiple-slides .multiple-slides__gal-wrap .container {
  padding: 0;
}

@media only screen and (max-width: 767px) {
  .slider.multiple-slides .multiple-slides__gal-wrap .container {
    padding: 0 0 4rem;
  }

  .slider.multiple-slides .multiple-slides__gal-wrap .container .newSwirl {
    bottom: 0;
  }
}

.slider.multiple-slides .multiple-slides__slide {
  height: auto;
  display: flex;
  flex-wrap: wrap;
}

.slider.multiple-slides .multiple-slides__slide > div {
  width: 100%;
}

@media only screen and (min-width: 768px) {
  .slider.multiple-slides .multiple-slides__slide {
    height: 550px;
  }

  .slider.multiple-slides .multiple-slides__slide > div {
    width: 50%;
  }
}

.slider.multiple-slides .multiple-slides__slide .multiple-slides__slide-image {
  background-position: center center;
  background-size: cover;
  height: 150px;
  @media only screen and (min-width: 768px) {
    height: 100%;
  }
}

.slider.multiple-slides .multiple-slides__slide-content {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5rem 2rem;
}

.coll .multiple-slides__slide-content,
.coll .multiple-slides__slide-content h2 {
  background: var(--primary-color);
  color: var(--white-color);
}

.coll .inverted-color .multiple-slides__slide-content,
.coll .inverted-color .multiple-slides__slide-content h2 {
  background: var(--white-color);
  color: var(--primary-color);
}

.rc .multiple-slides__slide-content {
  background: var(--primary-color);
  color: var(--white-color);
}

.rc .multiple-slides__slide-content h2 {
  color: var(--white-color);
}

.rc .inverted-color .multiple-slides__slide-content {
  background: var(--white-color);
}

.slider.multiple-slides .multiple-slides__slide-content > div {
  max-width: 450px;
}

.slider.multiple-slides .multiple-slides__slide-content h2 {
  padding: 0;
  margin-bottom: 0.5rem;
}

.ws .slider.multiple-slides .multiple-slides__slide-content h2 {
  font-family: 'alverata', serif;
}

.slider.multiple-slides .slider-prev,
.slider.multiple-slides .slider-next {
  font-family: 'Font Awesome 5 Pro';
  border: none;
  background: no-repeat;
  color: var(--white-color);
  font-size: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 50px;
  height: 50px;
  background: var(--bunting-color);
  padding: 0;
  z-index: 10;
}

.slider.multiple-slides .slider-prev:hover,
.slider.multiple-slides .slider-next:hover {
  cursor: pointer;
  opacity: 0.95;
  transition: all 0.2s;
}

.slider.multiple-slides .slider-next {
  right: 0;
}

.slider.multiple-slides .slider-next:after {
  content: '\f054';
  background: none;
  font-size: 2rem;
}

.slider.multiple-slides .slider-prev {
  left: 0;
}

.slider.multiple-slides .slider-prev:after {
  content: '\f053';
  background: none;
  font-size: 2rem;
}

.slider.multiple-slides .swiper-pagination {
  padding: 0 2rem;
  position: absolute;
  right: 0;
  bottom: 1rem;
  z-index: 1;
  width: 50%;
  justify-content: center;
}

//  NS stuff
.nsShape {
  .swiper-container {
    padding: 1rem;
  }
  .swiper-slide.multiple-slides__slide.swiper-slide-active:after {
    content: '';
    position: absolute;
    top: -1rem;
    left: -1rem;
    width: 50%;
    height: 120px;
    background: var(--ns-violet-color);
    z-index: -1;
    @media only screen and (min-width: 768px) {
      top: initial;
      bottom: -1rem;
    }
  }
  &.slider.multiple-slides .multiple-slides__thumb {
    border: 2px solid transparent;
  }
  &.slider.multiple-slides .multiple-slides__thumb.swiper-slide-active {
    border: 2px solid var(--ns-violet-color);
  }
  &.slider.multiple-slides .multiple-slides__thumb-prev,
  &.slider.multiple-slides .multiple-slides__thumb-next,
  &.slider.multiple-slides .swiper-pagination-bullet {
    background-color: var(--ns-violet-color);
  }
  &.slider.multiple-slides .container > h2 {
    font-family: 'proxima-nova';
    text-transform: uppercase;
    max-width: 750px;
    margin: auto;
    font-size: 1.5rem;
    line-height: 1em;
    color: var(--ns-blue-color);
    @media only screen and (min-width: 768px) {
      font-size: 4rem;
    }
  }
  &.slider.multiple-slides .multiple-slides__slide-content h2 {
    color: var(--ns-violet-color);
    font-family: 'proxima-nova';
    text-transform: uppercase;
  }
  .btn-primary {
    color: var(--ns-violet-color);
    font-weight: 600;
    border: 2px solid;
    &:hover {
      background-color: var(--ns-violet-color);
      color: var(--white-color);
      border-color: var(--ns-violet-color);
    }
  }
}

/* Advanced stats slider */
.advancedStats__wrapper .swiperSlider__button {
  background: var(--primary-color);
}

@media only screen and (max-width: 767px) {
  .advancedStats {
    justify-content: center;
    width: 100%;
    align-items: flex-start;
  }

  .advancedStats__item.doubleHeight {
    height: 170px;
    width: 100%;
    align-items: flex-start;
    justify-content: flex-start;
  }
}

.advancedStats__item {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  position: relative;
}

@media only screen and (min-width: 768px) {
  .advancedStats__item {
    margin-bottom: 2rem;
  }
}

.advancedStats__item h3 {
  font-size: 26px;
  width: 100%;
  margin: 0;
}

.ws .advancedStats__item h3 {
  color: var(--primary-color);
}

.advancedStats__item p {
  color: var(--primary-color);
  font-size: 56px;
  width: 100%;
  font-weight: 600;
  margin-bottom: 0;
}

.coll .two-columns-slider .swiper-slide .advancedStats__item p {
  color: var(--primary-color);
}

.advancedStats__item-svgWrap {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
}

.advancedStats__item svg {
  max-width: 100%;
  max-height: 100%;
}

.advancedStats__item.doubleHeight > div:first-child {
  position: relative;
  z-index: 1;
}

.advancedStats__item.doubleHeight .advancedStats__item-svgWrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.advancedStats__item.doubleHeight .advancedStats__item-svgWrap svg {
  width: 100%;
  height: 100%;
}

@media only screen and (min-width: 768px) {
  .advancedStats {
    padding-top: 2rem;
  }

  .advancedStats__item {
    align-items: flex-end;
    justify-content: space-between;
    flex-direction: row;
    height: 120px;
  }

  .advancedStats__item svg {
    max-height: initial;
  }

  .advancedStats__item.doubleHeight {
    height: 300px;
    padding: 2rem 0;
    align-items: flex-start;
  }

  .advancedStats__item.doubleHeight .advancedStats__item-svgWrap {
    left: -1rem;
    top: 2rem;
    width: 100%;
    height: calc(100% - 1rem);
  }

  .advancedStats__item-svgWrap {
    height: 100%;
  }
}

/* bg variants */
.slider.primary-bg h2,
.slider.secondary-bg h2,
.slider.purple-bg h2,
.slider.yellow-bg h2,
.slider.blue-bg h2,
.slider.orange-bg h2,
.slider.black-bg h2 {
  color: var(--white-color);
}

/* Circle css */
.isVisible .circleSvg #Oval-3,
.swiper-slide-active .circleSvg #Oval-3 {
  animation: circleSvgRotate 2s ease-in-out;
  transform-origin: 50% 50%;
}

@keyframes circleSvgRotate {
  from {
    -webkit-transform: rotateZ(0deg);
  }

  to {
    -webkit-transform: rotateZ(360deg);
  }
}

/* Lines css */
.lineSvg7 path {
  stroke-dasharray: 320;
  stroke-dashoffset: 320;
}

.lineSvg7 circle {
  cx: 0;
}

.isVisible .lineSvg7 path,
.swiper-slide-active .lineSvg7 path {
  animation: dash 3s ease-out;
  stroke-dashoffset: 0;
}

.isVisible .lineSvg7 circle,
.swiper-slide-active .lineSvg7 circle {
  cx: 63%;
  transition: all 1.76s ease-out;
}

@keyframes dash {
  from {
    stroke-dashoffset: 320;
  }

  to {
    stroke-dashoffset: 0;
  }
}

.lineSvg4 path {
  stroke-dasharray: 320;
  stroke-dashoffset: 320;
}

.lineSvg4 circle {
  cx: 0;
}

.isVisible .lineSvg4 path,
.swiper-slide-active .lineSvg4 path {
  animation: dash 2s linear;
  stroke-dashoffset: 0;
}

.isVisible .lineSvg4 circle,
.swiper-slide-active .lineSvg4 circle {
  cx: 40%;
  transition: all 0.8s linear;
}

@keyframes dash {
  from {
    stroke-dashoffset: 320;
  }

  to {
    stroke-dashoffset: 0;
  }
}

/* Graph css */
@media only screen and (max-width: 767px) {
  .graphSvg {
    margin-top: -50px;
  }
}

.graphSvg #Path-3 {
  stroke-dasharray: 350;
  stroke-dashoffset: 350;
}

.isVisible .graphSvg #Path-3,
.swiper-slide-active .graphSvg #Path-3 {
  animation: dash2 1s linear;
  stroke-dashoffset: 0;
}

@keyframes dash2 {
  from {
    stroke-dashoffset: 350;
  }

  to {
    stroke-dashoffset: 0;
  }
}

.graphSvg #Oval-3 {
  opacity: 0;
  transition: all 0.2s ease;
}

.isVisible .graphSvg #Oval-3,
.swiper-slide-active .graphSvg #Oval-3 {
  transition-delay: 1s;
  opacity: 1;
}

.graphSvg #Path-2 {
  transform: scaleY(0);
  transform-origin: center bottom;
}

.isVisible .graphSvg #Path-2,
.swiper-slide-active .graphSvg #Path-2 {
  animation: draw 0.5s linear forwards;
}

@keyframes draw {
  to {
    transform: scaleY(1);
  }
}

.isVisible .worldSvg g path:nth-child(23),
.isVisible .worldSvg g path:nth-child(133),
.isVisible .worldSvg g path:nth-child(343),
.isVisible .worldSvg g path:nth-child(453),
.isVisible .worldSvg g path:nth-child(263),
.isVisible .worldSvg g path:nth-child(173),
.isVisible .worldSvg g path:nth-child(783),
.isVisible .worldSvg g path:nth-child(823),
.swiper-slide-active .worldSvg g path:nth-child(23),
.swiper-slide-active .worldSvg g path:nth-child(133),
.swiper-slide-active .worldSvg g path:nth-child(343),
.swiper-slide-active .worldSvg g path:nth-child(453),
.swiper-slide-active .worldSvg g path:nth-child(263),
.swiper-slide-active .worldSvg g path:nth-child(173),
.swiper-slide-active .worldSvg g path:nth-child(783),
.swiper-slide-active .worldSvg g path:nth-child(823) {
  animation: colorFill 2s infinite;
}

.isVisible .worldSvg g path:nth-child(123),
.isVisible .worldSvg g path:nth-child(233),
.isVisible .worldSvg g path:nth-child(243),
.isVisible .worldSvg g path:nth-child(653),
.isVisible .worldSvg g path:nth-child(563),
.isVisible .worldSvg g path:nth-child(673),
.isVisible .worldSvg g path:nth-child(783),
.isVisible .worldSvg g path:nth-child(393),
.isVisible .worldSvg g path:nth-child(123),
.swiper-slide-active .worldSvg g path:nth-child(233),
.swiper-slide-active .worldSvg g path:nth-child(243),
.swiper-slide-active .worldSvg g path:nth-child(653),
.swiper-slide-active .worldSvg g path:nth-child(563),
.swiper-slide-active .worldSvg g path:nth-child(673),
.swiper-slide-active .worldSvg g path:nth-child(783),
.swiper-slide-active .worldSvg g path:nth-child(393) {
  animation: colorFill 3s infinite;
}

.isVisible .worldSvg g path:nth-child(7),
.isVisible .worldSvg g path:nth-child(897),
.isVisible .worldSvg g path:nth-child(127),
.isVisible .worldSvg g path:nth-child(547),
.isVisible .worldSvg g path:nth-child(687),
.isVisible .worldSvg g path:nth-child(127),
.isVisible .worldSvg g path:nth-child(357),
.isVisible .worldSvg g path:nth-child(777),
.swiper-slide-active .worldSvg g path:nth-child(7),
.swiper-slide-active .worldSvg g path:nth-child(897),
.swiper-slide-active .worldSvg g path:nth-child(127),
.swiper-slide-active .worldSvg g path:nth-child(547),
.swiper-slide-active .worldSvg g path:nth-child(687),
.swiper-slide-active .worldSvg g path:nth-child(127),
.swiper-slide-active .worldSvg g path:nth-child(357),
.swiper-slide-active .worldSvg g path:nth-child(777) {
  animation: colorFill 4s infinite;
}

.isVisible .worldSvg g path:nth-child(9),
.isVisible .worldSvg g path:nth-child(129),
.isVisible .worldSvg g path:nth-child(239),
.isVisible .worldSvg g path:nth-child(459),
.isVisible .worldSvg g path:nth-child(679),
.isVisible .worldSvg g path:nth-child(899),
.isVisible .worldSvg g path:nth-child(909),
.isVisible .worldSvg g path:nth-child(329),
.swiper-slide-active .worldSvg g path:nth-child(9),
.swiper-slide-active .worldSvg g path:nth-child(129),
.swiper-slide-active .worldSvg g path:nth-child(239),
.swiper-slide-active .worldSvg g path:nth-child(459),
.swiper-slide-active .worldSvg g path:nth-child(679),
.swiper-slide-active .worldSvg g path:nth-child(899),
.swiper-slide-active .worldSvg g path:nth-child(909),
.swiper-slide-active .worldSvg g path:nth-child(329) {
  animation: colorFill 5s infinite;
}

.isVisible .worldSvg g path:nth-child(922),
.isVisible .worldSvg g path:nth-child(322),
.isVisible .worldSvg g path:nth-child(722),
.isVisible .worldSvg g path:nth-child(962),
.isVisible .worldSvg g path:nth-child(832),
.isVisible .worldSvg g path:nth-child(912),
.isVisible .worldSvg g path:nth-child(742),
.isVisible .worldSvg g path:nth-child(632),
.swiper-slide-active .worldSvg g path:nth-child(922),
.swiper-slide-active .worldSvg g path:nth-child(322),
.swiper-slide-active .worldSvg g path:nth-child(722),
.swiper-slide-active .worldSvg g path:nth-child(962),
.swiper-slide-active .worldSvg g path:nth-child(832),
.swiper-slide-active .worldSvg g path:nth-child(912),
.swiper-slide-active .worldSvg g path:nth-child(742),
.swiper-slide-active .worldSvg g path:nth-child(632) {
  animation: colorFill 6s infinite;
}

.isVisible .worldSvg g path:nth-child(6),
.isVisible .worldSvg g path:nth-child(126),
.isVisible .worldSvg g path:nth-child(376),
.isVisible .worldSvg g path:nth-child(746),
.isVisible .worldSvg g path:nth-child(526),
.isVisible .worldSvg g path:nth-child(209),
.isVisible .worldSvg g path:nth-child(56),
.isVisible .worldSvg g path:nth-child(46),
.swiper-slide-active .worldSvg g path:nth-child(6),
.swiper-slide-active .worldSvg g path:nth-child(126),
.swiper-slide-active .worldSvg g path:nth-child(376),
.swiper-slide-active .worldSvg g path:nth-child(746),
.swiper-slide-active .worldSvg g path:nth-child(526),
.swiper-slide-active .worldSvg g path:nth-child(246),
.swiper-slide-active .worldSvg g path:nth-child(56),
.swiper-slide-active .worldSvg g path:nth-child(46) {
  animation: colorFill 7s infinite;
}

.ws .worldSvg {
  --initial-color: var(--butterfly-color);
  --animated-color: var(--primary-color);
}

.coll .worldSvg {
  --initial-color: var(--blue-color);
  --animated-color: var(--primary-color);
}

@keyframes colorFill {
  0% {
    fill: var(--initial-color);
  }

  50% {
    fill: var(--animated-color);
  }

  100% {
    fill: var(--initial-color);
  }
}

/* Collision color fixes for advanced stats svgs */
.coll .graphSvg #Path-2,
.coll .lineSvg7 #Rectangle-7,
.coll .lineSvg4 #Rectangle-7 {
  fill: var(--blue-color);
}

.coll .circleSvg #Oval-1 {
  stroke: var(--blue-color);
}

.coll .lineSvg7 circle,
.coll .circleSvg #Oval-3 {
  fill: var(--primary-color);
}

.coll .graphSvg #Path-3,
.coll .lineSvg7 path,
.coll .lineSvg4 path,
.coll .circleSvg #Oval-2 {
  stroke: var(--primary-color);
}

.coll .circleSvg #Oval-3,
.coll .lineSvg4 circle,
.coll .lineSvg7 circle,
.coll .graphSvg #Oval-3 {
  fill: var(--white-color);
}

/* END Collision color fixes for advanced stats svgs */
