.form__field {
  &.richText {
    margin-bottom: .5rem;
    * {
      color: var(--primary-color);
    }
    p {
      margin-bottom: 0;
      font-size: .875rem;
    }
  }
}

.hero .form__field {
  &.richText {
    * {
      color: var(--white-color);
    }
  }
}