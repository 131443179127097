.schedule {
  position: relative;
}

.schedule__filter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1rem 0;
  max-width: 100%;
  flex-wrap: wrap;
}

@media only screen and (min-width: 768px) {
  .schedule__filter {
    padding: 1rem 2rem 0 2rem;
  }
}

.schedule__filter-search {
  width: 100%;
  max-width: calc(60% - 60px);
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 2rem;
}

.schedule__filter-search h2 {
  margin: 0 1rem 0 0;
  font-family: 'Inter', serif;
}

.schedule__filter-search input {
  border-color: var(--silver-color);
  background: var(--white-color);
  padding: 1.2rem 2.8rem 1.2rem 1.4rem;
  border-radius: 4px;
  font-family: 'Inter', serif;
  border-width: 2px;
}

.schedule__filter-search input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--black-color);
  opacity: 1;
  /* Firefox */
}

.schedule__filter-search input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--black-color);
}

.schedule__filter-search input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--black-color);
}

.schedule__filter-search svg {
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  width: 25px;
  pointer-events: none;

  path {
    fill: var(--black-color);
  }

  &.times {
    width: 20px;
    pointer-events: auto;

    &:hover {
      cursor: pointer;
    }

    path {
      fill: var(--secondary-color);
    }
  }
}

.schedule__filter-icon {
  position: relative;
  width: 40px;
  height: 40px;
  border: none;
  background: transparent;
  border-radius: 50%;
  background: var(--secondary-color);
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
  }

  span {
    background: var(--primary-color);
    border-radius: 50%;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--white-color);
    position: absolute;
    left: -5px;
    top: -5px;
    font-size: 0.85rem;
  }

  svg {
    width: 80%;
    height: 80%;
  }

  svg,
  svg path {
    fill: var(--white-color);
  }

  &.filterActive{
    background: white;
    border: 2px solid var(--secondary-color);
    svg path {
      fill: var(--secondary-color);
    }
  }

  @media only screen and (min-width: 768px) {
    width: 60px;
    height: 60px;
    svg {
      width: 70%;
      height: 70%;
    }
  }
}

.schedule__filter-filters {
  margin-top: 1rem;
  padding: 2rem;
  background: var(--concrete-color);
}

.schedule__filter-filters ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}

@media only screen and (max-width: 767px) {
  .schedule__filter-search {
    width: calc(100% - 152px);
    max-width: 100%;
    margin-right: 1rem;
    input {
      padding: 0.7rem 2.8rem 0.7rem 1.4rem;
    }
  }

  .schedule__filter-filters {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 1rem;
  }

  .schedule__filter-filters ul {
    max-height: 150px;
    overflow: scroll;
    margin: 0.5rem 0;
  }
}

.schedule__filter-filters ul li {
  padding: 0.5rem;
  font-size: 0.85rem;
  background-color: var(--white-color);
  color: var(--tundora-color);
  border: 1px solid var(--alto-color);
  margin: 0 0.5rem 0.5rem 0;
  transition: all 0.2s ease;
}

.schedule__filter-filters ul li:hover{
  cursor: pointer;
  background-color: var(--white-color); 
  border-color: var(--primary-color);
  color: var(--primary-color);
}

@media only screen and (min-width: 768px) {
  .schedule__filter-filters ul li {
    padding: 0.5rem 2rem;
    font-size: 1rem;
  }
}

.schedule__filter-filters ul li.active {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  color: var(--white-color);
}

.schedule__filter-filters h3 {
  margin-top: 0;
  color: var(--tundora-color);
}

.schedule__filter-filters .btn {
  margin: 1rem 0 0;
  padding: 0;
  color: var(--tundora-color);
  text-transform: initial;
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 1;
  .times {
    height: 22px;
    margin-right: 10px;
  }
}

/* Days list */
.fixed .schedule__days {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1002;
  background: var(--white-color);
  margin: 0;
  padding-top: 81px;
}

.schedule__days-today {
  color: var(--primary-color);
  padding: 1rem;
  height: 48px;
  font-size: 0.85rem;
}

.agenda.fixed .schedule__days-today {
  @media only screen and (max-width: 768px) {
    height: 60px;
  }
}

.schedule__days {
  margin-top: 1rem;
}

.schedule__days ul {
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  border-bottom: 1px solid var(--black-color);
  position: relative;
}

.ws .schedule__days ul {
  border-color: var(--bunting-color);
}

.ws .schedule__days ul li {
  color: var(--bunting-color);
  &:hover {
    color: var(--white-color);
  }
}

.schedule__days.infoTab ul li {
  width: 20%;
}

.schedule__days.infoTab ul li > div {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.schedule__days.infoTab ul li div span {
  display: flex;
  justify-content: center;
  width: 100%;
}

.schedule__days ul li:nth-child(1) {
  padding: 0 5px;
}

@media only screen and (min-width: 768px) {
  .schedule__days-today {
    padding: 0 2rem;
    height: 80px;
    display: flex;
    align-items: center;
    font-size: 1rem;
  }
}

.schedule__days ul {
  li {
    &:hover {
      cursor: pointer;
    }

    &.active {
      color: var(--white-color);
      &:after {
        background: var(--black-color);
      }
    }
  }
}
.ws .schedule__days ul li.active:after {
  background: var(--bunting-color);
}

.schedule__timeline {
  overflow-x: scroll;

  &:before {
    content: '';
    width: 100px;
    height: 35px;
    display: block;
    position: absolute;
    left: 0;
    background: var(--black-color);
    z-index: 10;

    @media only screen and (min-width: 768px) {
      width: 200px;
    }
  }
}
.ws .schedule__timeline:before {
  background: var(--bunting-color);
}

/* scroll timeline buttons */
.scrollTimelineButtons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 120px;

  @media only screen and (max-width: 1024px) {
    position: fixed;
    bottom: 20px;
    left: 50%;
    z-index: 1002;
  }

  @media only screen and (min-width: 1024px) {
    position: fixed;
    right: 50%;
    bottom: 1rem;
    z-index: 1000;
    transform: translateX(50%);
  }

  svg {
    height: 26px;
  }

  span {
    line-height: 1;
    width: 50px;
    height: 50px;
    background: var(--primary-color);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    position: relative;
    box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.14), 0 0 1px 0 rgba(0, 0, 0, 0.12),
      0 1px 2px 0 rgba(0, 0, 0, 0.2);
    transition: all 0.4s ease;

    svg {
      path {
        fill: var(--white-color);
      }
    }

    &:hover {
      cursor: pointer;
      background: var(--primary-color-hover);
      box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.1), 0 0 1px 0 rgba(0, 0, 0, 0.12),
        0 3px 6px 4px rgba(0, 0, 0, 0.1);
    }

    &.disabled {
      background: $hit-gray;

      &:hover {
        background: $hit-gray;
      }
    }
  }
}

.scrolledPastSched .scrollTimelineButtons {
  position: absolute;
}

.ws .scrollTimelineButtons {
  background: transparent;
}

// .schedule__timeline {
//     .scrollTimelineButtons {
//         position: absolute;
//         left: 0;
//         z-index: 1000;
//         height: 35px;
//         width: 200px;
//         padding: 0 55px;
//         display: flex;
//         align-items: center;
//         justify-content: space-between;
//         background: var(--black-color);

//         span {
//             height: auto;
//             width: 30px;
//             background: transparent;
//             box-shadow: none;
//         }
//     }
// }

// .fixed .schedule__timeline {
//     .scrollTimelineButtons {
//         display: none;
//     }
// }

/* Schedule hours timeline */
.schedule__hours-wrap {
  position: relative;
}

.schedule__hours {
  background-color: var(--black-color);
  color: var(--white-color);
  width: 9400px;
  display: flex;
  margin: 0;
  padding: 0.5rem 0;
  list-style: none;
  justify-content: flex-start;
  flex-wrap: wrap;
  overflow: hidden;
}

.ws .schedule__hours {
  background-color: var(--bunting-color);
}

.fixed .schedule__hours {
  position: fixed;
  top: 136px;
  z-index: 1001;
}

.schedule__hours svg {
  height: 13px;
}

.schedule__hours svg path {
  fill: var(--primary-color);
}

.schedule__hours span {
  width: 100px;
  color: var(--white-color);
  text-align: center;
}

@media only screen and (min-width: 768px) {
  .fixed .schedule__hours {
    top: 141px;
  }

  .schedule__hours span {
    width: 200px;
  }
}

.schedule__hours div:not(.schedule__hours-currentTime) {
  width: 150px;
  color: var(--white-color);
}

.schedule__hours div:last-child {
  width: 50px;
}

.fixed .schedule__timeline-wrap {
  padding-top: 108px;
}

@media only screen and (min-width: 768px) {
  .fixed .schedule__timeline-wrap {
    padding-top: 118px;
  }
}
.fixed .schedule__timeline:before {
  position: fixed;
  top: 136px;
  z-index: 1002;
}

/* Timeline row */
.schedule__timeline-row {
  display: flex;
  border-bottom: 1px solid var(--black-color);
  height: 100px;
  width: 9400px;
  padding-left: 100px;
  transition: all 0.4s ease;
  background: var(--concrete-color);
  overflow: hidden;
}

.ws .schedule__timeline-row {
  border-bottom: 1px solid var(--bunting-color);
}

.schedule__timeline-row:hover {
  background: var(--concrete-color-hover);
}

.coll .schedule__timeline-row:hover {
  background: var(--iron-color-hover);
}

.schedule__timeline-row:active {
  cursor: move;
}

@media only screen and (min-width: 768px) {
  .schedule__timeline-row {
    height: 200px;
    padding-left: 200px;
  }
}

.schedule__timeline .dates.empty:after {
  content: 'There are no timeslots related to your search. Please try searching for a different term or use different filter.';
  display: flex;
  padding: 2rem;
  max-width: 650px;
  width: 100%;
  margin: 0 auto;
  position: absolute;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
  background: white;
  z-index: 2;
}

/* Stage info */
.schedule__stage {
  height: 100px;
  width: 100px;
  position: absolute;
  left: 0;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  border-right: 1px solid var(--grey-color);
  border-bottom: 1px solid var(--black-color);
  background-color: var(--white-color);
}

.ws .schedule__stage {
  border-bottom: 1px solid var(--bunting-color);
}

.schedule__stage > div {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
}

.schedule__stage > div div {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ws .schedule__stage > div div {
  color: var(--bunting-color);
}

.schedule__stage div svg {
  max-width: 90%;
  max-height: 40px;
  width: 100%;
}

@media only screen and (min-width: 768px) {
  .schedule__stage div svg {
    max-height: 60px;
  }
}

.schedule__stage svg path,
.schedule__stage svg g,
.schedule__stage svg polygon {
  fill: var(--black-color);
}

.ws .schedule__stage svg path,
.ws .schedule__stage svg g,
.ws .schedule__stage svg polygon {
  fill: var(--bunting-color);
}

.schedule__stage span {
  width: 100%;
  padding: 0.5rem;
  text-align: center;
  font-size: 0.85rem;
}

.schedule__stage .schedule__stage-timeslotNum {
  position: absolute;
  top: -10px;
  left: 50%;
  margin-left: 20px;
  width: 32px;
  height: 32px;
  background: var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  color: var(--white-color);
  border-radius: 50%;
  font-size: 0.85rem;
  transform: translateX(-50%);
}

@media only screen and (min-width: 768px) {
  .schedule__stage .schedule__stage-timeslotNum {
    top: -20px;
  }

  .schedule__stage {
    width: 200px;
    height: 200px;
  }

  .schedule__stage > div div {
    min-width: 60px;
  }

  .schedule__stage span {
    font-size: 1rem;
  }
}

/* Timeslots */
.schedule__timeslots {
  padding: 0.5rem;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100px;
  position: relative;
}

.ws .schedule__timeslots {
  background: var(--bunting-color-15);
}

@media only screen and (min-width: 768px) {
  .schedule__timeslots {
    height: 199px;
  }
}

/* Schedule current time line */
.schedule__hours-currentTime {
  position: absolute;
  top: 0;
  height: 100%;
  width: 2px;
}

.schedule__hours-currentTime:before,
.schedule__hours-currentTime:after {
  content: '';
  width: 15px;
  height: 15px;
  position: absolute;
  left: -8px;
}

.schedule__hours-currentTime:before {
  top: 0;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-top: 9px solid var(--primary-color);
}

.schedule__hours-currentTime:after {
  bottom: 0;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-bottom: 9px solid var(--primary-color);
}

.schedule__currentTime {
  position: absolute;
  top: 0;
  height: 100%;
  width: 2px;
  background-color: var(--primary-color);
  z-index: 1;
}

/* dates */
.schedule .dates + .loader {
  display: none;
}

.schedule .dates:empty {
  padding: 4rem;
}

.schedule .dates:empty:after {
  content: 'No timeslots found on this day. Please check the other days, or try another search.';
  position: absolute;
  left: 1rem;
  top: 100px;
  max-width: 90%;
}

.agenda .schedule__timeline-row-agendaWrap.agendaNoTimeline:empty:after {
  content: 'No timeslots found on this day. Please check the other days, or try another search.';
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  z-index: 20000;
  background: #fff;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.schedule .dates:empty + .loader {
  display: flex;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  z-index: 3;
  width: 100%;
  background: white;
}

/* Info tab */
.schedule__infoTab {
  padding: 2rem 0 4rem;
}

@media only screen and (min-width: 768px) {
  .schedule__infoTab {
    padding: 3rem 0;
  }
}

.schedule__infoTab .container {
  max-width: 650px;
}

/* agenda */
.schedule.agenda {
  border-bottom: 1px solid var(--black-color);
}

.ws .schedule.agenda {
  border-bottom-color: var(--bunting-color);
}

/* Vertical timeline */
.verticalTimeline {
  width: 40px;
  height: 100%;
  padding: 0;
  margin-left: 50px;
  display: flex;
  justify-content: center;
  position: relative;
}

@media only screen and (min-width: 768px) {
  .verticalTimeline {
    padding: 0 1rem;
    width: 100px;
    margin-left: 100px;
  }
}

.verticalTimeline-line {
  background: var(--primary-color-hover);
  height: 100%;
  width: 15px;
  border-radius: 10px;
  position: relative;
}

.verticalTimeline-currentTime {
  position: absolute;
  top: 0;
  left: 50%;
  width: 15px;
  height: 15px;
  background: var(--primary-color);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  transform: translateX(-50%);
}

.verticalTimeline-currentTime:after {
  content: '';
  position: absolute;
  left: 50%;
  bottom: -15px;
  width: 30px;
  height: 30px;
  background: var(--primary-color);
  border-radius: 50%;
  transform: translateX(-50%);
}

.switchWrap {
  width: 20%;
  max-width: 250px;
  height: 60px;
  margin-right: 2rem;
  .switch {
    display: block;
    position: relative;
    height: 100%;
  }

  .switch input {
    display: none;
  }

  .slider {
    cursor: pointer;
    transition: 0.4s;
    padding: 0;
    border-radius: 30px;
    border: 1px solid var(--silver-color);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 100%;
    width: 45%;
    left: 0;
    top: 0;
    background-color: var(--white-color);
    transition: 0.4s;
    background: var(--primary-color);
    border-radius: 34px;
    z-index: -1;
  }

  input:checked + .slider:before {
    width: 55%;
    left: 100%;
    transform: translateX(-100%);
  }

  .on,
  .off {
    text-align: center;
    transition: all 0.6s ease;
    font-size: 0.875rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    @media only screen and (min-width: 768px) {
      svg {
        margin-right: 8px;
      }
    }
  }
  .on {
    width: 45%;
    color: var(--white-color);
    svg {
      path {
        fill: var(--white-color);
      }
    }
  }
  .off {
    width: 55%;
    color: var(--bunting-color);
    svg {
      path {
        fill: var(--bunting-color);
      }
    }
  }

  input:checked + .slider .on {
    color: var(--bunting-color);
    svg {
      path {
        fill: var(--bunting-color);
      }
    }
  }

  input:checked + .slider .off {
    color: var(--white-color);
    svg {
      path {
        fill: var(--white-color);
      }
    }
  }

  @media only screen and (max-width: 768px) {
    width: 80px;
    height: 40px;
    margin-right: 1rem;
    .on,
    .off {
      width: 50%;
    }
    .slider:before,
    input:checked + .slider:before {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
  }
}
