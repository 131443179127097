/* OLD DO NOT USE Background shapes */
.background-shape,
.background-shape>div:not(.backgroundShape) {
    position: relative;
    z-index: 1;
    overflow: hidden;
}

.background-shape>div.backgroundShape__container {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    max-width: 960px;
    width: 100%;
    overflow: initial;
}

.purple-swirl-t-l-purple-dots-t-r-purple-triangle-b-r>.backgroundShape,
.yellow-swirl-l-c-triangle-b-r>.backgroundShape,
.pink-swirl-l-t-dots-t-r-triangle-b-r>.backgroundShape,
.bg-pink-circle-r>.backgroundShape,
.yellow-triangle-l-purple-dots-l-yellow-swirl-t-r>.backgroundShape,
.purple-dots-l-c-purple-cricle-t-r-green-swirl-b-r>.backgroundShape,
.purple-dots-l-c-purple-cricle-t-r-purple-swirl-b-r>.backgroundShape,
.purple-swirl-t-l-purple-dots-t-r-purple-triangle-b-r>.backgroundShape {
    position: initial;
}

.rc .background-shape:before {
    content: '';
    width: 70%;
    height: 100%;
    position: absolute;
    top: 2.5rem;
    z-index: 0;
    background-size: 100%;
    transform: rotate(-3deg);
}

.rc .background-shape:after {
    content: '';
    width: 120%;
    height: 100%;
    position: absolute;
    top: 4.5rem;
    left: -25px;
    z-index: 0;
    background-size: 100%;
    transform: rotate(-2deg);
}

.rc .background-shape.animated:before,
.rc .background-shape.animated:after,
.rc .backgroundShape.animated:before,
.rc .backgroundShape.animated:after {
    -webkit-animation: BreathingAnimation 5s ease infinite;
    -moz-animation: BreathingAnimation 5s ease infinite;
    animation: BreathingAnimation 5s ease infinite;
}

.rc .background-shape.right:before {
    left: initial;
    right: 0;
    transform: rotate(3deg);
}

.rc .background-shape.right:after {
    left: initial;
    transform: rotate(2deg);
    right: -15px;
}

/* Color differences */
/*  Blue - Light Blue */
.rc .background-shape.blue-light-blue:before {
    background-image: radial-gradient(circle at bottom left, #1A3865 10%, #11BAE3 80%);
}

.rc .background-shape.blue-light-blue:after {
    background-image: radial-gradient(circle at bottom left, #1A3865 10%, #11BAE3 80%);
}

@media only screen and (min-width: 768px) {

    .rc .background-shape.blue-light-blue:before,
    .background-gradient__bl--blue-light-blue {
        background-image: radial-gradient(circle at left, #1A3865 10%, #11BAE3 55%);
    }

    .rc .background-shape.blue-light-blue:after {
        background-image: radial-gradient(circle at bottom left, #1A3865 10%, #11BAE3 60%);
    }
}

/* Light Blue - Blue */
.rc .background-shape.light-blue-blue:before {
    background-image: radial-gradient(circle at bottom left, #4577B7 0%, #1A3865 70%);
}

.rc .background-shape.light-blue-blue:after {
    background-image: radial-gradient(circle at bottom left, #4577B7 10%, #1A3865 75%);
}

/* Green - green */
.rc .background-shape.green-green:before {
    background-image: radial-gradient(circle at bottom right, #6EB8A4 0%, #528B77 90%);
}

.rc .background-shape.green-green:after {
    background-image: radial-gradient(circle at bottom right, #6EB8A4 15%, #528B77 70%);
}

/* Red - Chocolate - Amaranth */
.rc .background-shape.red-chocolate-amaranth:before {
    background-image: radial-gradient(circle at right, #EE3D4C 0%, #3D0101 80%);
}

.rc .background-shape.red-chocolate-amaranth:after {
    background-image: radial-gradient(circle at bottom right, #EE3D4C -10%, #3D0101 70%);
}

/* Red - Chocolate - Tamarillo */
.rc .background-shape.red-chocolate-tamarillo:before {
    background-image: radial-gradient(circle at right, #EF8790 0%, #890D17 65%);
}

.rc .background-shape.red-chocolate-tamarillo:after {
    background-image: radial-gradient(circle at bottom right, #890D17 -10%, #3D0101 70%);
}

/* Red - Tamarillo - Amarants */
.rc .background-shape.red-tamarillo-amaranth:before {
    background-image: radial-gradient(circle at right, #EE3D4C 0%, #3D0101 80%);
}

.rc .background-shape.red-tamarillo-amaranth:after {
    background-image: radial-gradient(circle at bottom right, #890D17 0%, #EE3D4C 80%);
}

/* Red - Tamarillo - Mauvelous */
.rc .background-shape.red-tamarillo-mauvelous:before {
    background-image: radial-gradient(circle at right, #EE3D4C 0%, #3D0101 80%);
}

.rc .background-shape.red-tamarillo-mauvelous:after {
    background-image: radial-gradient(circle at bottom right, #EF8790 0%, #890D17 70%);
}

/* WS Background Shapes */
.ws .bg-purple {
    background: var(--butterfly-color);
    color: var(--white-color);
}

.ws .bg-purple h2,
.ws .bg-purple h3,
.ws .bg-purple-green-circle h2,
.ws .bg-purple-green-circle h3,
.ws .bg-purple-green-circle-b-r h2 {
    color: var(--white-color);
}

.ws .bg-purple-green-circle {
    background: var(--butterfly-color);
    overflow: initial;
    z-index: initial;
    margin-bottom: 3rem;
    color: var(--white-color);
}

.ws .bg-purple-green-circle:after {
    content: '';
    background: var(--primary-color);
    position: absolute;
    width: 150px;
    height: 150px;
    bottom: -3rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
    border-radius: 50%;
}

.ws .bg-purple-green-circle-b-r {
    background: var(--butterfly-color);
    overflow: initial;
    z-index: initial;
    color: var(--white-color);
}

@media only screen and (min-width: 768px) {
    .ws .bg-purple-green-circle-b-r:after {
        content: '';
        background: var(--primary-color);
        position: absolute;
        width: 150px;
        height: 150px;
        bottom: -75px;
        right: 0;
        z-index: 5;
        transform: translateX(50%);
        border-radius: 50%;
    }
}

@media only screen and (min-width: 768px) {
    .ws .bg-purple-green-circle {
        margin-bottom: 4rem;
    }

    .ws .bg-purple-green-circle:after {
        width: 200px;
        height: 200px;
        bottom: -4rem;
    }
}

.ws .bg-pink {
    background: var(--secondary-color);
}

.ws .bg-pink,
.ws .bg-pink h2,
.ws .bg-pink h3,
.ws .bg-pink h4,
.ws .bg-pink p {
    color: var(--white-color);
}

.ws .bg-pink-circle-r {
    background: var(--secondary-color);
    margin-top: 2.5rem;
    overflow: initial;
    z-index: initial;
}

.ws .bg-pink-circle-r:after {
    content: '';
    background: var(--secondary-color-hover);
    position: absolute;
    width: 200px;
    height: 200px;
    top: -2.5rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
    width: 200px;
    height: 100px;
    border-top-left-radius: 200px;
    border-top-right-radius: 200px;
}

@media only screen and (min-width: 768px) {
    .ws .bg-pink-circle-r {
        margin-top: 90px;
    }

    .ws .bg-pink-circle-r:after {
        right: -33px;
        top: -90px;
        left: auto;
        transform: initial;
        width: 400px;
        height: 200px;
        transform: none;
        border-top-left-radius: 400px;
        border-top-right-radius: 400px;
    }
}

.ws .bg-blue {
    background: var(--bunting-color);
    color: var(--white-color);
}

.ws .bg-blue h1,
.ws .bg-blue h2,
.ws .bg-blue h3 {
    color: var(--white-color);
}

.ws .bg-blue-circle {
    background: var(--bunting-color);
    margin-top: 2.5rem;
    overflow: initial;
    z-index: initial;
}

.ws .bg-blue-circle:after {
    content: '';
    background: var(--primary-color);
    border-radius: 50%;
    position: absolute;
    width: 150px;
    height: 150px;
    top: -40px;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
}

@media only screen and (min-width: 768px) {
    .ws .bg-blue-circle {
        margin-top: 90px;
    }

    .ws .bg-blue-circle:after {
        width: 300px;
        height: 300px;
        top: -140px;
        left: 0;
    }
}

.ws .green-swirl-r-c .backgroundShape {
    display: none;
}

@media only screen and (min-width: 768px) {
    .ws .green-swirl-r-c .backgroundShape {
        display: block;
        position: absolute;
        right: 0;
        bottom: 0;
    }
}

.ws .yellow-swirl-l-c-triangle-b-r .yellow-swirl {
    position: absolute;
    left: -200px;
    top: 2rem;
}

.ws .video-section.pink-swirl-l-t-dots-t-r-triangle-b-r {
    margin: 2rem 0;
    padding: 0;
    overflow: initial;
}

@media only screen and (min-width: 768px) {
    .ws .video-section.pink-swirl-l-t-dots-t-r-triangle-b-r {
        margin: 10rem 0 5rem;
        padding: 0;
        overflow: initial;
    }
}

.ws .pink-swirl-l-t-dots-t-r-triangle-b-r .pink-swirl {
    position: absolute;
    z-index: 0;
    top: 10%;
}

.ws .pink-swirl-l-t-dots-t-r-triangle-b-r .dot-container {
    right: 5%;
    top: -8rem;
}

.ws .yellow-triangle-l-purple-dots-l-yellow-swirl-t-r .dot-container {
    top: 2px;
    left: -190px;
}

.ws .yellow-triangle-l-purple-dots-l-yellow-swirl-t-r .yellow-swirl {
    position: absolute;
    right: -35%;
    top: 0;
}

@media only screen and (min-width: 1600px) {
    .ws .yellow-triangle-l-purple-dots-l-yellow-swirl-t-r .yellow-swirl {
        right: -50%;
    }
}

.ws .purple-dots-l-c-purple-cricle-t-r-green-swirl-b-r .green-swirl {
    position: absolute;
    right: 0;
    bottom: 5%;
}

.ws .purple-swirl-t-l-purple-dots-t-r-purple-triangle-b-r .purple-swirl {
    position: absolute;
    left: 0;
    top: 20%;
    width: auto;
}

.purple-dots-l-c-purple-cricle-t-r-green-swirl-b-r .dot-container {
    right: auto;
    overflow: hidden;
    width: 200px;
    padding-top: 5px;
    z-index: 2;
}

.purple-dots-l-c-purple-cricle-t-r-green-swirl-b-r .dot-container .dot-row {
    width: 375px;
}

/* END OLD DO NOT USE Background shapes */

/* New shapes styles */
.backgroundShape {
    position: absolute;
}

.backgroundShape svg {
    width: 100%;
}

/* Shape position */
/* vertical center */
.backgroundShape.centerv {
    top: 50%;
    transform: translateY(-50%)
}

/* horizontal center */
.backgroundShape.centerh {
    left: 50%;
    transform: translateX(-50%)
}

/* center - center */
.backgroundShape.centerh.centerv {
    transform: translate(-50%, -50%)
}

/* between top and center */
.backgroundShape.above-center {
    top: 25%;
}

/* between center and bottom */
.backgroundShape.bellow-center {
    top: 75%;
}

.backgroundShape.above-center.centerh,
.backgroundShape.bellow-center.centerh {
    transform: translate(-50%, -50%)
}

/* between left and center */
.backgroundShape.before-center {
    left: 25%;
}

/* between center and right */
.backgroundShape.after-center {
    left: 75%;
}

.backgroundShape.before-center.centerv,
.backgroundShape.after-center.centerv {
    transform: translate(-50%, -50%)
}

/* Ultimate mixes of shape positions */
.backgroundShape.after-center.bellow-center,
.backgroundShape.after-center.above-center,
.backgroundShape.before-center.bellow-center,
.backgroundShape.before-center.above-center {
    transform: translate(-50%, -50%)
}

.backgroundShape.top {
    top: 0;
}

.backgroundShape.bottom {
    bottom: 0;
}

.backgroundShape.left {
    left: 0;
    right: initial;
}

.backgroundShape.right {
    right: 0;
    left: initial;
}

/* END Shape position */


/* Shape locations */
.backgroundShape.half-left {
    transform: translateX(-50%)
}

.backgroundShape.half-left-top {
    transform: translate(-50%, -50%)
}

.backgroundShape.half-left-bottom {
    transform: translate(-50%, 50%)
}

.backgroundShape.half-right {
    transform: translateX(50%)
}

.backgroundShape.half-right-top {
    transform: translate(50%, -50%)
}

.backgroundShape.half-right-bottom {
    transform: translate(50%, 50%)
}

.backgroundShape.half-top {
    transform: translateY(-50%)
}

.backgroundShape.half-bottom {
    transform: translateY(50%)
}

/* END Shape locations */

/* END New shapes styles */

// Import other shapes
@import './circle.scss';
@import './dots.scss';
@import './lines.scss';
@import './square.scss';
@import './swirl.scss';
@import './triangle.scss';
@import './hills.scss';
@import './blocks.scss'