.inlineTimeslot {
  $e: &;
  max-width: 100%;
  flex: 0 0 auto;
  border: 1px solid var(--alto-color);
  border-radius: 8px;
  padding: 1rem 1.2rem;
  @media only screen and (min-width: 768px) {
    padding: 1.2rem 1.4rem;
  }

  &.isLive {
    border-color: var(--dusty-gray);
  }

  #{$e}__title {
    font-size: 1.25rem;
    margin: .5rem 0;
  }
  #{$e}__info {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-size: .85rem;

    & > div {
      display: flex;
      align-items: center;
    }
    &-status {
      margin-right: 1rem;
      @media only screen and (min-width: 768px) {
        margin-right: 3rem;
      }
    }
    svg {
      width:1rem;
      margin-right: .25rem;
    }
  }

  .attendees {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    .attendee {
      width: auto;
    }
  }
  .attendees__short {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    .attendee {
      max-width: 100%;
      @media only screen and (min-width: 768px) {
        max-width: 50%;
      }
    }
  }
}

.attendee {
  $e: &;
  display: flex;
  align-items: center;

  img {
    width: 40px;
    border-radius: 50%;
    margin-right: .5rem;
  }
  #{$e}__name {
    font-size: 1rem;
    line-height: 1;
    margin-bottom: .25rem;
  }
  #{$e}__jobTitle {
    font-size: .85rem;
    color: var(--dusty-gray);
  }
  #{$e}__details {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
  }
}