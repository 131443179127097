$light-text-color: #9e9e9e;
$light-grey-color: #d8d8d8;

.reviews-section {

    .tabs {
        border-bottom: 0;

        li:after {
            border-top-right-radius: 5px;
        }

        li {
            span {
                color: #0e163a;
                font-size: 1.2rem;
                font-weight: 500;
            }

            &.active {
                span {
                    color: #fff;
                }
            }
        }
    }

    div:last-of-type {
        border-bottom: none;
    }

    .review-block {
        margin-top: 50px;
        padding-bottom: 50px;
        border-bottom: 2px solid $light-grey-color;

        img,
        svg {
            float: left;
            width: 46px;
            height: 46px;
            margin: 0 14px 14px 0;
            border-radius: 24px;
        }

        h3 {
            font-size: 1.14rem;
            line-height: 21px;
            margin: 4px 0;
        }

        .review-category {
            color: #fff;
            font-size: 0.6rem;
            letter-spacing: 2px;
            margin-left: 10px;
            text-transform: uppercase;
            border-radius: 5px;
            padding: 3px 4px;
        }

        .review-author-details {
            height: 60px;

            .review-author-details-sub {
                font-size: 1rem;
                color: $light-text-color;
            }
        }

        .review-title {
            display: inline-block;
            font-size: 0.8rem;
            font-weight: 800;
            letter-spacing: 2px;
            margin-left: 10px;
            // text-transform: uppercase;

        }

        .review-date {
            font-size: 1.05rem;
            font-weight: 400;
            color: $light-text-color;
        }

        .review-content {
            font-size: 1.15rem;
            line-height: 2rem;
            padding-top: 40px;
            overflow: hidden;
            display: block;
        }

        .overlay-gradient {
            height: 25px;
            margin-top: -25px;
            position: relative;
            background-image: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
        }

        .review-readmore {
            display: flex;
            font-size: 1.25rem;
            font-weight: 700;
            color: var(--primary-color);

            &:hover {
                cursor: pointer;
            }

            svg {
                height: 30px;
                padding: 5px;
            }
        }
    }

    .like {
        svg {
            max-width: 20px;
            margin: 0 .5rem 0 0;
        }
    }

    .reviews-view-more-btn {
        width: 280px;
        height: 60px;
        display: block;
        margin-left: auto;
        margin-right: auto;
        background-color: var(--primary-color);
        color: #fff;
        font-size: 1.4rem;
        font-weight: 600;
        text-transform: uppercase;
        margin-bottom: 50px;
    }
}

@media only screen and (max-width: 768px) {
    .reviews-section {
        .reviews-view-more-btn {
            width: 100%;
            font-size: 1.1rem;
            height: 40px;
        }

        .review-block {
            .review-content {
                padding-top: 10px;
            }
        }
    }
}