.dot-container {
    position: absolute;
    top: 2rem;
    right: 2rem;
    z-index: 0;
    height: 375px;
    width: 375px;
    background: transparent;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.hero .dot-container.top {
    top: 6rem;
    transform: none;
}
.dot-container.smallWidth {
    height: 200px;
    width: 150px;
}
@media only screen and (min-width: 768px) {
    .dot-container.smallWidth {
        height: 350px;
        width: 200px;
    }
}
@media only screen and (min-width: 1240px) {
    .dot-container.smallWidth {
        height: 350px;
        width: 300px;
    }
}

.dot-row {
    display: flex;
    width: 100%;
    justify-content: space-between;
}
.dot {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: var(--secondary-color);
}
.purple .dot {
    background: var(--butterfly-color);
}
.orange .dot {
    background: var(--orange-color);
}
.dot-row:first-child .dot {
    animation: scale 1s ease-in-out infinite alternate;
}
.dot-row:nth-child(2) .dot {
    animation: scaleRev 1s ease-in-out infinite alternate;
}
.dot-row:nth-child(3) .dot {
    animation: scale 1s ease-in-out infinite alternate;
}
.dot-row:nth-child(4) .dot {
    animation: scaleRev 1s ease-in-out infinite alternate;
}
.dot-row:nth-child(5) .dot {
    animation: scale 1s ease-in-out infinite alternate;
}
.dot-row:nth-child(6) .dot {
    animation: scaleRev 1s ease-in-out infinite alternate;
}
.dot-row:nth-child(7) .dot {
    animation: scale 1s ease-in-out infinite alternate;
}
.dot-row:nth-child(8) .dot {
    animation: scaleRev 1s ease-in-out infinite alternate;
}

@keyframes scale {
    0% {
        transform: scale(1.0);
        -webkit-transform: scale(1.0);
    }
    100% {
        transform: scale(1.5);
        -webkit-transform: scale(1.5);
    }
}

@keyframes scaleRev {
    0% {
        transform: scale(1.5);
        -webkit-transform: scale(1.5);
    }
    100% {
        transform: scale(1.0);
        -webkit-transform: scale(1.0);
    }
}


/* bg v2 */
.dot-container.centerv {
    top: 50%;
    transform: translateY(-50%)
}
.dot-container.centerh {
    left: 50%;
    transform: translateX(-50%)
}
.dot-container.top {
    top: 2rem;
}
@media only screen and (min-width: 768px) {
    .dot-container.top {
        top: 4rem;
    }
}
.dot-container.bottom {
    bottom: 0;
    top: initial;
}
.dot-container.left {
    left: 2rem;
    right: initial;
}
.dot-container.right {
    right: 2rem;
    left: initial;
}
@media only screen and (max-width: 767px) {
    .dot-container.left {
        left: 1rem;
    }
    .dot-container.right {
        right: 1rem;
    }
}
.no-padding-top .dot-container.top {
    top: 0;
}