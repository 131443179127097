.accordions {
  padding: 3rem 0;
}

.rc-collapse {
  border-top: 1px solid $hit-gray;
  border-bottom: 1px solid $hit-gray;
  width: 100%;
}

.rc-collapse-anim-active {
  transition: height 0.2s ease-out;
}

.rc-collapse > .rc-collapse-item {
  border-top: 1px solid $hit-gray;
}

.rc-collapse > .rc-collapse-item:first-child {
  border-top: none;
}

.rc-collapse > .rc-collapse-item > .rc-collapse-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  line-height: 1;
  padding: 1.5rem 2rem 1.5rem 0;
  font-weight: 600;
  cursor: pointer;
  outline: none;

  svg {
    position: absolute;
    right: 0.5rem;
    top: 50%;
    transform: translateY(-50%);
    width: 25px;
    height: 25px;
    padding: 5px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }

  .rc-collapse-extra {
    margin: 0 1rem 0 auto;
  }
}

.rc-collapse > .rc-collapse-item > .rc-collapse-header .rc-collapse-extra {
  margin: 0 1rem 0 auto;
}

.rc-collapse > .rc-collapse-item-disabled > .rc-collapse-header {
  cursor: not-allowed;
  color: var(--dove-gray);
  background-color: var(--silver-color);
}

.rc-collapse-content {
  overflow: hidden;

  @media only screen and (min-width: 768px) {
    padding-left: 2rem;
    max-width: 60%;
  }
}

.rc-collapse-content > .rc-collapse-content-box {
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.rc-collapse-content-inactive {
  display: none;
}

.rc-collapse > .rc-collapse-item-active {
  > .rc-collapse-header svg {
    background-color: var(--primary-color);

    path {
      fill: var(--white-color);
    }
  }
}

.accordionImage {
  svg {
    path {
      fill: var(--white-color);
    }
  }
}
.rc-collapse > .rc-collapse-item.accordionImage > .rc-collapse-header {
  padding: 0;
}
