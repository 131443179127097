.singlePost {
    a {
        color: var(--primary-color);

        &:hover {
            color: var(--primary-color-hover);
        }
    }

    &__back {
        display: flex;
        align-items: center;
        text-decoration: none;
        margin-bottom: 1rem;

        svg {
            height: 14px;
            margin-right: 5px;
        }
    }

    &__title {
        color: $shark-gray;
        font-family: 'alverata', serif;
    }

    &__featuredImage {
        width: 100%;
        height: 200px;
        background-size: cover;
        background-position: center center;
        margin-bottom: 1rem;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
    }

    &__content {
        font-size: 1.115rem;
        text-align: left;

        &-wrap {
            padding: 0 0 1rem;
            position: relative;
        }

        img {
            display: block;
            margin: 1rem auto;
            height: auto;
        }

        iframe {
            max-width: 100%;
            margin: .5rem 0;
        }

        blockquote {
            color: var(--primary-color);
            font-weight: 600;
            font-size: 1.35rem;
            margin: 2rem;
        }

        .caption {
            text-align: center;
            width: 100%;
            display: block;
            margin-top: -10px;
            font-size: 1rem;
        }
    }

    &__categories {
        .btn {
            text-transform: capitalize;
            padding: 1rem 2.5rem;
            margin-right: 1rem;
        }
    }

    &__tags {
        margin: .5rem 0;

        a {
            margin: 0 .2rem;
        }
    }

    &__share {
        display: flex;
        justify-content: flex-end;
        padding: 1rem 0;
        border-bottom: 1px solid $alto;
        margin-bottom: 2rem;
        color: $hit-gray;

        .share-twitter,
        .share-facebook,
        .share-linkedin {
            display: flex;
            align-items: center;
            justify-content: flex-start;
        }

        .share-twitter,
        .share-facebook,
        .share-linkedin {
            margin-left: 1rem;
        }

        svg {
            height: 20px;

            path {
                fill: $hit-gray;
            }
        }
    }

    &__relatedPosts {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-wrap: wrap;
        margin: 2rem 0;

        h2 {
            width: 100%;
            font-size: 1.25rem;
        }
    }

    // Tablet & Desktop edits
    @media only screen and (min-width:768px) {
        padding-top: 0;
        padding-bottom: 0;

        .blog.container {
            padding-top: 1rem;
        }

        &__title {
            font-size: 3rem;
        }

        &__featuredImage {
            height: 400px;
            margin-bottom: 3rem;
        }

        &__content {
            &-wrap {
                padding: 0 0 3rem 0;
            }

            blockquote {
                margin: 3rem 5rem;
                font-size: 1.75rem;
            }

            img {
                margin: 2rem auto;
            }

            .caption {
                margin-top: -20px;
            }
        }

        &__relatedPosts {
            .postBlock {
                width: 32%;
            }
        }
    }
    
    @media only screen and (min-width:1200px) {
      .blog.container {
        max-width: 1100px;
      }
    }
    @media only screen and (min-width:1280px) {
        &__featuredImage {
            height: 500px;
        }

        &__content {
            &-wrap {
                padding: 0 18rem 3rem 0;
            }
        }
    }

    // Twitter fix
    .twitter-tweet.twitter-tweet-rendered {
        margin: 0 auto;
    }


    // form fix
    .notHero {

        .form.two-cols>div:first-child,
        .form.two-cols>div.submit {
            width: 100%;
        }

        .form__thank-you {
            font-size: 1.2rem;
        }

        .form__thank-you h2 {
            margin-top: .5rem;
        }

    }
}

.like {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-right: auto;
    color: $shark-gray;

    &:hover {
        cursor: pointer;

        svg path {
            fill: var(--primary-color);
            transition: all .4s ease;
        }
    }

    svg {
        height: 20px;
        margin-right: .5rem;
    }

    &.disabled {
        cursor: no-drop;

        svg path {
            fill: var(--primary-color);
        }
    }
}