.menuItem {
    padding: 0 1rem;
    position:relative;
}
.menuItem:hover {
    cursor:pointer;
}
.menuItem a {
    text-decoration: none;
}

.menuItem ul {
    overflow:hidden;
    transition: max-height 0.5s ease-out;
}
.menuItem.open ul {
    transition: max-height 2s ease-out;
}

.menuItem.hasSubmenu i {
    transition: transform .5s ease;
    margin-left:.5rem;
}
.menuItem.hasSubmenu.open i {
    transform: rotate(180deg);
    transition: transform .5s ease;
}
@media screen and (min-width: 768px) {
    .menuItem.hasSubmenu i {
        margin-left: .7rem;
        font-size: 1.2rem;
    }
}