.checkbox > div {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}
.form__field.checkbox.error span{
    font-weight: 600;
    animation: blink 2s linear infinite;
    color: var(--primary-color);
}


.checkboxField {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 100%;
    position: relative;
    margin-bottom: 1rem;
}
.checkboxField:last-child {
    margin-bottom:.5rem;
}
.checkboxField input {
    margin: 0;
    opacity: 0;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 32px;
    height: 32px;
    z-index: 1;
}
.checkboxField input:hover,
.checkboxField label:hover {
    cursor: pointer;
}
.checkboxField label {
    width: calc(100% - 40px);
}

.checkboxField .checkmark {
    height: 32px;
    width: 32px;
    background-color: var(--white-color);
    border: 1px solid var(--primary-color);
    margin: 0 8px 0 0;
    padding: 5px;
    transition: all .4s ease;
}
.checkboxField input ~ .checkmark svg {
    display: none;
    color: var(--primary-color);
}
.checkboxField input:checked ~ .checkmark {
    background-color: var(--bunting-color);
    border: 1px solid var(--primary-color);
}
.checkboxField input:checked ~ .checkmark svg {
    display: block;
}

/* Perks fix */
.checkboxField .perksFix {
    align-items: flex-start;
    color: var(--bunting-color);
    margin-bottom: 0;
}
.checkboxField.perksFix__wrap {
    width: 100%;
    border: 1px solid var(--bunting-color);
    transition: all .4s ease;
    position: relative;
    margin-bottom: 2rem;
}
@media only screen and (min-width: 400px) {
    .checkboxField.perksFix__wrap {
        margin-right: 1%;
        width: 49%;
    }
}
@media only screen and (min-width: 768px) {
    .checkboxField.perksFix__wrap {
        width: 32%;
    }
}
@media only screen and (min-width: 1024px) {
    .checkboxField.perksFix__wrap {
        width: 24%;
    }
}
.checkboxField.perksFix__wrap.checked {
    background: var(--primary-color);
}
.checkboxField.perksFix__wrap input {
    display: none;
}
.checkboxField.perksFix__wrap label,
.checkboxField .perksFix__image,
.checkboxField .perksFix,
.checkboxField.perksFix__wrap > div {
    width: 100%;
}
.checkboxField .perksFix__checkmark {
    display: none;
    background: var(--white-color);
    transition: all .4s ease;
}
.checkboxField.checked .perksFix__checkmark {
    position: absolute;
    top: -18px;
    left: 50%;
    transform: translateX(-50%);
    width: 36px;
    height: 36px;
    padding: 7px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    background: var(--primary-color);
    border-radius: 50%;
    border: 1px solid var(--bunting-color);
}
.checkboxField .perksFix__checkmark svg {
    width: 100%;
}
.checkboxField .perksFix__checkmark svg path{
    fill: var(--white-color)
}
.checkboxField .perksFix__image {
    border-bottom: 1px solid var(--bunting-color);
    background: var(--white-color)
}
.checkboxField .perksFix__image a {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
}
.checkboxField .perksFix__image img {
    max-height: 120px;
}
.checkboxField .perksFix__content {
    padding: 1rem;
}
.checkboxField.checked .perksFix__content {
    color: var(--white-color)
}
.checkboxField .perksFix__content h3 {
    margin-top: 0;
    margin-bottom: .5rem;
    font-size: 1rem;
}
.checkboxField.checked .perksFix__content h3 {
    color: var(--white-color)
}
.checkboxField.perksFix__wrap label {
    width: 100%;
    display: flex;
    justify-content: center;
    align-self: flex-end;
    padding: 1rem;
    background: var(--primary-color);
    color: var(--white-color);
    border-top: 1px solid var(--bunting-color);
    transition: all .4s ease;
    user-select: none;
}
.checkboxField.perksFix__wrap label:hover {
    background: var(--primary-color-hover)
}
.checkboxField.checked.perksFix__wrap label {
    background: var(--bunting-color)
} 