.tracksGrid {
  padding: 3rem 0 3rem;
  position: relative;
}

.tracksGrid .container {
  max-width: 1280px;
}

.tracksGrid h2 {
  font-weight: 900;
  margin: 0 0 3rem;
}

.ws .tracksGrid h2 {
  font-family: 'alverata', serif;
}

.tracksGrid .tracksBlock {
  color: var(--white-color);
  min-height: 300px;
  height: 100%;
  padding-bottom: 30px;
  transition: all .4s linear;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
}

.ws .tracksGrid .bottomArea {
  background: var(--bunting-color);
}

.tracksBlock:hover {
  transform: scale(1.02);
}

.tracksGrid a {
  text-decoration: none;
  display: block;
  height: 100%;
}

.tracksGrid .topArea {
  width: 100%;
  min-height: 120px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.tracksGrid .topArea svg {
  margin-left: auto;
  margin-right: auto;
  width: 70%;
  max-height: 80px;
}

.tracksGrid .bottomArea {
  width: 100%;
  padding: 1.5rem 1.8rem;
  background: var(--black-color);
  overflow: hidden;
  height: calc(100% - 120px);
}

.tracksGrid .bottomArea h3 {
  color: #fff;
  font-size: 1.3em;
  margin: 0 0 .5rem;
}

.tracksGrid .bottomArea p {
  overflow: hidden;
  margin: 0;
}

@media only screen and (max-width: 768px) {
  .tracksGrid {
    padding: 4rem 0 2rem;
  }
}