.menu {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10013;
    background: var(--secondary-color);
    width: 100%;
    height: 100%;
    display: block;
    transform: translate3d(0, -100%, 0);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    padding-top: 4rem;
}

.menu.go {
    transition: transform .8s cubic-bezier(.86, 0, .07, 1), -webkit-transform .8s cubic-bezier(.86, 0, .07, 1);
    transform: translateZ(0);
}

.menu.go-reverse {
    transform: translate3d(0, -100%, 0);
    transition: transform .8s cubic-bezier(.86, 0, .07, 1), -webkit-transform .8s cubic-bezier(.86, 0, .07, 1);
}

.menu__burger {
    position: fixed;
    top: 2rem;
    right: 1rem;
    z-index: 10014;
    transform: translateY(-50%);
}

.menu__wrap {
    height: 100%;
    padding: 0 2rem 1rem;
    max-width: 1240px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media only screen and (min-width: 768px) {
    .menu {
        padding-top: 6rem;
    }

    .menu__wrap {
        padding: 0 2rem 1rem;
    }

    .menu__burger {
        top: 2.5rem;
        right: 2rem;
    }
}

@media only screen and (min-width: 1025px) {
    .menu {
        padding-top: 5rem;
    }

    .menu__wrap {
        overflow: hidden;
    }
}

.menu__wrap .row {
    width: 100%;
    max-height: 100%;
    overflow-y: auto;
    margin: 0;
    justify-content: space-between;
    flex-wrap: wrap;
}

.menu__logo {
    position: absolute;
    top: 2rem;
    left: 2rem;
    width: auto;
    display: flex;
    align-items: center;
}

.menu__logo img {
    width: 80px;
}

.ws .menu__logo {
    text-decoration: none;
}

.ws .menu__logo img {
    width: 60px;
}

.menu__logo div {
    transition: all .5s ease;
    margin-left: 10px;
    color: var(--white-color);
    font-size: 1rem;
}

@media only screen and (min-width: 1025px) {
    .menu__logo img {
        width: 100%;
        max-width: 300px;
    }
    .rc .menu__logo img {
        width: 60px;
    }
    .coll .menu__logo {
        top:0;
        img {
            max-width: 250px;
        }
    }
}

.menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 1.3rem;
    width: 100%;
}

.menu .row>ul {
    width: 100%;
    padding: 1rem 0;
}

.menu .row>span {
    display: none;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .menu ul {
        font-size: 1.5rem;
    }

    .menu .row>ul:not(.menu__third-list) {
        width: 45%;
    }
}

@media only screen and (min-width: 1025px) {
    .menu ul {
        font-size: 1.5rem;
    }

    .menu .row>ul {
        width: 30%;
        padding: 1rem 0 1rem 1rem;
    }

    .menu .row>span {
        background: var(--primary-color);
        font-size: 0;
        width: 1px;
        display: block;
    }
}

.menu .menuItem {
    padding: 0;
}

.menu .menuItem span,
.menu .menuItem a {
    display: inline-block;
    width: 90%;
    transition: all .5s ease;
    padding: .6rem 0;
}

.menu .menuItem a,
.menu .menuItem {
    color: var(--primary-color);
}

.menu .menuItem>a:hover,
.menu .menuItem>span:hover {
    color: var(--primary-color-link-hover);
}

@media only screen and (min-width: 768px) {

    .menu .menuItem>a:hover,
    .menu .menuItem>span:hover {
        padding-left: 5px;
    }
}

.menu .menuItem.active span {
    color: var(--white-color);
    text-decoration: underline;
}

.menu .menuItem.active span:hover {
    cursor: not-allowed;
}

.menu ul .submenu {
    font-size: 1rem;
}

.menu ul .submenu .menuItem {
    padding-left: 1.2rem;
}

@media only screen and (min-width: 1025px) {
    .menu ul .submenu {
        font-size: 1.2rem;
    }

    .menu ul .submenu .menuItem {
        padding-left: 1.5rem;
    }
}

.menu__ctas {
    width: 100%;
}

.menu__ctas .btn {
    width: 100%;
    text-align: center;
}

.menu__ctas .btn:first-child {
    margin-bottom: 1rem;
}

@media only screen and (min-width: 1025px) {
    .menu__ctas {
        width: auto;
        margin: 2rem 0 3rem;
    }

    .menu__ctas .btn {
        width: auto;
        margin-right: 1rem;
    }

    /* .menu__ctas .btn:first-child {
        margin-bottom:0;
    } */
}

.menu .menu__social-icons {
    display: flex;
    font-size: 2rem;
    margin: 1rem 0;
}

.menu .menu__social-icons li {
    margin-right: 1.5rem;
}

.menu .menu__social-icons li a {
    color: var(--primary-color);
}

.menu .menu__social-icons li a:hover {
    color: var(--primary-color-link-hover);
}

.ws .menu .menu__social-icons li a:hover {
    color: var(--primary-color-hover);
}

.menu .menu__social-icons li a svg {
    height: 24px;
}


/* Hamburger icon */
.hamburger {
    display: inline-block;
    cursor: pointer;
    transition-property: opacity, filter;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible;
}

.hamburger:hover {
    opacity: 0.8;
}

.hamburger-box {
    width: 26px;
    height: 20px;
    display: inline-block;
    position: relative;
}

.hamburger-inner {
    display: block;
    top: 50%;
    margin-top: -2px;
}

.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
    width: 26px;
    height: 2px;
    background-color: var(--primary-color);
    border-radius: 0;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
}

.hamburger-inner::before,
.hamburger-inner::after {
    content: "";
    display: block;
}

.hamburger-inner::before {
    top: -10px;
}

.hamburger-inner::after {
    bottom: -10px;
}

.hamburger--elastic .hamburger-inner {
    top: 2px;
    transition-duration: 0.275s;
    transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.hamburger--elastic .hamburger-inner::before {
    top: 8px;
    transition: opacity 0.125s 0.275s ease;
}

.hamburger--elastic .hamburger-inner::after {
    top: 16px;
    transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.hamburger--elastic.is-active .hamburger-inner {
    transform: translate3d(0, 10px, 0) rotate(135deg);
    transition-delay: 0.075s;
}

.hamburger--elastic.is-active .hamburger-inner::before {
    transition-delay: 0s;
    opacity: 0;
}

.hamburger--elastic.is-active .hamburger-inner::after {
    transform: translate3d(0, -20px, 0) rotate(-270deg);
    transition-delay: 0.075s;
    top: 20px;
}

/* orange color */
.orange .hamburger-inner,
.orange .hamburger-inner::before,
.orange .hamburger-inner::after {
    background-color: var(--orange-color);
}

.orange.menu .menu__social-icons li a {
    color: var(--orange-color);
}

.orange.menu .menu__social-icons li a:hover {
    color: var(--orange-color-hover);
}

.orange.menu .menuItem a,
.orange.menu .menuItem {
    color: var(--orange-color);
}

.orange.menu .menuItem>a:hover,
.orange.menu .menuItem>span:hover {
    color: var(--orange-color-hover);
}

@media only screen and (min-width: 1025px) {
    .orange.menu .row>span {
        background: var(--orange-color);
    }
}

/* white color */
.white .hamburger-inner,
.white .hamburger-inner::before,
.white .hamburger-inner::after {
    background-color: var(--white-color);
}

.white.menu .menu__social-icons li a {
    color: var(--white-color);
}

.white.menu .menu__social-icons li a:hover {
    color: var(--white-color-hover);
}

.white.menu .menuItem a,
.white.menu .menuItem {
    color: var(--white-color);
}

.white.menu .menuItem>a:hover,
.white.menu .menuItem>span:hover {
    color: var(--white-color-hover);
}

@media only screen and (min-width: 1025px) {
    .white.menu .row>span {
        background: var(--white-color);
    }
}

/* can-can color */
.can-can .hamburger-inner,
.can-can .hamburger-inner::before,
.can-can .hamburger-inner::after {
    background-color: var(--can-can-color);
}

.can-can.menu .menu__social-icons li a {
    color: var(--can-can-color);
}

.can-can.menu .menu__social-icons li a:hover {
    color: var(--can-can-color-hover);
}

.can-can.menu .menuItem a,
.can-can.menu .menuItem {
    color: var(--can-can-color);
}

.can-can.menu .menuItem>a:hover,
.can-can.menu .menuItem>span:hover {
    color: var(--can-can-color-hover);
}

@media only screen and (min-width: 1025px) {
    .can-can.menu .row>span {
        background: var(--can-can-color);
    }
}

/* sun color */
.sun .hamburger-inner,
.sun .hamburger-inner::before,
.sun .hamburger-inner::after {
    background-color: var(--sun-color);
}

.sun.menu .menu__social-icons li a {
    color: var(--sun-color);
}

.sun.menu .menu__social-icons li a:hover {
    color: var(--sun-color-hover);
}

.sun.menu .menuItem a,
.sun.menu .menuItem {
    color: var(--sun-color);
}

.sun.menu .menuItem>a:hover,
.sun.menu .menuItem>span:hover {
    color: var(--sun-color-hover);
}

@media only screen and (min-width: 1025px) {
    .sun.menu .row>span {
        background: var(--sun-color);
    }
}