.form__field.radio {
    & > div {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        flex-direction:column;
    }
    &.horizontal > div {
        flex-direction: row;
    }
}
.form__field.radio .radioField {
    margin: 0 2rem 1.5rem 0;
    height: auto;
}
.form__field.radio .radioField:last-child {
    margin-bottom: .5rem;
}
@media only screen and (min-width: 768px) {
    .form__field.radio .radioField {
        margin: 0 2rem 1rem 0;
    }
}
.form__field.radio input:focus + label:before,
.form__field.radio input:checked:focus + label:before {
    box-shadow: 0px 0px 6px 2px var(--primary-color);
}
.ws .form__field.radio input:focus + label:before,
.ws .form__field.radio input:checked:focus + label:before {
    box-shadow: 0px 0px 6px 2px var(--primary-color);
}
.form__field.radio input:checked + label:before {
    box-shadow: none;
}

.form__field.radio [type="radio"]:checked,
.form__field.radio [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
.form__field.radio [type="radio"]:checked + label,
.form__field.radio [type="radio"]:not(:checked) + label
{
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    display: inline-block;
    color: var(--black-color);
}
.ws .form__field.radio [type="radio"]:checked + label,
.ws .form__field.radio [type="radio"]:not(:checked) + label
{
    color: var(--primary-color);
}
.hero__form .form__field.radio [type="radio"]:checked + label,
.hero__form .form__field.radio [type="radio"]:not(:checked) + label {
    color: var(--white-color);
}
.form__field.radio [type="radio"]:checked + label:before,
.form__field.radio [type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    border: 2px solid var(--white-color);
    border-radius: 100%;
    background: transparent;
}
.notHero .form__field.radio [type="radio"]:checked + label:before,
.notHero .form__field.radio [type="radio"]:not(:checked) + label:before {
    border: 2px solid var(--primary-color);
}
.notHero .form__field.radio [type="radio"]:checked + label:after,
.notHero .form__field.radio [type="radio"]:not(:checked) + label:after {
    background: var(--primary-color);
}
.form__field.radio [type="radio"]:checked + label:after,
.form__field.radio [type="radio"]:not(:checked) + label:after {
    content: '';
    width: 10px;
    height: 10px;
    background: var(--white-color);
    position: absolute;
    top: 5px;
    left: 5px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
.form__field.radio [type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
.form__field.radio [type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}
.form__field.radio.error span{
    font-weight: 600;
    animation: blink 2s linear infinite;
    color: var(--primary-color);
}
.form__field.radio.error:after {
    content: none;
}