.image-grid {
    position: relative;
    z-index: 1;
    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
}

.image-grid.padding {
    padding: 2rem 0 1rem
}

@media only screen and (min-width: 768px) {
    .image-grid.padding {
        padding: 3rem 0 2rem
    }
}

.image-grid .container {
    padding: 0 1rem;
}

.image-grid__row {
    display: flex;
    flex-wrap: wrap;
}

.image-grid__half,
.image-grid__quarter {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    /* height: 200px; */
}

.image-grid__half {
    width: 100%;
}

.image-grid__quarter {
    width: 50%;
}

.image-grid__quote {
    padding: 1rem 1.2rem;
    color: var(--white-color);
    position: relative;
    display: flex;
    align-items: center;
}

.image-grid__quote span {
    display: block;
    margin-bottom: 1.5rem;
    font-weight: 600;
    font-size: 1.1rem;
}

.image-grid__quote i {
    font-size: 2rem;
    position: absolute;
}

.image-grid__quote i.link {
    bottom: 1rem;
    right: 1rem;
}

.image-grid__quote i.social {
    position: initial;
}

@media only screen and (max-width: 768px) {
    .image-grid__quote {
        padding: 1.2rem 1.4rem;
        /* padding: 4.4rem 2.2rem; */
        font-size: 0.8rem;
        span{
            font-size: 1rem;
        }
    }
}

@media only screen and (min-width: 768px) {
    .image-grid__row {
        display: flex;
    }

    .image-grid__quarter,
    .image-grid__half {
        max-height: 400px;
        height: auto;
    }

    .image-grid__half {
        width: 40%;
    }

    .image-grid__quarter {
        width: 30%;
    }

    .image-grid__quote span {
        display: block;
        margin-bottom: 1.5rem;
        font-size: 1.2rem;
        line-height: 1.5rem;
    }
}

@media only screen and (min-width: 1440px) {
    .image-grid__quote {
        font-size: 1.5rem;
    }

    .image-grid__quote {
        padding: 2.5rem 2.7rem;
    }

    .image-grid__half {
        width: 50%;
    }

    .image-grid__quarter {
        width: 25%;
    }
}

.image-grid__slider .swiper-container,
.image-grid__slider .swiper-wrapper,
.image-grid__slider .swiper-slide {
    height: 100%;
}

.image-grid__slider .swiper-slide {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

.image-grid__slider .swiper-pagination {
    position: absolute;
    bottom: 1.5rem;
    left: 50%;
    transform: translateX(-50%);
    width: auto;
}

.image-grid__slider .swiper-pagination .swiper-pagination-bullet {
    background: var(--white-color);
}

@media only screen and (max-width: 767px) {

    .image-grid__quarter.last,
    .image-grid__half.last {
        order: 1;
    }
}

/* bg color */
.image-grid__quote.orange {
    background: var(--orange-color)
}

.image-grid__quote.venture {
    position: relative;
    background-color: var(--concrete-color-hover);
    color: $venture-purple;

    >div {
        position: relative;
        padding-top: 20px;

        &:before {
            position: absolute;
            left: 0;
            top: 0;
            width: 180px;
            height: 1px;
            /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#130f28+0,068b87+100 */
            background: rgb(19, 15, 40);
            /* Old browsers */
            background: -moz-linear-gradient(left, rgba(19, 15, 40, 1) 0%, rgba(6, 139, 135, 1) 100%);
            /* FF3.6-15 */
            background: -webkit-linear-gradient(left, rgba(19, 15, 40, 1) 0%, rgba(6, 139, 135, 1) 100%);
            /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to right, rgba(19, 15, 40, 1) 0%, rgba(6, 139, 135, 1) 100%);
            /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#130f28', endColorstr='#068b87', GradientType=1);
            /* IE6-9 */
            padding: 0;
            content: '';
        }

        &:after {
            position: absolute;
            left: 40px;
            top: -5px;
            width: 100px;
            height: 10px;
            padding: 0;
            content: '';
            transform: skew(-20deg);
            /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#068b87+0,130f28+100 */
            background: rgb(6, 139, 135);
            /* Old browsers */
            background: -moz-linear-gradient(left, rgba(6, 139, 135, 1) 0%, rgba(19, 15, 40, 1) 100%);
            /* FF3.6-15 */
            background: -webkit-linear-gradient(left, rgba(6, 139, 135, 1) 0%, rgba(19, 15, 40, 1) 100%);
            /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to right, rgba(6, 139, 135, 1) 0%, rgba(19, 15, 40, 1) 100%);
            /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#068b87', endColorstr='#130f28', GradientType=1);
            /* IE6-9 */
        }
    }
}



/* Text-button */
.text-button {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 1rem;
}

.text-button__content {
    width: 100%;
    min-height: 100px;
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    border: 1px solid var(--primary-color);
    border-bottom: none;
    text-align: center;
    font-size: 1.2rem;
    font-weight: 600;
    color: var(--bunting-color);
    background: var(--white-color);
}

.text-button a.btn.btn-primary-white {
    width: 100%;
    border: 1px solid var(--primary-color);
}

.text-button a.btn.btn-primary-white:hover {
    opacity: 1;
}

.ws .text-button__content {
    border-color: var(--bunting-color);
}

.coll .text-button__content,
.coll .text-button a.btn.btn-primary-white {
    border-color: var(--secondary-color)
}