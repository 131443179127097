.backgroundShape.hills {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 3rem;
        z-index: 1;
        transform: skewY(2deg);
        width: 100%;
        height: 100%;
        background: var(--primary-color); /* Old browsers */
        background: -moz-linear-gradient(-227deg, #EE3D4C 0%, #3D0101 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(-227deg, #EE3D4C 0%, #3D0101 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(-227deg, #EE3D4C 0%, #3D0101 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#EE3D4C', endColorstr='#3D0101',GradientType=0 ); /* IE6-9 */
    }
    &:after {
        content: '';
        position: absolute;
        left: 30%;
        top: 1rem;
        z-index: 0;
        transform: skewY(2deg);
        height: 100%;
        width: 70%;
        background: var(--primary-color); /* Old browsers */
        background: -moz-linear-gradient(-227deg, #EE3D4C 0%, #3D0101 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(-227deg, #EE3D4C 0%, #3D0101 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(-227deg, #EE3D4C 0%, #3D0101 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#EE3D4C', endColorstr='#3D0101',GradientType=0 ); /* IE6-9 */
    }
    @media only screen and (min-width: 768px) {
        &:before {
            top: 5rem;
        }
        &:after {
            top: 2rem;
        }
    }
}

// Add extra padding on sections
section.bgHills {
    padding-top: 4rem;
    color: var(--white-color);
}
@media only screen and (min-width: 768px) {
    section.bgHills {
        padding-top:8rem;
    }
}