.lever {
  padding: 2rem;
  .lever-block {
    width: 100%;
    display:inline-block;
    padding:0 0 0.5rem;
    h3{
      margin-bottom:0.7rem;
    }
    .lever-job {
      width: 100%;
      padding-bottom:1rem;
      float: left;
      a{
        font-size: 1.1rem;
      }
      .location{
        font-size: 0.9rem;
        opacity: 0.9;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .lever {
    .lever-job {
      width: 50%;
    }
  }
}
