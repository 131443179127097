.introSection {
  padding: 6rem 1rem 3rem;
  position: relative;
  z-index: 5;
  @media only screen and (max-width: 768px) {
    .container {
      position: static;
    }
  }
  @media only screen and (min-width: 768px) {
    padding: 3rem 1rem;
  }
  &_title {
    font-size: 2rem;
    line-height: 1em;
    font-family: 'proxima-nova' !important;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 1rem;
    @media only screen and (min-width: 768px) {
      font-size: 4rem;
      line-height: 64px;
    }
  }
  &_wrap {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    position: static;
  }
  &_image {
    position: absolute;
    left: 0;
    top: -1rem;
    width: 120px;
    height: 120px;
    @media only screen and (min-width: 768px) {
      left: -5rem;
      bottom: -6rem;
      top: initial;
      width: 250px;
      height: auto;
    }
    svg {
      width: 100%;
      height: auto;
    }
  }
  &_content {
    width: 100%;
    padding-left: 2rem;
    @media only screen and (min-width: 768px) {
      padding-left: 210px;
    }
  }

  // BG color variations
  &.primary {
    background: var(--primary-color);
    color: var(--white-color);
    h2 {
      color: var(--white-color);
    }
  }
  &.ns-blue-bg {
    h2 {
      color: var(--white-color);
    }
  }
}
