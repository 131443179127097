select {
    width: 100%;
    display: block;
    background-color: var(--white-color);
    color: var(--black-color);
    border-radius: 0;
    -webkit-appearance: none;
    -webkit-border-radius: 0;
    -webkit-padding-end: 20px;
    -webkit-padding-start: 2px;
    -webkit-user-select: none;
    padding: 1rem 2rem 1rem 1rem;
    font-size: 1rem;
}
.form__field.select div {
    position: relative;
}
.form__field.select i {
    position: absolute;
    top: 50%;
    right:1rem;
    transform: translateY(-50%);
    color: var(--primary-color);
    pointer-events: none;
    transition: all .4s ease;
}
.form__field.select.error i {
    right: 3rem;
}
.form__field.select.error div:not(.form__field-description):after {
    font-family: "Font Awesome 5 Pro";
    font-weight: 100;
    font-size: 24px;
    content: '\f06a';
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--primary-color);
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
}