.algolia-block {
  margin-top: 30px;
  margin-bottom: 30px;
}

.algolia-block .ais-SearchBox-form .ais-SearchBox-input {
  border: 1px solid var(--primary-color);
  padding-right: 20px;
}

.algolia-block .ais-SearchBox-form .ais-SearchBox-input::placeholder {
  color: var(--primary-color);
  font-weight: 400;
}

.algolia-block .ais-SearchBox-form .ais-SearchBox-reset {
  display: none;
}
.algolia-block .ais-SearchBox-form {
  position: relative;
}
.algolia-block .ais-SearchBox-form input::-webkit-search-decoration,
.algolia-block .ais-SearchBox-form input::-webkit-search-cancel-button,
.algolia-block .ais-SearchBox-form input::-webkit-search-results-button,
.algolia-block .ais-SearchBox-form input::-webkit-search-results-decoration {
  display: none;
}
.algolia-block .ais-SearchBox-form input::-ms-clear,
.algolia-block .ais-SearchBox-form input::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

.algolia-block .ais-SearchBox-form .ais-SearchBox-submit {
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  width: 26px;
  height: 26px;
  padding: 0;
  svg {
    width: 100%;
    path {
      fill: var(--primary-color);
    }
  }
}

.algolia-block .hide-search {
  display: none;
}

.algolia-container ul {
  padding: 0;
}

.algolia-container li {
  width: 100%;
  list-style: none;
  cursor: pointer;
  border: 1px solid #0e163a;
  margin-bottom: 0.5rem;
}

@media only screen and (min-width: 640px) {
  .algolia-container {
    margin: 0 -0.5%;

    li {
      width: 49%;
      margin: 0 0.5%;
    }
  }
}

@media only screen and (min-width: 890px) {
  .algolia-container {
    margin: 0 -0.66%;

    li {
      width: 32%;
      margin: 0 0.66%;
    }
  }
}

@media only screen and (min-width: 1200px) {
  .algolia-container {
    margin: 0 -0.5%;

    li {
      width: 24%;
      margin: 0 0.5%;
    }
  }
}

@media only screen and (min-width: 768px) {
  .algolia-container li {
    margin-bottom: 1rem;
  }
}

.algolia-container .ais-InfiniteHits-list {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: space-between;

  &:after {
    content: '';
    flex: auto;
  }
}

.algolia-container .ais-InfiniteHits-loadMore {
  width: 100%;
  max-width: 590px;
  height: 52px;
  border: 1px solid var(--primary-color);
  background-color: var(--primary-color);
  color: #fff;
  font-size: 1rem;
  font-family: Inter, sans-serif;
  font-weight: 600;
  display: block;
  margin-left: auto;
  margin-right: auto;

  &:hover {
    cursor: pointer;
    background: var(--primary-color-hover);
    border-color: var(--primary-color-hover);
  }
}

.algolia-hit-box {
  color: #0e163a;
  overflow: hidden;
}

.algolia-hit-box .algolia-img-block {
  //   height: 180px;
  text-align: center;
  padding: 30px 20px;
  border-bottom: solid 1px var(--bunting-color);
  margin-top: -17px;
}

.algolia-hit-box .algolia-img-block img {
  // height: 120px;
}

.algolia-text-block {
  padding: 14px 30px;
  position: relative;
}

.algolia-text-block h4 {
  font-size: 1.3rem;
  font-weight: 900;
  margin: 0;
  padding-bottom: 14px;
}

.algolia-text-block .text-span {
  display: block;
  margin: 0 0 1rem;
}

.algolia-text-block .LinesEllipsis {
  margin-bottom: 1rem;
}

.shape-tl {
  width: 34.1px;
  height: 34.1px;
  transform: rotate(-45deg);
  background-color: var(--growth-color);
  display: block;
  margin-top: -17px;
  margin-left: -17px;
}

.shape-tl.ALPHA {
  background-color: var(--alpha-color);
}

.shape-tl.BETA {
  background-color: var(--beta-color);
}

.shape-tl.Exhibitor {
  background-color: var(--bunting-color);
}

.rc {
  .shape-tl {
    &.ALPHA {
      background-image: var(--alpha-gradient-color);
    }

    &.BETA {
      background-image: var(--beta-gradient-color);
    }

    &.GROWTH {
      background-image: var(--growth-gradient-color);
    }

    &.Exhibitor {
      background-color: var(--bunting-color);
    }
  }
}

/* event partners specific */
// .ep .ais-InfiniteHits-loadMore {
//     display: none;
// }

@media only screen and (min-width: 768px) {
  .ais-InfiniteHits-loadMore--disabled {
    display: none !important;
  }

  .numCols-5 .ais-InfiniteHits-item {
    width: 19%;
  }

  .numCols-6 .ais-InfiniteHits-item {
    width: 15.66%;
  }
}
