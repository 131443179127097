.section-activityStream {
  margin-top: 130px;
  padding-left: 30px;
  color: #333;
  width: 25%;
  float: left;
  .row {
    flex: 1;
  }
  .title-block{
    width:100%;
    h3{
      color:#fff;
    }
  }
  .activity-live {
    text-align: center;
    span {
      border: 1px solid #cccccc;
      border-radius: 1rem;
      padding: 5px 10px;
      font-size: 0.85rem;
      color: #333;
      background: #fff;
    }
    .activity-hr {
      border-bottom: 1px solid #cccccc;
      margin-top: -8px;
    }
  }
  .single-activity {
    border-bottom: 1px solid #cccccc;
    padding: 1rem 0 1.2rem;
    a > span {
      color: #333;
    }
    .activity-icon {
      display: block;
      box-sizing: border-box;
      height: 33px;
      width: 33px;
      border: 1px solid #cccccc;
      border-radius: 17.5px;
      text-align: center;
      float: left;
      margin-right: 15px;
      padding: 7px;
      path,
      circle {
        fill: var(--primary-color);
      }
    }
    .activity-time {
      color: #999;
      display: block;
      font-size: 0.7rem;
    }
    .activity-text {
      font-size: 0.85rem;
      display: flex;
      flex-direction: column;
    }
  }
}

@media only screen and (max-width: 1020px) {
  .section-activityStream {
    width:100%;
    margin:0;
    .title-block{
      h3{
        color:#333;
      }
    }
  }
}
