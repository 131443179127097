.nowNext {
  margin: 0 auto;
  padding-bottom: 2rem;
  display: flex;
  flex-wrap: wrap;
  padding-top: 4rem;

  .inlineTimeslot {
    width: calc(100vw - 15%);
    overflow: hidden;
    margin-right: 1rem;
    @media only screen and (min-width: 768px) {
      width: calc(50% - 0.5rem);
      margin-right: 0;
    }
  }
  .mobileInlineTimeslot {
    width: calc(100vw - 15%);
    overflow: hidden;
    margin-right: 1rem;
  }

  &__timeslots {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    position: relative;
    justify-content: space-between;
    margin-right: -1rem;
    @media only screen and (min-width: 768px) {
      width: 75%;
      overflow-x: hidden;
      margin-right: 0;
    }
  }
  &__cta {
    padding: 1rem 2rem;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    .btn {
      width: 100%;
      border-radius: 2rem;
      margin-top: 1rem;
    }
    @media only screen and (min-width: 768px) {
      width: 25%;
    }
  }
}

@media only screen and (max-width: 768px) {
  .nowNext {
    padding-top: 2rem;
  }
}
