.section-channel {
  padding: 2rem 0;
  .row {
    // width: 100%;
    // margin: 0;
  }
  .flex-row {
    display: flex;
    margin-right: -10px;
    margin-left: -10px;
    margin-bottom: 0.5rem;
  }
  .title-block {
    flex: 50%;
    h2 {
      margin: 0;
    }
  }
  .book-now-block {
    flex: 50%;
    margin: auto;
    text-align: right;
    text-transform: uppercase;
    font-size: 0.75rem;
    a {
      color: #fff;
      background: var(--primary-color);
      padding: 0.5rem 2rem;
      margin-left: 1rem;
      border-radius: 2rem;
      text-decoration: none;
    }
    &.ismobile {
      flex: 100%;
      text-align: center;
      margin: 1rem 0 0;
      a {
        display: block;
        width: auto;
        max-width: 208px;
        margin: 10px auto 0;
      }
    }
  }
}

.channel-block {
  float: left;
  width: 24%;
  margin-right: 0.5%;
  margin-left: 0.5%;
  position: relative;
  .live-now {
    display: none;
    &.live {
      text-transform: uppercase;
      display: block;
      margin: 1rem 1rem 1.5rem;
      color: #fff;
      position: absolute;
      z-index: 2;
      font-weight: 600;
      font-size: 0.63rem;
      background: red;
      padding: 0.2rem 0.3rem;
      border-radius: 0.3rem;
    }
  }
}
.channel-img {
  height: 100%;
  width: 100%;
  -webkit-transition: all 1s ease;
  transition: all 1s ease;
  object-fit: cover;
  margin-bottom: 10px;
  border: 1px solid #e9e9e9;
  border-radius: 4px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 13.08%, rgba(0, 0, 0, 0.8) 100%);
}
.channel-content {
  height: 16%;
  width: 100%;
  position: absolute;
  bottom: 0;
  z-index: 2;
  font-weight: 600;
  font-size: 0.85rem;
  color: #fff;
  padding: 0.3rem 1rem;
  background-color: rgba(0, 0, 0, 0.3);
  svg {
    height: 100%;
    fill: #fff;
    display: inline-block;
    max-height: 0.85rem;
    padding-right: 0.3rem;
    opacity: 0.5;
  }
  h4 {
    display: inline-block;
    margin: 0;
  }
}

@media only screen and (max-width: 767px) {
  .channel-block {
    width: 48%;
    padding-bottom: 4px;
    .live-now.live {
      margin: 0.5rem;
    }
  }
  .channel-content {
    white-space: nowrap;
    margin-bottom: 0.8rem;
    padding: 0 0.5rem;
  }
}
