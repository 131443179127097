html {
  scroll-behavior: smooth;
}

.speakers {
    padding: 2rem 0;
    position: relative;
    overflow: hidden;
}

.container.speakers {
    padding: 2rem 1rem;
}

.speakers .row {
    align-items: flex-start;
}

.speakers .row .col-md-6,
.speakers .row .col-md-12 {
    flex-wrap: wrap;
    display: flex;
    justify-content: space-between;
    padding: 0 1rem;
}

.speakers .row .col-md-6:after,
.speakers .row .col-md-12:after {
    content: '';
    flex: auto;
}

.speakers .row .col-sm {
    display: flex;
    flex-wrap: wrap;
    padding-right: 0;
    padding-left: 1rem;
}

.speakers .loader {
    width: 100%;
}

.fixedList .speaker:not(.speaker__large) {
    width: 48%;
}

.speaker {
    color: var(--white-color);
    overflow: hidden;
    /* height: 160px; */
    height: auto;
    width: 24%;
    padding: 0;
    margin-bottom: 1rem;
    margin-right: 0.5%;
    margin-left: 0.5%;
    position: relative;
}

.speaker:hover {
    cursor: pointer;
}

.speaker:before {
    padding-top: 100%;
    display: block;
    content: "";
}

.speaker .speaker__image {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    height: 100%;
    width: 100%;
    transition: all 1s ease;
    object-fit: cover;
}

.speaker:hover .speaker__image {
    transform: scale(1.05);
}

.speaker .speaker__content__inner {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.speaker .speaker__content-wrap {
    /* position: relative;
    width: 100%;
    height: 100%; */
    position: absolute;
    overflow: hidden;
    top: 0;
    height: 100%;
    width: 100%;
}

.speaker.speaker__large {
    /* height: 300px; */
    height: auto;
    width: 100%;
}

.speaker__large .speaker__content {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1rem;
    background: radial-gradient(circle at bottom left, rgba(26, 56, 101, .4) 10%, rgba(17, 186, 227, .4) 80%);
    font-weight: 600;
    font-size: 1.2rem;
}

.coll .speaker__large .speaker__content {
    background: rgba(14, 185, 175, .7);
}

.rc .speaker__large .speaker__content {
    background: rgba(161, 29, 33, .7);
}

.venture .speaker__large .speaker__content {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#068b87+0,130f28+100&0.8+0,1+100 */
    background: -moz-linear-gradient(top, rgba(6, 139, 135, 0.8) 0%, rgba(19, 15, 40, 1) 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(6, 139, 135, 0.8) 0%, rgba(19, 15, 40, 1) 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(6, 139, 135, 0.8) 0%, rgba(19, 15, 40, 1) 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#cc068b87', endColorstr='#130f28', GradientType=0);
    /* IE6-9 */
}

.speaker:not(.speaker__large) .speaker__content-wrap:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    // background: rgba(0, 0, 0, .25);
    z-index: 1;
}

.speaker:not(.speaker__large) .speaker__content {
    height: 100%;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: flex-end;
    padding: 1rem;
    text-shadow: 1px 2px 3px #000;
    position: relative;
    z-index: 2;
    font-weight: 600;
    font-size: .85rem;
}

.speakers .btn {
    width: 50%;
    margin: 0 auto;
    display: block;
    color: #fff;
    background: var(--primary-color);
    border-color: var(--primary-color);
    font-weight: 600;
}

.speakers .btn:hover {
    background: var(--primary-color-hover);
    border-color: var(--primary-color-hover);
}

/* responsive square hacks */
@media only screen and (min-width: 550px) {
    .speaker {
        /* height: 280px; */
        height: auto;
    }
}

@media only screen and (min-width: 768px) {
    .speakers {
        padding: 4rem 1rem;
    }

    .speakers h2 {
        padding: 0;
    }

    .container.speakers {
        padding: 2rem 0;
    }

    .speakers .row .col-md-6 {
        padding: 0;
        justify-content: flex-start;
    }

    .speakers .row .col-md-12 {
        padding: 0;
    }

    .fixedList .speaker:not(.speaker__large) {
        width: 49%;
    }

    .speaker {
        padding: 0 1rem 1rem 0;
        /* height: 380px; */
        height: auto;
        margin-bottom: 0.4rem;
        ;
    }

    .speaker.speaker__large {
        /* height: 560px; */
        height: auto;
        margin-bottom: 0.3rem;
    }

    .speaker__large .speaker__content {
        font-size: 2.5rem;
        padding: 2.5rem;
    }

    .speaker:not(.speaker__large) .speaker__content {
        font-size: 1rem;
    }

    .speakers .btn {
        margin-top: 1rem;
    }

    .otherSpeakers .speaker {
        margin: 0 .3rem .3rem;
        padding: 0;
        width: calc(25% - .6rem);
    }
}

@media only screen and (max-width: 1200px) {
    .speaker {
        margin-bottom: 0.3rem;
    }
}

@media only screen and (max-width: 600px) {
    .speaker.speaker__large {
        margin-bottom: 0.5rem;
    }

    .fixedList .speaker:not(.speaker__large) {
        margin-bottom: 0.5rem;
    }
}

@media only screen and (max-width: 768px) {
    .speaker.speaker__large {
        margin-bottom: .5rem;
    }

    /* hack to make the small images work on small devices */
    .fixedList .speaker:not(.speaker__large),
    .otherSpeakers .speaker,
    .standard-layout .speaker {
        width: 49%;
        padding: 0 .5rem 0 0;
        margin-bottom: .5rem;

        height: auto;
        position: relative;
    }

    .fixedList .speaker:not(.speaker__large):nth-child(2n),
    .otherSpeakers .speaker:nth-child(2n),
    .standard-layout .speaker:nth-child(2n) {
        padding: 0 0 0 .5rem;
    }

    .speaker.speaker__large {
        padding: 0;
    }

    .otherSpeakers .speaker:nth-child(2n),
    .standard-layout .speaker:nth-child(2n) {
        padding: 0 0 0 .5rem;
    }

    .speakers .container {
        padding: 0 15px;
        margin: 0;
    }
}