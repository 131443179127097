/* Tabs */
.tabs {
  display: flex;
  align-items: flex-start;
  list-style: none;
  margin: 0;
  padding: 0;
  border-bottom: 1px solid var(--primary-color);
  overflow: hidden;
  height: 55px;
  @media only screen and (min-width: 768px) {
    height: 60px;
  }

  .tab {
    width: 25%;
    max-width: 250px;
    height: 100%;
    position: relative;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--bunting-color);
    z-index: 4;
    white-space: pre;
    padding: 0 16px;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -o-user-select: none;
    border-radius: 10rem;

    div {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 1rem;
    }
    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .font-app-icons {
      font-family: 'app-icons';
      width: auto;
      margin-right: 0.5rem;
    }

    &:before {
      content: '';
      position: absolute;
      left: -15px;
      background: transparent;
      width: 70%;
      height: 100%;
      z-index: -1;
      border-top-left-radius: 10px;
    }

    &:after {
      content: '';
      height: 66px;
      display: block;
      position: absolute;
      top: 0;
      z-index: -1;
      left: initial;
      right: -15px;
      width: 70%;
      transform: skew(20deg);
      border-top-right-radius: 10px;
    }

    &:nth-child(1) {
      z-index: 10;

      &,
      &:after,
      &:before,
      > div {
        background: var(--alto-color);
      }
      &:before {
        width: 80%;
      }
    }

    @mixin bgMixin($count) {
      &:nth-child(#{$count}) {
        z-index: 10 -$count;

        &,
        &:after,
        &:before,
        > div {
          background: lighten(#d4d4d4, $count * 2%);
        }
      }
    }

    @for $i from 2 through 10 {
      @include bgMixin($i);
    }

    &:hover {
      cursor: pointer;
      color: var(--white-color);

      &,
      &:after,
      &:before,
      > div {
        background: var(--primary-color);
      }
    }

    &.selected:after {
      background: var(--primary-color);
    }
    &:first-child.active:before {
      left: -35px;
    }

    &.active {
      z-index: 15;
      color: var(--white-color);

      &,
      > div,
      &:before,
      &:after {
        background: var(--bunting-color);
      }

      &:before {
        left: -10px;
        transform: skew(-20deg);
      }

      &:after {
        transition: all 0.2s ease;
        right: -10px;
      }
    }
  }

  &__content {
    padding: 3rem 0 0;
  }

  &__section {
    padding: 3rem 0;
  }
}

.rc .tabs li {
  color: var(--black-color);

  &.active {
    color: var(--white-color);
  }
}

.coll .tabs li:after {
  border-top-right-radius: 0;
}
