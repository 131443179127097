.formSection {
    padding: 2rem 0;
    z-index: 1;
    position: relative;
    overflow: hidden;
    &.primary-bg {
      color: var(--white-color);
      h2,
      .notHero .form__thank-you,
      .form__field.radio [type="radio"]:checked + label, .form__field.radio [type="radio"]:not(:checked) + label {
        color: var(--white-color);
      }
      .form__field.radio [type="radio"]:checked + label:before, .form__field.radio [type="radio"]:not(:checked) + label:before {
        border-color: var(--white-color);
      }
      .notHero .form__field.radio [type="radio"]:checked + label:after, .notHero .form__field.radio [type="radio"]:not(:checked) + label:after {
        background-color: var(--white-color);
      }
    }
}

.formSection h2 {
    margin-bottom: .725rem;
}

@media only screen and (min-width: 768px) {
    .formSection {
        padding: 3rem 0;
    }

    .formSection h2 {
        margin-bottom: 1.5rem;
    }
}

.ws .formSection h2 {
    color: var(--bunting-color);
}

/* Form error */
.formSection__error {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    background: rgba(0, 0, 0, .8);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;
    padding: 2rem;
    text-align: center;
    height: 100%;
    width: 100%;

    a {
        color: var(--white-color);
    }
}

/* Hero form */
.hero__form {
    align-items: center;
}

.hero__form>div {
    width: 100%;
}

.hero__form .formSection {
    padding-top: 0;
}

@media only screen and (max-width: 767px) {

    .hero__form .formSection,
    .hero__form .formSection>.container {
        padding: 0;
    }
}

/* Thank you */
.form__thank-you {
    font-size: 1.3rem;
    line-height: 1.2em;
}

.hero__form .form__thank-you {
    color: var(--white-color);
}

.notHero .form__thank-you {
    color: var(--primary-color);
}

@media only screen and (min-width: 768px) {
    .form__thank-you {
        font-size: 1.75rem;
        line-height: 1.2em;
    }
}

/* Form */
form.form {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    transition: all .3s ease;
}

form.form>div {
    position: relative;
    width: 100%;
}

/* Not hero form with two cols */
@media only screen and (min-width: 768px) {
    form.form {
        padding-bottom: 2rem;
    }

    .form.two-cols {
        padding-bottom: 0;
    }

    .notHero .form.two-cols {
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;
    }

    .notHero .form.two-cols>div:first-child,
    .notHero .form.two-cols>div.submit {
        width: 49%;
    }

    .notHero .form.two-cols>div.submit {
        padding-top: 1.2rem;
    }
}

@media only screen and (max-width: 767px) {
    .form.two-cols .form__field.floatingLabel {
        margin-bottom: 1rem;
    }
}

/* Fields */
.notHero .form .form__field .form__field-label {
    color: var(--primary-color);
    outline-color: var(--primary-color);
}

.primary-bg .notHero .form .form__field .form__field-label {
    color: var(--white-color);
    outline-color: var(--white-color);
}

.primary-bg .notHero .form .form__field.richText * {
    color: var(--white-color);
    outline-color: var(--white-color);
}

.coll {
  .notHero .form .form__field .form__field-label {
      color: var(--black-color);
      outline-color: var(--black-color);
  }
  .primary-bg .notHero .form .form__field .form__field-label {
    color: var(--white-color);
    outline-color: var(--white-color);
  }
}

.rc .notHero .form .form__field .form__field-label {
    color: var(--black-color);
    outline-color: var(--black-color);
}

.notHero .form .form__field select,
.notHero .form .form__field input,
.notHero .form .form__field textarea {
    color: var(--black-color);
    outline-color: var(--primary-color);
}

.ws .notHero .form .form__field select,
.ws .notHero .form .form__field input,
.ws .notHero .form .form__field textarea {
    color: var(--tundora-color);
    outline-color: var(--primary-color);
}

.notHero .form input:focus,
.notHero .form select:focus,
.notHero .form textarea:focus {
    border-color: var(--primary-color);
}

.form__field {
    margin-bottom: .5rem;
}

.form__field:last-child {
    margin-bottom: 2rem;
}

.form__field-wrap {
    position: relative;
}

.form__field.error:not(.phone):not(.file) input,
.form__field.error:not(.phone):not(.file) select {
    box-shadow: inset 0px 0px 0px 2px var(--primary-color);
    border: 1px solid var(--primary-color);
}

.form__field:not(.select):not(.phone):not(.file).error .form__field-wrap:after {
    font-family: "Font Awesome 5 Pro";
    content: '\f06a';
    font-size: 24px;
    font-weight: 100;
    color: var(--primary-color);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
}

.form__field span {
    font-size: .85rem;
    color: var(--white-color);
    margin-bottom: .5rem;
    margin-top: .3rem;
    display: block;
    width: 100%;
}

input,
select,
textarea {
    padding: 1rem;
    font-size: 1rem;
    width: 100%;
    color: var(--black-color);
    border: 1px solid var(--black-color);
    transition: all .2s ease;
    outline-color: var(--primary-color);
    -moz-appearance: none;
}

.ws input,
.ws select,
.ws textarea {
    color: var(--tundora-color);
    border: 1px solid var(--grey-color);
}

textarea {
    min-height: 100px;
    max-width: 100%;
}

.form__field-description {
    font-size: .8rem;
    padding: 3px 0 0;
}

.form .submit {
    margin: 0 auto;
}

@media only screen and (min-width: 768px) {
    .form .submit {
        max-width: 480px;
    }
}

.form .submit button {
    padding: 1rem;
    border: none;
    text-transform: uppercase;
    font-weight: 600;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%;
    height: 52px;
}

input[type="submit"]:hover {
    cursor: pointer;
    opacity: .9;
}

input[type="radio"] {
    width: auto;
}

/* Floating Label field */
.form__field.floatingLabel {
    position: relative;
    margin-bottom: .5rem;
    padding-top: 1.2rem;
}
.form__field {
  opacity: 0;
  display: none;
  &.active {
    display: block;
    -webkit-animation: fadeIn .5s forwards;
    animation: fadeIn .5s forwards;
  }
  &.hidden {
    display: none !important;
  }
}
@-webkit-keyframes fadeIn {
  from { opacity: 0; }
    to { opacity: 1; }
}
@keyframes fadeIn {
  from { opacity: 0; }
    to { opacity: 1; }
}

.form__field .floatingLabel__label {
    position: absolute;
    pointer-events: none;
    left: 1rem;
    top: 50%;
    transition: 0.2s ease all;
    margin: 0;
    font-size: .85rem;
    color: var(--primary-color);
    transform: translateY(-50%);
    width: 90%;
}

.coll .form__field .floatingLabel__label,
.rc .form__field .floatingLabel__label {
    color: var(--black-color);
}

.form__field.textarea .floatingLabel__label {
    top: 1.5rem;
}

.form__field .focused+.floatingLabel__label,
.form__field input:not([value=""])+.floatingLabel__label,
.form__field .textarea .focused+.floatingLabel__label .form__field.floatingLabel.float .floatingLabel__label {
    top: -12px;
    left: 0;
    color: var(--white-color);
}

@media only screen and (min-width: 768px) {
    .form__field .floatingLabel__label {
        font-size: .85rem;
    }
}

.notHero .form__field .focused+.floatingLabel__label {
    color: var(--primary-color);
}

.coll .notHero .form__field .focused+.floatingLabel__label {
    color: var(--black-color);
}

.formSection h2 {
    position: relative;
}

.ws .formSection h2 {
    font-family: 'alverata', serif;
}



// First two fields 50%
@media only screen and (min-width: 768px) {

    .formSection.first-two-half-width form .form__field:first-child,
    .formSection.first-two-half-width form .form__field:nth-child(2) {
        width: 49%;
    }
}



// Header form
.formSection.inHeader {
    padding: 0;
    background-color: var(--porcelain-color);

    .form {
        flex-wrap: nowrap;
        justify-content: flex-start;

        div.submit,
        .form__field.floatingLabel {
            margin: 0;
            padding: 0;
            width: auto;
        }

        .submit button {
            background: transparent;
            color: #000;
            font-size: 0;

            &:after {
                font-size: 1rem;
                content: '->';
            }
        }

        input {
            background-color: transparent;
            border: none;
        }
    }
}




// Disabled forms
.form__disabled {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, .8);
    color: #fff;
    z-index: 100;
    padding: 3rem;
}