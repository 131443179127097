/* Hollow Circle */
.newCircle.hollow:not(.lined-circle) svg path {
    background: transparent;
}

/* Circle  colors */
.newCircle.primary-circle path {
    fill: var(--primary-color);
}
.newCircle.secondary-circle path {
    fill: var(--secondary-color);
}
.newCircle.white-circle path {
    fill: var(--white-color);
}
.newCircle.black-circle path {
    fill: var(--black-color);
}
.newCircle.purple-circle path {
    fill: var(--purple-color);
}
.newCircle.orange-circle svg path {
    fill: var(--orange-color);
}
.newCircle.yellow-circle svg path {
    fill: var(--sun-color);
}
.newCircle.blue-circle svg path {
    fill: var(--blue-color);
}

/* Lined circle fix */
@media only screen and (max-width: 780px) {
    .newCircle.lined-circle {
        max-width: 150px;
        max-height: 150px;
    }
}