.video-section {
  padding: 0.5rem 0 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}

.video-section_nsBgShape {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  svg {
    position: absolute;
    left: -50%;
    top: -70%;
    width: 240%;
    height: 250%;
    z-index: 0;
    transform: rotate(-15deg);
  }
}

.video-section.modal {
  padding: 0;
  height: auto;
}

.ws .video-section .backgroundShape {
  display: none;
}

@media only screen and (min-width: 768px) {
  .video-section {
    padding: 5rem 0;
  }

  .ws .video-section {
    padding: 4rem 0;
  }

  .video-section.paddingBottomBig {
    padding: 10rem 0;
  }

  .ws .video-section .backgroundShape {
    display: flex;
  }
}

.video-section .container {
  position: relative;
  display: flex;
  justify-content: left;
  z-index: 4;
}

.activity-stream {
  padding-bottom: 0;
}

.activity-stream .container {
  justify-content: left;
  padding-bottom: 0;
  iframe {
    width: 75%;
  }
}

@media only screen and (max-width: 1020px) {
  .video-section .container {
    display: block;
  }
  .activity-stream .container {
    iframe {
      width: 100%;
    }
  }
  .video-section.activity-stream {
    margin-bottom: 0 !important;
  }
}

.video-section .container .row {
  width: 100%;
}

.video-section video {
  width: 80%;
  margin: 0 auto;
  display: block;
}

.video-section video:hover {
  cursor: pointer;
}

.video-section iframe {
  max-width: 100%;
}

@media only screen and (max-width: 767px) {
  .video-section iframe,
  .video-section video {
    height: auto;
    min-height: 350px;
    max-height: 400px;
  }
}
@media only screen and (max-width: 580px) {
  .video-section iframe,
  .video-section video {
    min-height: 300px;
  }
}
@media only screen and (max-width: 475px) {
  .video-section iframe,
  .video-section video {
    min-height: 250px;
  }
}
@media only screen and (max-width: 420px) {
  .video-section iframe,
  .video-section video {
    min-height: 220px;
  }
}

/* Cover */
.video-section__cover {
  position: relative;
  max-width: 960px;
  margin: 0 auto;
  &-extraShape {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    .ns-arrow {
      position: absolute;
      left: 2rem;
      bottom: -3rem;
      width: 80px;
    }
    .ns-text {
      position: absolute;
      right: -1rem;
      top: 50%;
      transform: translateY(-50%);
      width: 100px;
    }
    @media only screen and (min-width: 768px) {
      .ns-arrow,
      .ns-text {
        width: 120px;
      }
    }
  }
}

.video-section__cover video {
  max-width: 960px;
  width: 100%;
}

@media only screen and (min-width: 768px) {
  .video-section__cover {
    padding: 0 1rem;
  }
}

.video-section__cover:hover {
  cursor: pointer;
}

.video-section__cover > svg {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 62px;
  path {
    fill: var(--primary-color);
  }
}

@media only screen and (max-width: 767px) {
  .video-section__cover video {
    width: 100%;
    max-height: none;
    min-height: 0;
  }
}

/* Modal */
.video-section__modal {
  display: none;
  opacity: 0;
}

.video-section__modal.open {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.8);
  z-index: 10014;
  opacity: 1;
  animation: fadeIn 0.2s;
}

.video-section__modal-close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  transition: transform 0.4s ease-in-out;
  z-index: 10013;
}

.video-section__modal-close:hover {
  cursor: pointer;
}

.video-section__modal-close svg {
  width: 26px;
}

.video-section__modal-close svg path {
  fill: var(--primary-color);
}

.video-section__modal-close:hover {
  transform: rotate(180deg);
}

.video-section__modal .container {
  align-items: center;
}

.video-section__modal video {
  min-height: 0;
  width: 100%;
}

/* Orange color */
.video-section.orange .video-section__cover > svg path,
.video-section.orange .video-section__modal-close svg path {
  fill: var(--orange-color);
}

/* Video overlay */
.video-section .video-section__overlay:not(.background-shape) {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.95);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  z-index: 10012;
  overflow-x: auto;
  padding: 5rem 0 2rem;
}

@media only screen and (min-width: 768px) {
  .video-section .video-section__overlay:not(.background-shape) {
    padding: 2rem 0;
  }
}

.video-section__overlay .container {
  align-items: center;
  flex-wrap: wrap;
}

.video-section__overlay .container > div {
  width: 100%;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* bg variants */
.video-section.blue-bg h2 {
  color: var(--white-color);
}
.video-section.nsBg h2 {
  font-family: 'proxima-nova';
  text-transform: uppercase;
  color: var(--white-color);
  width: 100%;
  max-width: 500px;
}
.video-section.nsBg {
  background: var(--ns-red-color);
}
