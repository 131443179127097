.backgroundShape.blocks {
    display: flex;

    svg {
        height: 120px;
        width: auto;

        @media only screen and (min-width: 768px) {
            height: 220px;
        }
    }
}