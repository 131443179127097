.btn {
  padding: 1rem 1.5rem;
  background: var(--white-color);
  border: 1px solid;
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 400;
  transition: all 0.4s ease;
  display: inline-block;
  text-decoration: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  text-align: center;
}

.btn:hover {
  cursor: pointer;
  opacity: 1;
}

.btn:disabled:hover {
  cursor: not-allowed;
}

.btn.full-width {
  width: 100%;
  text-align: center;
}

.btn.wide {
  width: 100%;
  max-width: 480px;
}

@media only screen and (max-width: 767px) {
  .btn.full-width-small {
    width: 100%;
    text-align: center;
  }
}

.btn.no-style {
  background: transparent;
  border: none;
  color: var(--white-color);
  font-weight: 400;
  &:hover,
  &.active {
    color: var(--primary-color);
  }
}

/* Button colors */
.btn.btn-primary-white {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  color: var(--white-color);
}

.btn.btn-primary-white:hover,
.btn.btn-primary-white:active {
  background-color: var(--primary-color-hover);
  border-color: var(--primary-color-hover);
  color: var(--white-color);
}

.btn.btn-primary-white:disabled {
  background: var(--bombay-color);
  border-color: var(--black-color);
  cursor: not-allowed;
}

.btn.btn-white-primary {
  background-color: var(--white-color);
  border-color: var(--primary-color);
  color: var(--primary-color);
}

.btn.btn-white-primary:hover,
.btn.btn-white-primary:active {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  color: var(--white-color);
}

.btn.btn-primary-transparent {
  background-color: transparent;
  border-color: var(--primary-color);
  color: var(--primary-color);
}

.btn.btn-primary-transparent:hover,
.btn.btn-primary-transparent:active {
  background-color: var(--primary-color-hover);
  border-color: var(--primary-color-hover);
  color: var(--white-color);
}

.btn.btn-secondary-white {
  background-color: var(--secondary-color);
  border-color: var(--secondary-color);
  color: var(--white-color);
}

.btn.btn-secondary-white:hover,
.btn.btn-secondary-white:active {
  background-color: var(--secondary-color-hover);
  border-color: var(--secondary-color-hover);
}

.btn.btn-white-secondary {
  background-color: var(--white-color);
  border-color: var(--black-color);
  color: var(--black-color);
}

.btn.btn-white-secondary:hover,
.btn.btn-white-secondary:active {
  background-color: var(--black-color);
  border-color: var(--black-color);
  color: var(--white-color);
}

.btn.btn-secondary-transparent {
  background-color: transparent;
  border-color: var(--secondary-color);
  color: var(--secondary-color);
}

.btn.btn-secondary-transparent:hover,
.btn.btn-secondary-transparent:active {
  background-color: var(--secondary-color-hover);
  border-color: var(--secondary-color-hover);
  color: var(--white-color);
}

.btn.btn-white-green {
  background: var(--white-color);
  border-color: var(--tradewind-color);
  color: var(--tradewind-color);
}

.btn.btn-white-green:active,
.btn.btn-white-green:hover {
  background: var(--tradewind-color);
  color: var(--white-color);
}

.btn.btn-green-white {
  background: var(--tradewind-color);
  border-color: var(--tradewind-color);
  color: var(--white-color);
}

.btn.btn-green-white:hover,
.btn.btn-green-white:active {
  background: var(--tradewind-color-hover);
  border-color: var(--tradewind-color-hover);
}

.btn.btn-pink {
  background-color: var(--secondary-color);
  color: var(--white-color);
  border: 0;
}

.btn.btn-pink:hover {
  background-color: var(--secondary-color-hover);
}

.btn.btn-orange-white {
  background-color: var(--orange-color);
  border-color: var(--orange-color);
  color: var(--white-color);
}

.btn.btn-orange-white:hover,
.btn.btn-orange-white:active {
  background-color: var(--orange-color-hover);
  border-color: var(--orange-color-hover);
}

.btn.btn-white-orange {
  background-color: var(--white-color);
  border-color: var(--orange-color);
  color: var(--orange-color);
}

.btn.btn-white-orange:hover,
.btn.btn-white-orange:active {
  background-color: var(--white-color);
  border-color: var(--orange-color-hover);
}

.btn.btn-orange-transparent {
  background-color: transparent;
  border-color: var(--orange-color);
  color: var(--orange-color);
}

.btn.btn-orange-transparent:hover,
.btn.btn-orange-transparent:active {
  background-color: var(--orange-color-hover);
  border-color: var(--orange-color-hover);
  color: var(--white-color);
}

.btn.btn-ns-orange-white {
  background-color: var(--ns-orange-color);
  border-color: var(--ns-orange-color);
  color: var(--white-color);
}

.btn.btn-ns-orange-white:hover,
.btn.btn-ns-orange-white:active {
  background-color: var(--ns-orange-color-hover);
  border-color: var(--ns-orange-color-hover);
}

.btn.btn-ns-white-orange {
  background-color: var(--white-color);
  border-color: var(--ns-orange-color);
  color: var(--ns-orange-color);
}

.btn.btn-ns-white-orange:hover,
.btn.btn-ns-white-orange:active {
  background-color: var(--ns-orange-color-hover);
  border-color: var(--ns-orange-color-hover);
  color: var(--white-color);
}

.btn.btn-sun-white {
  background-color: var(--sun-color);
  border-color: var(--sun-color);
  color: var(--white-color);
}

.btn.btn-sun-white:hover,
.btn.btn-sun-white:active {
  background-color: var(--sun-color-hover);
  border-color: var(--sun-color-hover);
}

.btn.btn-white-sun {
  background-color: var(--white-color);
  border-color: var(--sun-color);
  color: var(--sun-color);
}

.btn.btn-white-sun:hover,
.btn.btn-white-sun:active {
  background-color: var(--white-color);
  border-color: var(--sun-color-hover);
}

.btn.btn-bunting-white {
  background-color: var(--bunting-color);
  border-color: var(--bunting-color);
  color: var(--white-color);
}

.btn.btn-bunting-white:hover,
.btn.btn-bunting-white:active {
  background-color: var(--white-color);
  border-color: var(--bunting-color);
  color: var(--bunting-color);
}

.btn.btn-porcelain-white {
  background-color: var(--porcelain-color);
  border-color: var(--porcelain-color);
  color: var(--dove-gray);
}

.btn.btn-porcelain-white:hover,
.btn.btn-porcelain-white:active {
  background-color: var(--dove-gray);
  border-color: var(--porcelain-color);
  color: var(--porcelain-color);
}

.btn.btn-eminence-white {
  background-color: var(--eminence-color);
  border-color: var(--eminence-color);
  color: var(--white-color);
}

.btn.btn-eminence-white:hover,
.btn.btn-eminence-white:active {
  background-color: var(--white-color);
  border-color: var(--eminence-color);
  color: var(--eminence-color);
}

.btn.btn-venture-purple-white {
  background-color: var(--venture-purple);
  border-color: var(--venture-purple);
  color: var(--white-color);
}

.btn.btn-venture-purple-white:hover,
.btn.btn-venture-purple-white:active {
  background-color: var(--venture-purple-light);
  border-color: var(--venture-purple);
  color: var(--white-color);
}

.btn.btn-optOut{
  margin-top:8px;
  border-color: #999999;
}
