.loader {
    padding: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10000;
    position: relative;
}
.pageLoader {
    padding: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10020;
    position: relative;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: var(--white-color);
}

/* Ball path styles */
.ball-triangle-path {
    position: relative;
    width: 60px;
    height: 60px;
}
.ball-triangle-path > div:nth-child(1) {
    -webkit-animation-name: ball-triangle-path-1;
    animation-name: ball-triangle-path-1;
    -webkit-animation-delay: 0;
    animation-delay: 0;
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite; 
}
.ball-triangle-path > div:nth-child(2) {
    -webkit-animation-name: ball-triangle-path-2;
    animation-name: ball-triangle-path-2;
    -webkit-animation-delay: 0;
    animation-delay: 0;
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite; 
}
.ball-triangle-path > div:nth-child(3) {
    -webkit-animation-name: ball-triangle-path-3;
    animation-name: ball-triangle-path-3;
    -webkit-animation-delay: 0;
    animation-delay: 0;
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite; 
}
.ball-triangle-path > div {
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background-color: var(--primary-color);
}
.ball-triangle-path > div:nth-of-type(1) {
    top: 50px; 
}
.ball-triangle-path > div:nth-of-type(2) {
    left: 25px; 
}
.ball-triangle-path > div:nth-of-type(3) {
    top: 50px;
    left: 50px; 
}


/* bg variants */
.loader.primary-bg .ball-triangle-path > div,
.loader.secondary-bg .ball-triangle-path > div,
.loader.blue-bg .ball-triangle-path > div,
.loader.purple-bg .ball-triangle-path > div,
.loader.orange-bg .ball-triangle-path > div,
.loader.yellow-bg .ball-triangle-path > div {
    background-color: var(--white-color);
}


/* Keyframes */
@-webkit-keyframes ball-triangle-path-1 {
    33% {
        -webkit-transform: translate(25px, -50px);
        transform: translate(25px, -50px); 
    }
    66% {
        -webkit-transform: translate(50px, 0px);
        transform: translate(50px, 0px); 
    }
    100% {
        -webkit-transform: translate(0px, 0px);
        transform: translate(0px, 0px); 
    } 
}
@keyframes ball-triangle-path-1 {
    33% {
        -webkit-transform: translate(25px, -50px);
        transform: translate(25px, -50px); 
    }
    66% {
        -webkit-transform: translate(50px, 0px);
        transform: translate(50px, 0px); 
    }
    100% {
        -webkit-transform: translate(0px, 0px);
        transform: translate(0px, 0px); 
    } 
}
@-webkit-keyframes ball-triangle-path-2 {
    33% {
        -webkit-transform: translate(25px, 50px);
        transform: translate(25px, 50px); 
    }
    66% {
        -webkit-transform: translate(-25px, 50px);
        transform: translate(-25px, 50px); 
    }
    100% {
        -webkit-transform: translate(0px, 0px);
        transform: translate(0px, 0px); 
    } 
}
@keyframes ball-triangle-path-2 {
    33% {
        -webkit-transform: translate(25px, 50px);
        transform: translate(25px, 50px); 
    }
    66% {
        -webkit-transform: translate(-25px, 50px);
        transform: translate(-25px, 50px); 
    }
    100% {
        -webkit-transform: translate(0px, 0px);
        transform: translate(0px, 0px); 
    } 
}
@-webkit-keyframes ball-triangle-path-3 {
    33% {
        -webkit-transform: translate(-50px, 0px);
        transform: translate(-50px, 0px); 
    }
    66% {
        -webkit-transform: translate(-25px, -50px);
        transform: translate(-25px, -50px); 
        }
    100% {
        -webkit-transform: translate(0px, 0px);
        transform: translate(0px, 0px); 
        } 
}
@keyframes ball-triangle-path-3 {
    33% {
        -webkit-transform: translate(-50px, 0px);
        transform: translate(-50px, 0px); 
    }
    66% {
        -webkit-transform: translate(-25px, -50px);
        transform: translate(-25px, -50px); 
    }
    100% {
        -webkit-transform: translate(0px, 0px);
        transform: translate(0px, 0px); 
    } 
}