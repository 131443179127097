.fringe-events-container {
    .events__single {
        a {
            text-transform: uppercase;
            text-decoration: none;
        }

        .events__single-button:after {
            content: 'Register';
        }
    }
}