.iconText-parent{
	padding-top:20px;
	padding-bottom:20px;
	position: relative;
	overflow: hidden;
}
@media screen and (min-width: 768px) {
	.iconText-parent {
		padding: 3rem 0 4rem 0;
	}
}

.iconText-block{
	padding: 20px 8px;
    text-align: center;
    display: flex;
	justify-content: flex-start;
	flex-direction: column;
}

.iconText-block .image-block{
    display: flex;
    align-items: center;
	justify-content: flex-start;
	padding: 0 1rem;
}

.iconText-block .image-block .block-line{
	width:50%;
	height:8px;
}

.iconText-block img{
	max-width: 200px;
	width:100%;
}
.iconText-block svg {
	height: 145px;
	width: auto;
}

.iconText-block span{
	width:100%;
	display:block;
	text-align: left;
	line-height: 1.4;
	padding: 0 1rem;
}

.iconText-block span h3{
	width:100%;
	display:block;
	font-weight: 900;
	line-height: 1.17;
}
.ws .iconText-block span h3 {
	color: var(--bunting-color);
}

.iconText-parent.background-shape span h3{
	color:#fff;
}

@media screen and (max-width: 767px) {
	.iconText-block{
		flex-direction: row;
		justify-content: space-between;
	}
	.iconText-block .image-block{
		width:30%;
		float:left;
		align-items: center;
    	display: flex;
		padding: 0;
	}
	.iconText-block span{
		width:65%;
		float:right;
		padding: 0;
		font-size: .85rem;
	}
	.iconText-block:nth-of-type(even) .image-block{
		float:right;
		justify-content: flex-end;
	}
	.iconText-block:nth-of-type(even) span{
		float:left;
	}
	.iconText-block:nth-child(2n){
		flex-direction: row-reverse;
	}
}

/* bg variants */
.iconText-parent.primary-bg,
.primary-bg .iconText-block span h3,
.iconText-parent.secondary-bg,
.secondary-bg .iconText-block span h3,
.iconText-parent.purple-bg,
.purple-bg .iconText-block span h3,
.iconText-parent.blue-bg,
.blue-bg .iconText-block span h3,
.iconText-parent.orange-bg,
.orange-bg .iconText-block span h3 {
	color: var(--white-color);
}

.iconText-parent.blue-bg,
.blue-bg .iconText-block span h3 {
	color: var(--bunting-color)
}