.backgroundShape.newSwirl {
    position: absolute;
}
.backgroundShape.newSwirl {
    width: 100%;
    max-width: 100%;
}
@media only screen and (max-width: 767px) {
    .backgroundShape.newSwirl {
        max-width: 200px
    }
}

.primary-swirl g,
.primary-swirl path {
    stroke: var(--primary-color);
}

.secondary-swirl g,
.secondary-swirl path {
    stroke: var(--secondary-color);
}

.white-swirl g,
.white-swirl path {
    stroke: var(--white-color);
}

.black-swirl g,
.black-swirl path {
    stroke: var(--black-color);
}

.orange-swirl g,
.orange-swirl path {
    stroke: var(--orange-color);
}

.purple-swirl g,
.purple-swirl path {
    stroke: var(--butterfly-color);
}

.blue-swirl g,
.blue-swirl path {
    stroke: var(--bunting-color);
}

.yellow-swirl g,
.yellow-swirl path {
    stroke: var(--sun-color);
}

.pink-swirl g,
.pink-swirl path {
    stroke: var(--secondary-color);
}

.green-swirl g,
.green-swirl path {
    stroke: var(--primary-color)
}

.purple-swirl g,
.purple-swirl path{
    stroke: var(--butterfly-color);
}

.blue-swirl g,
.blue-swirl path{
    stroke: var(--bunting-color);
}



/* bg v2 */
.newSwirl.bottom {
    bottom: 2rem;
}