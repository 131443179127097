.hero {
  background-color: var(--primary-color);
  align-items: center;
  display: flex;
  position: relative;
  color: var(--white-color);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  padding: 5rem 0 3rem;
  overflow: hidden;

  .hero__scroll-down {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 4rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;

    svg {
      position: absolute;
      bottom: 10px;
      left: 50%;
      transform: translateX(-50%);
      animation: MoveUpDown 2s ease-out infinite;
      width: 30px;

      &:hover {
        cursor: pointer;
        animation-play-state: paused;
      }
    }
  }

  .container {
    .center {
      justify-content: center;
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
    }

    .bottom {
      justify-content: flex-end;
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
    }
  }
}

.hero.alignTop {
  align-items: flex-start;
}

.hero svg {
  width: 100%;
}

@media only screen and (min-width: 768px) {
  .hero {
    min-height: 630px;
    padding: 6rem 0 2rem;
  }

  .hero svg {
    width: 90%;
  }

  .hero .backgroundShape:not(.blocks) svg {
    width: 100%;
  }

  .ws .hero:after {
    content: '';
    position: absolute;
    left: -100px;
    top: 50%;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    background: var(--primary-color);
    transform: translateY(-50%);
  }

  .ws .hero.no-circle::after {
    content: none;
  }
}

@media only screen and (min-width: 1400px) {
  .ws .hero {
    min-height: 700px;
  }
}

.hero.formVisible {
  height: auto;
  padding: 8rem 0 2rem;
}

.hero .container {
  max-width: 960px;
  padding: 0 1rem;
}

.ws .hero .container {
  position: relative;
  z-index: 1;
}

.ws .hero .container h1:not(.form__thank-you) {
  font-family: 'alverata', serif;
  color: var(--white-color);
}

.ws .hero.formVisible .container h1,
.ws .hero .container h1.full,
.rc .hero .container h1.full,
.coll .hero .container h1.full,
.mc .hero .container h1.full {
  width: 100%;
}

.coll .hero__form a {
  color: var(--white-color);
}

@media only screen and (min-width: 768px) {
  .hero .container h1:not(.form__thank-you) {
    width: 50%;
    font-size: 2.4rem;
  }
}

.hero p {
  font-size: 1.2rem;
}

.hero .hero-btn {
  padding: 1rem;
  text-transform: uppercase;
  font-weight: 600;
  width: 100%;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hero-btn:first-of-type {
  margin-bottom: 2rem;
}

.hero .hero-btn:hover {
  opacity: 1;
}

.hero .hero-btn svg {
  margin-right: 1rem;
  max-height: 30px;
  min-height: 30px;
  max-width: 25px;
}

.hero .hero-btn svg path {
  fill: var(--white-color);
}

@media only screen and (min-width: 768px) {
  .hero__form {
    margin-top: -0.6rem;
  }
}

.hero h1.two-title:last-of-type {
  display: flex;
}

.hero h1.two-title:last-of-type svg {
  align-self: flex-end;
  width: 100%;
}

.hero h1 span,
.hero h2 span {
  font-size: 1rem;
  font-weight: 400;
  white-space: nowrap;
}

.hero__bg-slider {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.hero__bg-slider .swiper-wrapper,
.hero__bg-slider .swiper-slide {
  width: 100%;
  height: 100%;
  background-position: center center;
  background-size: cover;
}

.hero__bg-slider.swiper-container-horizontal > .swiper-pagination-bullets {
  position: absolute;
  bottom: 1.5rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  width: auto;
}

.hero__bg-slider.swiper-container-horizontal
  > .swiper-pagination-bullets
  .swiper-pagination-bullet {
  width: 14px;
  height: 14px;
}

.hero__bg-slider.swiper-container-horizontal
  > .swiper-pagination-bullets
  .swiper-pagination-bullet-active {
  width: 21px;
  height: 21px;
}

.hero .dot-container {
  z-index: 0;
  top: 5rem;
  right: 1rem;
  opacity: 0.4;
}

.hero .newCircle {
  opacity: 0.4;
}

@media only screen and (min-width: 768px) {
  .hero .dot-container {
    right: 2rem;
    opacity: 1;
  }

  .hero .newCircle {
    opacity: 1;
  }
}

@media only screen and (max-width: 768px) {
  .hero .hero-bottom-text {
    display: none;
  }
}

.hero .hero-bottom-text {
  position: absolute;
  bottom: 5px;
  right: 5px;
  font-size: 0.8rem;
  z-index: 4;
}

.disclaimer {
  font-size: 0.7em;
}

.hero.reduced-height {
  min-height: 500px;
}

.hero.heightInitial {
  min-height: initial;
}

// Essentials Hero
.hero .container h1:not(.form__thank-you).titleBg {
  padding: 0.5rem 1rem;
  width: initial;
  display: inline-block;
  font-weight: 400;

  &.pullTitle {
    position: relative;
    z-index: 2;
    margin-left: 2rem;
  }
}

.hero .contentBg {
  margin-top: -3.4rem;
  padding: 3rem 2rem 2rem;
  background: $shark-gray;
  position: relative;
  z-index: 1;
  display: inline-block;
  max-width: 400px;
}

// Full height hero
.hero.fullHeight {
  height: 100%;
  height: 100vh;
  padding: 0;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  &.displayTicker {
    height: calc(100vh - 120px);

    .hero__scroll-down {
      bottom: 2rem;
    }
  }

  > .container {
    max-width: 500px;
    text-align: center;
    z-index: 4;
  }

  .concentricCircleBold,
  .concentricCircleBoldLg {
    position: absolute;
  }

  .concentricCircleBold {
    right: -75px;
    bottom: -75px;
    width: 150px;
    height: 150px;
    z-index: 1;
  }

  @media only screen and (max-width: 767px) {
    .concentricCircleBold:first-of-type {
      left: -75px;
      right: initial;
      bottom: initial;
      top: 25%;
      transform: translateY(-50%);
    }
  }

  @media only screen and (min-width: 768px) {
    &.displayTicker {
      height: calc(100vh - 100px);
    }

    .hero__scroll-down {
      bottom: 3rem;
    }

    .concentricCircleBold {
      width: 200px;
      height: 200px;
      left: -100px;
      top: 25%;
      transform: translateY(-50%);
    }

    .concentricCircleBoldLg {
      right: -250px;
      top: 65%;
      transform: translateY(-50%);
      width: 500px;
      height: 500px;
    }
  }
}

@keyframes MoveUpDown {
  0%,
  100% {
    bottom: 10px;
  }

  50% {
    bottom: 20px;
  }
}

// shapeTextImage Hero
.hero.shapeTextImage {
  $e: &;
  padding: 0;

  .zigZagBoldLine {
    position: absolute;
    top: 30%;
    left: -100px;
    width: 70%;
    z-index: 1;
  }
  .full-circle {
    position: absolute;
    top: 30%;
    left: -100px;
    z-index: 2;
    width: 200px;
  }
  .hexagon {
    position: absolute;
    top: 20%;
    left: -100px;
    z-index: 2;
    width: 200px;
    height: 200px;
  }
  .waves {
    position: absolute;
    left: -100px;
    width: 70%;
    height: auto;
    top: 40%;
  }
  .square {
    content: '';
    position: absolute;
    right: 0;
    top: 45%;
    width: 50%;
    height: 80%;
    background: var(--primary-color);
    z-index: 0;
    transform: translateY(-50%);
  }

  > .container {
    z-index: 4;

    h1 {
      margin-left: 2rem;
    }

    p {
      color: var(--white-color);
      background: $shark-gray;
      max-width: 500px;
      padding: 3.5rem 2rem 3rem;
      margin-top: -55px;
      margin-bottom: 0;
    }
  }

  .shapeTextImage__image {
    height: 250px;
    width: 100%;
    background-position: center center;
    background-size: cover;
  }

  @media only screen and (min-width: 768px) {
    height: 500px;
    min-height: auto;
    padding: 6rem 0;
    align-items: flex-end;

    .shapeTextImage__image {
      position: absolute;
      right: 0;
      top: 50%;
      height: 80%;
      width: 60%;
      transform: translateY(-50%);
      z-index: 2;
    }
  }

  @media only screen and (max-width: 767px) {
    flex-direction: column;
    padding-bottom: 0;

    > .container {
      padding: 0;
      margin-top: -27px;

      p {
        padding-bottom: 4rem;
      }
    }

    .hero__scroll-down {
      bottom: 0;
      left: 60px;
      transform: none;

      svg {
        width: 20px;
      }
    }
  }

  @at-root .ws #{$e} .titleBg {
    background: var(--secondary-color);
  }
}

// Ns Shape hero
.hero .backgroundShape.barrels {
  right: 0;
  top: 0;
  height: 100%;
  width: 60%;
  @media only screen and (min-width: 768px) {
    width: 50%;
  }
  svg {
    width: auto;
    height: 100%;
    position: absolute;
    left: 0;
    top: 40%;
    @media only screen and (min-width: 768px) {
      height: 150%;
      top: 10%;
    }
  }
}
