.backgroundShape.lines {
    width: 150px;
    height: 100%;
}

.backgroundShape.lines.diagonal {
    height: 100px;
    width: 100px;
    transform: none;
}

@media only screen and (min-width: 768px) {
    .backgroundShape.lines.diagonal {
        width: 250px;
        height: 250px;
    }
}

.backgroundShape.lines:not(.diagonal) {
    width: auto;
}

.backgroundShape.lines:not(.diagonal) svg {
    height: 100%;
}

.backgroundShape.lines.primary svg:not(.ventureWrap) polygon,
.backgroundShape.lines.primary svg:not(.ventureWrap) path {
    fill: var(--primary-color);
}

.backgroundShape.lines.secondary svg:not(.ventureWrap) polygon,
.backgroundShape.lines.secondary svg:not(.ventureWrap) path {
    fill: var(--secondary-color);
}

.backgroundShape.lines.white svg:not(.ventureWrap) polygon,
.backgroundShape.lines.white svg:not(.ventureWrap) path {
    fill: var(--white-color);
}

.backgroundShape.lines.black svg:not(.ventureWrap) polygon,
.backgroundShape.lines.black svg:not(.ventureWrap) path {
    fill: var(--black-color);
}

.backgroundShape.lines.blue svg:not(.ventureWrap) polygon,
.backgroundShape.lines.blue svg:not(.ventureWrap) path {
    fill: var(--blue-color);
}

.backgroundShape.lines.diagonal.half-left-bottom svg {
    transform: rotate(-90deg)
}

.backgroundShape.lines.diagonal.half-right-top svg {
    transform: rotate(90deg)
}

.backgroundShape.lines.diagonal.half-right-bottom svg {
    transform: rotate(180deg)
}

// Venture lines
.backgroundShape.lines.venture-wrap {
    @media only screen and (max-width: 768px) {
        width: 100%;
        position: absolute;
        top: 0;
        right: 0;

        .bottomLine {
            position: absolute;
            right: 0;
            bottom: 10px;
            width: 60%;
            height: 2px;
            background: #09002A;

            &:after {
                content: '';
                width: 110px;
                height: 12px;
                position: absolute;
                top: -5px;
                right: 50%;
                transform: translateX(50%) skew(-20deg);
                background: #000;
                /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#068b87+0,130f28+100 */
                background: rgb(6, 139, 135);
                /* Old browsers */
                background: -moz-linear-gradient(left, rgba(6, 139, 135, 1) 0%, rgba(19, 15, 40, 1) 100%);
                /* FF3.6-15 */
                background: -webkit-linear-gradient(left, rgba(6, 139, 135, 1) 0%, rgba(19, 15, 40, 1) 100%);
                /* Chrome10-25,Safari5.1-6 */
                background: linear-gradient(to right, rgba(6, 139, 135, 1) 0%, rgba(19, 15, 40, 1) 100%);
                /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#068b87', endColorstr='#130f28', GradientType=1);
                /* IE6-9 */
            }
        }
    }

    @media only screen and (min-width: 768px) {
        right: 50%;
        top: 0;
        width: 100%;
        max-width: 960px;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
        transform: translateX(50%);

        +.container {
            max-width: 550px;
        }

        .diagLine {
            position: absolute;
            right: 65px;
            top: -20px;
            width: 2px;
            height: calc(100% + 40px);
            background: #09002A;
            transform: rotate(-20deg);

            &:after {
                content: '';
                width: 12px;
                height: 110px;
                position: absolute;
                top: 50%;
                left: -5px;
                transform: translateY(-50%);
                background: #000;
                /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#068b87+0,130f28+100 */
                background: rgb(6, 139, 135);
                /* Old browsers */
                background: -moz-linear-gradient(top, rgba(6, 139, 135, 1) 0%, rgba(19, 15, 40, 1) 100%);
                /* FF3.6-15 */
                background: -webkit-linear-gradient(top, rgba(6, 139, 135, 1) 0%, rgba(19, 15, 40, 1) 100%);
                /* Chrome10-25,Safari5.1-6 */
                background: linear-gradient(to bottom, rgba(6, 139, 135, 1) 0%, rgba(19, 15, 40, 1) 100%);
                /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#068b87', endColorstr='#130f28', GradientType=0);
                /* IE6-9 */
            }
        }

        .bottomLine {
            position: absolute;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 2px;
            background: #09002A;
        }
    }
}

.backgroundShape.lines {
    .venture-logo {
        display: none;

        @media only screen and (min-width: 768px) {
            display: block;
            right: 0;
            top: 0;
            width: 100%;
            height: 100%;

            &:after {
                content: '';
                position: absolute;
                bottom: 5px;
                left: 0;
                width: calc(100% - 450px);
                height: 3px;
                background: $venture-purple;
            }

            svg {
                position: absolute;
                bottom: 5px;
                right: 0;
                width: 450px;
                height: auto;
            }
        }
    }

    &.venture-inline {

        &.white svg polygon {
            fill: var(--white-color);
        }

        &.purple svg polygon {
            fill: var(--venture-purple);
        }

        &.blue svg polygon {
            fill: var(--venture-blue);
        }
    }

    &.venture-logo {

        &.white svg g {
            stroke: var(--white-color);
        }

        &.purple svg g {
            stroke: var(--venture-purple);
        }

        &.blue svg g {
            stroke: var(--venture-blue);
        }
    }
}