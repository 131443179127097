.schedule__timeslots .timeslot {
  padding: 0 0.25rem 0 0;
  margin: 6px 0;
  height: 86px;
  display: flex;
  flex-wrap: wrap;
  margin-right: 0.5rem;
  overflow: hidden;
  position: absolute;
  top: 0;
  animation: fadeIn 0.2s;
}

.agenda .schedule__timeslots .timeslot.agendaNoTimeline {
  padding: 6px 10px 0;
  position: static;
  height: auto;
}

@media only screen and (min-width: 768px) {
  .schedule__timeslots .timeslot {
    height: 184px;
  }
}

.schedule__timeslots .timeslot.break .timeslot__content {
  background: var(--primary-color);
  color: var(--white-color);
}

.ws .schedule__timeslots .timeslot.break .timeslot__content {
  background: var(--bunting-color);
}

.schedule__timeslots .timeslot.break .timeslot__content h3,
.schedule__timeslots .timeslot.break .timeslot__content .text,
.break .timeslot__content .timeslot__time {
  color: var(--white-color);
}

.timeslot__content {
  border: 1px solid var(--black-color);
  padding: 0.5rem;
  background: var(--white-color);
}

.ws .timeslot__content {
  border-color: var(--bunting-color);
}

.schedule__timeslots .timeslot:hover {
  cursor: pointer;
  z-index: 9;
}

.schedule__timeslots .timeslot:hover .timeslot__content {
  background: var(--concrete-color);
}

.schedule__timeslots .timeslot.break:hover .timeslot__content {
  background: var(--primary-color-hover);
}

.ws .schedule__timeslots .timeslot.break:hover .timeslot__content {
  background: var(--bunting-color);
}

.schedule__timeslots .timeslot h3 {
  margin-top: 0;
  font-size: 0.85rem;
  margin-bottom: 0.5rem;
}

.schedule__timeslots .timeslot__time {
  color: var(--primary-color);
  font-weight: 600;
  font-size: 0.75rem;
  align-self: flex-end;
}

@media only screen and (min-width: 768px) {
  .schedule__timeslots .timeslot h3 {
    font-size: 1rem;
  }
}

.timeslot__content {
  position: relative;
  width: 100%;
  transition: all 0.2s ease;
}

.timeslot__content > div {
  max-width: 500px;
}

.timeslot__content .timeslot__time {
  position: absolute;
  left: 0.5rem;
  bottom: 0.5rem;
  width: 85%;
  width: calc(100% - 1rem);
}

.timeslot__overlay {
  position: fixed;
  top: 0;
  width: 100%;
  max-width: 600px;
  height: 100%;
  z-index: 10014;
  transform: scale(0);
}

.timeslot__overlay.opened {
  overflow: hidden;
  animation: rightToLeft 0.7s;
  right: 0;
  transform: scale(1);
}

.timeslot__overlay.opened.ticker {
  height: calc(100% - 100px);
}

.timeslot__overlay-content {
  height: 100%;
  width: 100%;
  background-color: var(--white-color);
  margin: 0 0 0 auto;
  position: relative;
  z-index: 2;
  overflow-y: auto;
  overscroll-behavior: contain;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  padding-bottom: 4rem;
}

@media only screen and (min-width: 768px) {
  .timeslot__overlay {
    width: 40%;
  }

  .timeslot__overlay-content {
    border-left: 1px solid var(--black-color);
  }

  .ws .timeslot__overlay-content {
    border-left-color: var(--bunting-color);
  }
}

.timeslot__overlay-topics .timeslot__content-title {
  width: 100%;
  margin-bottom: 0.5rem;
}

.timeslot__overlay-content > div {
  width: 100%;
}

.timeslot__overlay-bgCover {
  background: transparent;
}

.timeslot__overlay.opened .timeslot__overlay-bgCover {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  z-index: 1;
  transition: all 0.1s ease;
  overscroll-behavior: contain;
  overflow: hidden;
}

.timeslot__overlay-top {
  display: flex;
  flex-wrap: wrap;
  padding: 1rem 3rem 1rem 1.2rem;
}

.timeslot__overlay-top h3 {
  margin-top: 0;
  width: 100%;
}

.timeslot__overlay-top svg {
  width: 1rem;
  min-width: 1rem;
  margin-right: 0.5rem;
}

.timeslot__overlay-top svg path {
  fill: var(--black-color);
}

.ws .timeslot__overlay-top svg path {
  fill: var(--bunting-color);
}

.timeslot__overlay-top-info {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  flex-direction: column;
  width: 100%;
  padding-right: 0.5rem;
}

.timeslot__overlay-top-info > div {
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.85rem;
}

.timeslot__overlay-top-info > div span {
  font-size: 0.75rem;
}

.timeslot__overlay-top-rate {
  width: 30%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
}

.timeslot__overlay-top-rate div {
  display: flex;
  margin-top: 0.5rem;
}

.timeslot__overlay-top-rate svg {
  width: 25%;
  height: 1.5rem;
}

.timeslot__overlay-top-rate svg path {
  fill: var(--primary-color);
}

.timeslot__overlay-close {
  position: fixed;
  right: 1rem;
  top: 1rem;
  width: 30px;
  height: 30px;
  transform: rotate(0deg);
  transition: all 0.5s ease;
  transform-origin: center center;
  text-align: center;
  overscroll-behavior: contain;
  overflow: hidden;
  -webkit-transform: translateZ(0);
  z-index: 2;
}

.timeslot__overlay-close svg {
  max-width: 100%;
  height: 100%;
}

.timeslot__overlay-close svg path {
  fill: var(--primary-color);
}

.timeslot__overlay-close:hover {
  cursor: pointer;
  -ms-transform: rotate(180deg);
  /* IE 9 */
  -webkit-transform: rotate(180deg);
  /* Safari and Chrome */
  -o-transform: rotate(180deg);
  /* Opera */
  -moz-transform: rotate(180deg);
  /* Firefox */
  transform: rotate(180deg);
}

.timeslot__overlay-topics {
  display: flex;
  flex-wrap: wrap;
}

.timeslot__overlay-topics > div:not(.timeslot__content-title) {
  padding: 0.5rem 1rem;
  background: var(--black-color);
  color: var(--white-color);
  margin: 0 0.5rem 0.5rem 0;
}

.ws .timeslot__overlay-topics > div:not(.timeslot__content-title) {
  background: var(--bunting-color);
}

.timeslot__participants .timeslot__content-title {
  padding: 1rem 1.2rem 0;
}

.timeslot__participants .rc-collapse-content {
  max-width: 100%;
  border-bottom: 1px solid var(--grey-color);
}
.timeslot__participants .rc-collapse > .rc-collapse-item > .rc-collapse-header {
  padding: 0;
}
.timeslot__participants .rc-collapse {
  border-top: none;
  border-bottom: none;
}

.timeslot__participants .accordions {
  padding: 0;
}

.timeslot__participant {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 1rem 1.2rem;
  border-bottom: 1px solid var(--grey-color);
}

.timeslot__participant-image {
  border-radius: 50%;
  width: 70px;
  min-width: 70px;
  height: 70px;
  overflow: hidden;
  margin-right: 1rem;
  background-size: 45px;
  background-repeat: no-repeat;
  background-position: center center;
}

.timeslot__participant-image div {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.timeslot__participant-name {
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.ws .timeslot__participant-name {
  color: var(--bunting-color);
}

.timeslot__participant-position {
  font-weight: 400;
}

.timeslot__overlay-bookTicket {
  width: 100%;
  max-width: 600px;
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 1;
  display: flex;
  margin: auto 0 0;
  height: 60px;
  align-items: center;
  justify-content: center;
  background: var(--primary-color);
  color: var(--white-color);
  text-decoration: none;
  font-weight: 600;
  overscroll-behavior: contain;
  overflow: hidden;
  text-transform: uppercase;
  -webkit-transform: translateZ(0);
}

@media only screen and (min-width: 768px) {
  .timeslot__overlay-bookTicket {
    border-left: 1px solid var(--black-color);
  }

  .ws .timeslot__overlay-bookTicket {
    border-left-color: var(--bunting-color);
  }
}

.rc .timeslot__overlay-bookTicket {
  color: #fff;
}

/* Agenda overlay */
@media only screen and (min-width: 768px) {
  .agenda .timeslot__overlay.opened {
    width: 35%;
    min-width: 300px;
    height: 100%;

    position: absolute;
    bottom: 0;
    top: initial;
  }

  .agenda .timeslot__overlay.opened.ticker {
    height: calc(100% - 100px);
    bottom: 100px;
  }

  .agenda.fixed .timeslot__overlay.opened {
    height: calc(100% - 146px);
    position: fixed;
  }

  .agenda.fixed .timeslot__overlay.opened.ticker {
    height: calc(100% - 246px);
  }

  .agenda .timeslot__overlay-content {
    width: 100%;
    overscroll-behavior: initial;
  }

  .agenda .timeslot__overlay-close {
    position: absolute;
  }

  // .agenda .timeslot__overlay-bookTicket {
  //     display: none;
  // }
}

/* agenda timeslot */
.agenda .timeslot__time-diff {
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
  font-weight: 600;
  font-size: 0.75rem;
  color: var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.agenda .timeslot__time-diff svg {
  width: 20px;
  margin-right: 5px;
}

.agenda .schedule__timeslots .timeslot.active .timeslot__content {
  background: var(--black-color);
  color: var(--white-color);
}

.ws .agenda .schedule__timeslots .timeslot.active .timeslot__content {
  background: var(--bunting-color);
}

.agenda .schedule__timeslots .timeslot.active h3,
.agenda .schedule__timeslots .timeslot.active .timeslot__time,
.agenda .schedule__timeslots .timeslot.active .timeslot__time-diff,
.agenda .schedule__timeslots .timeslot.break .timeslot__time-diff {
  color: var(--white-color);
}

.agenda .schedule__timeslots .timeslot.agendaNoTimeline .timeslot__content {
  padding-bottom: 3rem;
}

/* Finished */
.currentDate .schedule__timeslots .timeslot.finished .timeslot__content,
.pastDate .schedule__timeslots .timeslot .timeslot__content.agenda,
.agenda .currentDate .schedule__timeslots .timeslot.finished .timeslot__content,
.agenda .pastDate .schedule__timeslots .timeslot .timeslot__content {
  border-color: var(--alto-color);
  background: var(--white-color);
}

.currentDate .schedule__timeslots .timeslot.finished h3,
.currentDate .schedule__timeslots .timeslot.finished .timeslot__time,
.currentDate .schedule__timeslots .timeslot.finished .timeslot__time-diff,
.currentDate .schedule__timeslots .timeslot.finished,
.currentDate .schedule__timeslots .timeslot.finished .text,
.pastDate .schedule__timeslots .timeslot h3,
.pastDate .schedule__timeslots .timeslot .timeslot__time,
.pastDate .schedule__timeslots .timeslot.finished .timeslot__time-diff,
.pastDate .schedule__timeslots .timeslot,
.pastDate .schedule__timeslots .timeslot.finished .text,
.agenda .currentDate .schedule__timeslots .timeslot.finished h3,
.agenda .currentDate .schedule__timeslots .timeslot.finished .timeslot__time,
.agenda .currentDate .schedule__timeslots .timeslot.finished .timeslot__time-diff,
.agenda .currentDate .schedule__timeslots .timeslot.finished,
.agenda .currentDate .schedule__timeslots .timeslot.finished .text,
.agenda .pastDate .schedule__timeslots .timeslot h3,
.agenda .pastDate .schedule__timeslots .timeslot .timeslot__time,
.agenda .pastDate .schedule__timeslots .timeslot.finished .timeslot__time-diff,
.agenda .pastDate .schedule__timeslots .timeslot .timeslot__time-diff,
.agenda .pastDate .schedule__timeslots .timeslot,
.agenda .pastDate .schedule__timeslots .timeslot.finished .text {
  color: var(--bombay-color);
}

.agenda .pastDate .timeslot .timeslot__time-diff svg path,
.agenda .currentDate .timeslot.finished .timeslot__time-diff svg path {
  fill: var(--bombay-color);
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes rightToLeft {
  from {
    right: -100%;
  }

  to {
    right: 0;
  }
}

/* Used to change width of talk */

@for $i from 0 through 9 {
  .schedule__timeslots .timeslot.w#{$i} {
    width: 100px;
  }
}

@for $i from 10 through 600 {
  .schedule__timeslots .timeslot.w#{$i} {
    width: #{$i}0px;
  }
}
