.posts {
    padding: 0;
    position: relative;
    z-index: 1;
}
.posts > .container {
    padding: 2rem 0;
}

.posts__topics {
    padding: 0 1rem 1rem;
}
.posts__topics-filter {
    margin-bottom: 1rem;
}
.posts__topics-filter span {
    text-decoration: underline;
}
.posts__topics-filter > span:hover {
    cursor: pointer;
}
.posts__topics-filter > span svg {
    width: 15px;
}
.posts__topics-wrap {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
}
.posts__topics h3 {
    width: 100%;
    margin-top: 0;
    margin-bottom: .5rem;
}
.posts__topics-single {
    padding: .5rem .7rem;
    border: 1px solid var(--black-color);
    color: var(--black-color);
    margin: 0 .5rem .5rem 0;
    transition: all .4s ease;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -o-user-select: none;
}
.posts__topics-single:hover {
    cursor: pointer;
}
.posts__topics-single.active,
.posts__topics-single:hover {
    background: var(--black-color);
    color: var(--white-color);
}
.ws .posts__topics-single {
    border-color: var(--bunting-color);
    color: var(--bunting-color);
}
.ws .posts__topics-single.active,
.ws .posts__topics-single:hover {
    background: var(--bunting-color);
    color: var(--white-color);
}
.posts__single {
    padding: 2rem 1rem 4rem;
    transition: all .4s ease;
    position: relative;
    width: 100%;
    overflow: hidden;
}
.posts__single.list {
    padding: 2rem 1rem;
}
.posts__single.list:hover,
.posts__single.active {
    background: var(--concrete-color);
}
.posts__single a {
    color: var(--bunting-color);
    text-decoration: none;
}
.posts__single h3 {
    margin-top: 0;
    margin-bottom: 1rem;
    line-height:1.4;
}
.posts__single h3 svg {
    width: 20px;
    margin-left: .5rem;
}
.posts__single-date {
    color: var(--primary-color);
    font-size: .85rem;
    font-weight: 600;
    margin-bottom: .5rem;
}
.posts__single-interests {
    font-style: italic;
    font-size: .85rem;
    margin-top: 1rem;
}
.posts__single p {
    margin-bottom: 0;
}

@media only screen and (min-width: 768px) {
    .posts__single {
        padding: 1rem 2rem 4rem;
    }
    .posts__single.list {
        padding: 2rem;
    }
    .posts__topics {
        padding: 0 2rem 1rem;
    }
    .posts__topics-single {
        padding: .7rem 1.5rem;
    }
    .posts__single-date {
        margin-bottom: 1rem;
    }
    .posts__single-interests {
        margin-top: 2rem;
    }
}

.copiedBox{
    background: var(--primary-color);
    color:#fff;
    position: absolute;
    right: 2rem;
    top: 2rem;
    padding: 0.5rem;
    border-radius: 5px;
    font-weight: bold;
    opacity:0;
    transition-duration: 600ms;
}
.copiedBox.active{
    opacity:1;
}

.shareBlock{
    right: 10px;
    position: absolute;
    bottom: 10px;
    background: var(--primary-color);
    border-radius: 20px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.shareBlock:hover {
    cursor: pointer;
    opacity: .9;
}
.ws .shareBlock {
    background: var(--bunting-color);
}
.shareBlock svg {
    fill: var(--white-color);
    width: 16px;
}
.shareBlock svg path {
    fill: var(--white-color);
}


/* Post breadcrumbs */
.posts__single-breadCrumbs {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 1rem 0;
    font-size: 14px;
}
.posts__single-breadCrumbs a {
    color: var(--primary-color);
    white-space: nowrap;
}
.posts__single-breadCrumbs svg {
    width: 8px;
    min-width: 8px;
    margin: 0 10px;
}
.posts__single-breadCrumbs svg path {
    fill: var(--bunting-color)
}
.posts__single-breadCrumbs-title {
    width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
/* END Post breadcrumbs */