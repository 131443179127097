.cards {
  padding: 4rem 0 3rem;
  background: var(--albaster-color);

  .cardsSlider {
    padding: 0 1rem;

    .swiper-slide {
      width: 90%;
    }
  }
}

.card {
  $e: &;
  border: 1px solid var(--iron-color-hover);
  border-radius: $border-radius;
  overflow: hidden;
  position: relative;
  height: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 1rem;
  transition: all 0.4s ease;
  background: var(--white-color);

  a {
    display: flex;
    width: 100%;
    height: 100%;
    text-decoration: none;

    &:hover {
      opacity: 1;
    }
  }

  &__image {
    width: 100px;
    height: 100px;
    background-position: center center;
    background-size: cover;
    position: relative;
    transition: all 0.4s ease;
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    filter: grayscale(100%);

    // cool transition effect on image
    &::before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: scale3d(0, 0, 1);
      transition: transform 0.4s ease;
      background: rgba(255, 255, 255, 0.03);
      content: '';
      pointer-events: none;
    }

    &::before {
      transform-origin: center;
    }
  }

  &__content {
    max-height: 100px;
    width: calc(100% - 100px);
    padding: 1rem 1.2rem;
    color: var(--tundora-color);

    #{$e}__title {
      margin: 0;
      font-size: 1rem;
      margin-bottom: 0.3rem;
      transition: all 0.4s ease;

      @media only screen and (min-width: 1024px) {
        font-size: 1.2rem;
      }
    }

    #{$e}__text {
      margin: 0;
    }
  }

  &:hover,
  &:focus {
    cursor: pointer;
    box-shadow: 0 2px 0px 0 rgba(0, 0, 0, 0.1), 0 0px 1px 0 rgba(0, 0, 0, 0.12),
      0 2px 4px 1px rgba(0, 0, 0, 0.1);

    #{$e}__image {
      -webkit-filter: grayscale(0%);
      -moz-filter: grayscale(0%);
      filter: grayscale(0%);

      // cool transition effect
      &::before {
        transform: scale3d(1, 1, 1);
      }
    }
    #{$e}__content #{$e}__title {
      color: var(--primary-color);
    }
  }
}

.rc .card a {
  color: var(--secondary-color);
}
