/* File upload */
.form__field.file {
    margin-bottom:0;
}
.form__field.file input{
    border: none;
    padding: 0 0 1rem 0;
    color: var(--white-color);
}

.form__field.file.error span{
    font-weight: 600;
    animation: blink 2s linear infinite;
    color: var(--primary-color);
}