.timeslotGroup {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid #ccc;

  &_startTime {
    text-align: center;
    border-right: 1px solid #ccc;
    font-size: 1rem;
    padding: 0.8rem;
    width: 80px;
  }

  &_timeslots {
    width: calc(100% - 100px);
  }

  @media only screen and (min-width: 768px) {
    &_startTime {
      width: 120px;
      padding: 1rem;
      font-size: 1.25rem;
    }
    &_timeslots {
      width: calc(100% - 120px);
    }
  }
}
