.agendaTimeslot {
  padding: 0.7rem 1rem;
  border-bottom: 1px solid #ccc;
  transition: all 0.2s ease-out;
  &.active {
    background: var(--white-color);
  }
  &:hover {
    cursor: pointer;
    box-shadow: -2px 3px 8px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    position: relative;
  }
  &:last-child {
    border-bottom: none;
  }
  &_title {
    font-size: 1.125rem;
    font-weight: 700;
  }
  &_description {
    font-size: 0.875rem;
    margin-top: 0.5rem;
    display: block;
  }
  &_location {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 0.5rem;
    font-size: 0.85rem;
    font-weight: 700;
    svg {
      width: 1rem;
      margin-right: 0.5rem;
      path {
        fill: var(--primary-color);
      }
    }
  }
  @media only screen and (min-width: 768px) {
    padding: 1rem 1.2rem;
    &_title {
      font-size: 1.25rem;
    }
    &_description {
      font-size: 1rem;
    }
  }
}
