.share-section {
    padding: 3rem 0 6rem;
    position: relative;
    z-index: 1;
    width: 100%;
    overflow: hidden;
}

.share-section .col-md-6:first-child h2 {
    margin: 0;
}

.share-section__links {
    display: flex;
    align-items: center;
}
.share-section__links > div > a {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    margin-right: 2rem;
    text-decoration: none;
    color: var(--white-color);
}
@media screen and (max-width: 767px) {
    .share-section {
        padding: 3rem 0;
    }
    .share-section__links {
        padding-top: 2rem;
    }
}