.events {
  padding: 2rem 0;
  position: relative;
  overflow: hidden;
}

.error .events__content {
  border: 5px solid red;
}

.events {
  .tabs {
    padding: 0;
    margin-top: 1rem;
  }
}

.events__content {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 2rem 1rem 0;
  max-width: 1200px;
  margin: 0 auto;
}

.events__single {
  width: 100%;
  border: 1px solid var(--black-color);
  background-color: var(--white-color);
  position: relative;
  margin-bottom: 2rem;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}

.ws .events__single {
  border-color: var(--bunting-color);
}

.events__single.overlap .events__single-overlap {
  padding: 2rem 1rem;
  background: var(--primary-color);
  color: var(--white-color);
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  font-size: 0.85rem;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;

  &-close {
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    border: none;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    transition: all 0.4s ease;
    &:hover {
      cursor: pointer;
      transform: rotate(180deg);
    }
    svg {
      height: 100%;
    }
  }
}

.events__single.overlap .events__single-overlap h3 {
  margin: 0 0 0.5rem;
  font-size: 1.17rem;
  color: var(--white-color);
}

.events__single.overlap .events__single-overlap h4 {
  margin: 0 0 0.5rem;
}

.events__single.overlap .events__single-overlap .events__single-check {
  display: block;
  background: var(--sun-color);
}

.coll .events__single.overlap .events__single-overlap .events__single-check {
  background: var(--black-color);
}

.events__single.overlap .events__single-overlap .events__single-check svg {
  max-width: 100%;
  max-height: 100%;
}

.events__single.overlapsWith {
  .events__single-check {
    animation-name: overlap_animation;
    animation-duration: 2s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    -webkit-animation-name: overlap_animation;
    -webkit-animation-duration: 2s;
    -webkit-animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    -moz-animation-name: overlap_animation;
    -moz-animation-duration: 2s;
    -moz-animation-timing-function: linear;
    -moz-animation-iteration-count: infinite;

    svg path {
      animation-name: overlap_animation_svg;
      animation-duration: 2s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
      -webkit-animation-name: overlap_animation_svg;
      -webkit-animation-duration: 2s;
      -webkit-animation-timing-function: linear;
      -webkit-animation-iteration-count: infinite;
      -moz-animation-name: overlap_animation_svg;
      -moz-animation-duration: 2s;
      -moz-animation-timing-function: linear;
      -moz-animation-iteration-count: infinite;
    }
  }
}

@keyframes overlap_animation {
  0% {
    background-color: var(--primary-color);
    border-color: var(--black-color);
  }

  50% {
    background-color: var(--black-color);
    border-color: var(--primary-color);
  }

  100% {
    background-color: var(--primary-color);
    border-color: var(--black-color);
  }
}

@keyframes overlap_animation_svg {
  0% {
    fill: var(--white-color);
  }

  50% {
    fill: var(--primary-color);
  }

  100% {
    fill: var(--white-color);
  }
}

.events__single-check {
  display: none;
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
  width: 40px;
  height: 40px;
  background: var(--primary-color);
  border-radius: 50%;
  padding: 7px;
  border: 1px solid var(--black-color);
}

.ws .events__single-check {
  border-color: var(--bunting-color);
}

.events__single-check svg path {
  fill: var(--white-color);
}

.events__single-wrap {
  width: 100%;
}

.events__single-image {
  height: 150px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid var(--black-color);
  background: var(--white-color);
  overflow: hidden;
}

.ws .events__single-image {
  border-bottom-color: var(--bunting-color);
}

.events__single-image img {
  max-width: 80%;
  max-height: 90px;
}

.events__single-content {
  padding: 1.2rem 1rem;
  color: var(--bunting-color);
}

.rc .events__single-content {
  color: var(--black-color);
}

.events__single-content h3 {
  margin-top: 0;
}

.events__single-content div {
  margin-bottom: 0.2rem;
}

.events__single-content .events__single-location {
  margin-bottom: 1rem;
}

.events__single-content p {
  margin-bottom: 0;
}

.events__single-readMore:hover {
  cursor: pointer;
}

.events__single-button {
  width: 100%;
  padding: 0;
  height: 50px;
  border: none;
  background: var(--primary-color);
  color: var(--white-color);
  font-size: 1rem;
  text-transform: uppercase;
  border-top: 1px solid var(--bunting-color);
  transition: all 0.2s ease;
  align-self: flex-end;
}

.events__single-button:after {
  content: 'Select';
}

.selected .events__single-button:after {
  content: 'Remove';
}

.events__single-button:hover {
  cursor: pointer;
  background: var(--primary-color-hover);
}

.events__single-button:disabled,
.events__single-button:disabled:hover {
  background: var(--bombay-color);
  cursor: not-allowed;
}

.events__single-button:disabled:after {
  content: 'No spaces available';
}

.roundtable-events .events__single-button:disabled:after {
  content: 'No spaces available';
}

.selected.events__single {
  background: var(--primary-color);
}

.coll .selected.events__single {
  background: var(--black-color);
}

.selected .events__single-check {
  display: block;
}

.selected .events__single-content {
  color: var(--white-color);
}

.selected .events__single-content h3 {
  color: var(--white-color);
}

.selected .events__single-button {
  background: var(--bunting-color);
}

.coll .selected .events__single-button {
  background: var(--primary-color);
}

.events__register {
  width: 80%;
  display: flex;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
}

.events__register__top {
  margin-top: 2rem;
}

/* Second page */
.events__secondPage {
  position: relative;
  padding: 0 1rem;
}

.events__secondPage-content {
  border: 1px solid var(--black-color);
  padding: 0 1.2rem;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
}

.ws .events__secondPage-content {
  border-color: var(--bunting-color);
}

.events__secondPage-content .loader {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7);
}

.events__secondPage-close {
  position: absolute;
  top: -12px;
  right: -12px;
  width: 25px;
  height: 25px;
  background: var(--white-color);
  border-radius: 50%;
  border: 1px solid var(--black-color);
}

.ws .events__secondPage-close {
  border-color: var(--bunting-color);
}

.events__secondPage-close:hover {
  cursor: pointer;
}

.events__secondPage-close svg {
  max-width: 100%;
  max-height: 100%;
}

.ws .events__secondPage .floatingLabel__label,
.ws .events__secondPage .form__field.floatingLabel input ~ .floatingLabel__label,
.ws
  .events__secondPage
  .form__field.floatingLabel
  input:not(:focus):empty:valid
  ~ .floatingLabel__label {
  color: var(--tundora-color);
}

/* Events Form */
.events__form {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-items: flex-start;
  max-width: 1024px;
  margin: 0 auto;
  padding: 1rem 0;
}

.events__form > button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1.2rem;
  height: 52px;
  order: 1;
}

.events__form .form__field {
  width: 100%;
  margin-bottom: 0.3rem;
}

.events__form .form__field:last-child {
  margin-bottom: 0;
}

.events__form .checkbox span {
  color: var(--tundora-color);
}

.events__form .form__field.checkbox input:focus + label:before,
.events__form .form__field.checkbox input:checked:focus + label:before {
  box-shadow: 0px 0px 6px 2px var(--tundora-color);
}

/* Inline event */
.events__single-inline {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem 0;
  border-bottom: 1px solid var(--bombay-color);
}

.events__single-inline-image {
  width: 160px;
  max-width: 20%;
  height: auto;
}

.events__single-inline-content {
  width: calc(100% - 40px);
}

.events__single-remove {
  background: var(--secondary-color);
  width: 32px;
  height: 32px;
  border-radius: 50%;
  padding: 5px;
  transition: all 0.4s ease;
}

.coll .events__single-remove {
  background: var(--primary-color);
}

.rc .events__single-remove {
  background: var(--primary-color);
}

.events__single-remove:hover {
  background: var(--secondary-color-hover);
  cursor: pointer;
}

.events__single-remove svg {
  width: 100%;
  height: 100%;
}

.events__single-remove svg path {
  fill: var(--white-color);
}

.events__thankYou {
  padding: 2rem 1rem;
  width: 800px;
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  @media only screen and (max-width: 767px) {
    h2 {
      font-size: 1.2rem;
    }

    h3 {
      font-size: 1rem;
    }
  }

  @media only screen and (min-width: 768px) {
    padding: 5rem 2rem 9rem;

    h2 {
      font-size: 2rem;
    }
  }
}

.ws .events__thankYou {
  font-family: 'alverata', serif;
}

.events__thankYou .yellow-triangle {
  border-top-width: 120px;
  border-right-width: 120px;
  border-top-color: var(--sun-color);
  border-top-style: solid;
  border-right-color: transparent;
  border-right-style: solid;
  border-left-style: none;
  border-bottom-style: none;
  position: absolute;
}

.events__thankYou .events__single-inline h3 {
  font-size: 1.2rem;
}

.events__form.active .form__field {
  opacity: 1 !important;
  display: block !important;
}

@media only screen and (max-width: 767px) {
  .events__thankYou .newCircle {
    top: 0;
    z-index: -1;
    transform: none;
  }
}

@media only screen and (min-width: 768px) {
  .events__secondPage-content {
    padding: 2rem;
  }

  .events__form > button {
    order: 2;
  }

  .events__form .form__field {
    width: 39%;
    margin-right: 1%;
    margin-bottom: 1rem;
    opacity: 1;
    display: block;
  }

  .events__form {
    padding: 2rem 0 0;

    &.roundtable {
      .form__field {
        width: 47%;
      }
    }

    &.perk {
      .form__field {
        width: 47%;
      }
    }
  }

  .events__single {
    width: 24%;
    margin-right: 1%;
  }

  .events__single.wider {
    width: 32%;
  }

  .events__single-inline h3,
  .events__single-inline-content {
    width: 30%;
  }

  .events__form > button {
    width: 20%;
  }

  .events__register {
    width: 40%;
  }

  .events__thankYou {
    padding: 7rem 0;
  }

  .events {
    .events__filter {
      padding: 0 0 0 1rem !important;
      max-width: 1200px;
      margin: 0 auto;
    }
    .schedule__filter-icon {
      padding: 5px;
    }
  }
}

.events {
  .schedule__filter-icon {
    background: transparent;
    width: auto;
    padding: 0 5px;
    svg {
      position: relative;
      width: 40px;
      height: 40px;
      border: none;
      background: 0 0;
      border-radius: 50%;
      background: var(--secondary-color);
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8px;
    }
    span {
      color: var(--bunting-color);
      position: static;
      background: transparent;
      width: auto;
      margin-right: 0.5rem;
      font-size: 1rem;
    }
  }
}

.events__error {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.95);
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 2rem;

  > div {
    max-width: 960px;
    padding: 1rem;
    margin: 0 auto;
  }

  .events__error-close {
    top: 1rem;
    right: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid;
    padding: 7px;
    background: var(--secondary-color);
    color: var(--white-color);
    svg {
      width: 100%;
    }
  }
}
