/* Old triangle styles */
    .yellow-triangle {
        width: 0;
        height: 0;
        border-bottom-color:  var(--sun-color);
        border-bottom-style: solid;
        border-left-color: transparent;
        border-left-style: solid;
        position: absolute;
    }
    .ws .yellow-swirl-l-c-triangle-b-r .yellow-triangle {
        border-bottom-width: 100px;
        border-bottom-style: solid;
        border-left-width: 100px;
        border-left-style: solid;
        right: 0;
        bottom: 0;
    }
    .ws .yellow-triangle-l-purple-dots-l-yellow-swirl-t-r .yellow-triangle {
        border-bottom-width: 550px;
        border-left-width: 450px;
        left: -434px;
        top: 0;
    }


    .purple-triangle {
        width: 0;
        height: 0;
        border-bottom-color: var(--butterfly-color);
        border-bottom-style: solid;
        border-right-color: transparent;
        border-right-style: solid;
        position: absolute;
    }
    .ws .purple-dots-l-c-purple-cricle-t-r-green-swirl-b-r .purple-triangle {
        border-bottom-width: 500px;
        border-right-width: 500px;
        right: 40%;
        bottom: 5rem;
        z-index: -1;
        transform: translateX(100%);
    }


    .blue-triangle {
        width: 0;
        height: 0;
        border-bottom-color: var(--bunting-color);
        border-bottom-style: solid;
        border-right-color: transparent;
        border-right-style: solid;
        position: absolute;
    }
    .ws .pink-swirl-l-t-dots-t-r-triangle-b-r .blue-triangle {
        border-bottom-width: 700px;
        border-right-width: 800px;
        right: 50%;
        bottom: 0;
        z-index: -1;
        transform: translateX(100%);
    }
/* END Old triangle styles */



/* New triangle styles */
.backgroundShape.triangle.primary svg path {
    fill: var(--primary-color);
}
.backgroundShape.triangle.secondary svg path {
    fill: var(--secondary-color);
}
.backgroundShape.triangle.white svg path {
    fill: var(--white-color);
}
.backgroundShape.triangle.black svg path {
    fill: var(--black-color);
}
.backgroundShape.triangle.blue svg path {
    fill: var(--blue-color);
}