.postBlock {
    $c: &;

    // hover effect styles
    $postBlockHoverBgSize: 100% 6px;
    $postBlockTransitionDelay: 0s;

    padding-bottom: 40px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    overflow: hidden;
    align-items: flex-start;
    justify-content: flex-start;

    a {
        text-decoration: none;
    }

    &__img {
        display: block;
        width: 100%;
        margin-bottom: 1rem;
        background-size: cover;
        background-position: center center;
        height: 200px;
        position: relative;
        @media only screen and (min-width: 768px) {
            height: 250px;
        }
        @media only screen and (min-width: 1240px) {
            height: 300px;
        }
        &:hover {
            opacity: .95;
            transition: all .3s ease;

            // Img hover triggers URL underline
            ~ #{$c}__content {
                #{$c}__content-title span {
                    background-size: $postBlockHoverBgSize;
                    transition-delay: $postBlockTransitionDelay;
                    color: var(--primary-color);
                }
            }

        }

        #{$c}__text {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100px;
            display: flex;
            align-items: flex-end;
            padding: 1rem;
            color: var(--white-color);
            background: rgba(0,0,0,.5);
            background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.65) 100%);
            background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%);
            background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.85) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 );
        }
    }

    &__content {
        color: var(--scorpion-color);
        width: 100%;

        &-industry,
        &-author-date {
            font-size: .875rem;
            display: block;
            width: 100%;
            a {
                color: var(--scorpion-color);
            }
        }
        &-industry {
            margin-bottom: .45rem;
        }
        &-author-date {
            margin-top: .45rem;
            svg {
                height: 12px;
                margin-left: 1rem;
            }
        }
        &-title {
            display: inline-block;
            text-decoration: none;
            font-size: 1.25rem;
            line-height: 1.3em;
            color: $shark-gray;
        }
        &-excerpt {
            margin: .45rem 0 0;
        }
    }

    @media only screen and (min-width: 768px) {
        &.full {
            padding-bottom: 3rem;
            flex-direction: row;
            #{$c}__img {
                margin-bottom: 0;
                width: calc(66.6666% - 14px);
            }
            #{$c}__content {
                width: calc(33.3333% - 26px);
                margin-left: 40px;
            }
        }
        // 4 columns height img fix
        .col-md-3 .postBlock__img {
            height: 200px;
        }
    }

    &.no-block-image {
        padding: 2rem 0;
        border-top: 1px solid $hit-gray;
    }

}
// smaller columns fix
.col-md-3 .postBlock__img {
    height: 200px;
}

.hoverBorder span { 
    display: inline;
    background-image: linear-gradient(to bottom, transparent 20%, currentColor 21%);
    
    background-position: 0 1.05em;
    background-repeat: no-repeat;
    background-size: 0% 6px;
    transition: background-size 0.2s ease-in-out 0.2s;
    &:hover,
    &:focus {
        background-size: 100% 6px;
        transition-delay: 0s;
        color: var(--primary-color);
    }
}