.essentials {
  padding: 4rem 0;
  overflow: hidden;

  @media only screen and (min-width: 768px) {
    padding: 5rem 0;
  }

  .card {
    .card__image {
      transition: all 1s ease;
      -webkit-filter: grayscale(100%);
      -moz-filter: grayscale(100%);
      filter: grayscale(100%);
    }

    &:hover {
      .card__image {
        -webkit-filter: grayscale(0%);
        -moz-filter: grayscale(0%);
        filter: grayscale(0%);
      }
    }
  }

  .inlineMenuSlider {
    width: 100%;

    .swiper-slide {
      width: auto;
    }
  }

  .hero.shapeTextImage {
    @media only screen and (max-width: 768px) {
      height: auto;

      .container {
        flex-grow: 1;

        .row {
          height: 100%;
        }

        p {
          height: 100%;
        }
      }
    }
  }
}

.inlineMenu {
  // position: fixed;
  // top: 100px;
  // z-index: 10010;
  // left: 50%;
  // transform: translateX(-50%);
  display: flex;

  nav {
    display: block;
    width: 100%;
    overflow: hidden;
    background: white;
    z-index: 10005;
    position: relative;
    background: #ffffff;
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12),
      0 2px 4px -1px rgba(0, 0, 0, 0.2);

    a {
      padding: 1rem 1.2rem;
      display: block;
      text-decoration: none;
      position: relative;
      width: auto;
      color: var(--tundora-color);

      &.active {
        transition: border-bottom 0.4s ease;
        border-bottom: 2px solid #000;
      }
      &.swiper-slide-active {
        border-bottom: none;
        transition: none;
      }

      // cool transition effect on image
      &::before {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transform: scale3d(0, 0, 1);
        transition: transform 0.4s ease;
        background: rgba(0, 0, 0, 0.02);
        content: '';
        pointer-events: none;
      }

      &::before {
        transform-origin: bottom;
      }

      &:hover {
        border-bottom: 2px solid #000;

        // cool transition effect
        &::before {
          transform: scale3d(1, 1, 1);
        }
      }
    }
  }

  .swiper-button-next {
    right: 0;
    box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.5);
  }
  .swiper-button-prev {
    left: 0;
    box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.5);
  }
  .swiper-button-next,
  .swiper-button-prev {
    top: 0;
    bottom: auto;
    transform: none;
    background: white;
    color: var(--bunting-color);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    font-size: 2rem;
    outline: none;
    &.swiper-button-disabled {
      display: none;
    }
  }

  @media only screen and (min-width: 768px) {
    nav {
      display: flex;
      width: auto;
    }
  }

  @media only screen and (max-width: 768px) {
    padding: 0;
  }
}
// WS fixes
.ws .inlineMenu {
  nav {
    a {
      &::before {
        background: rgba(0, 0, 0, 0.03);
      }
      &:hover {
        border-bottom-color: transparent;
        &::before {
          transform: scale3d(1, 1, 1);
        }
      }
      &.active {
        border-bottom-color: transparent;
        border-bottom: none;
        background: var(--secondary-color);
        color: var(--white-color);
      }
    }
  }
}
