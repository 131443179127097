#ccc-button-holder{
   display: none;
 }
 #cc-panel {
   background-color: inherit;
 }
 #ccc-end {
    background-color: inherit;
    border-top: 0.0625rem solid white;
    bottom: 0;
    left: 10%;
    margin-bottom: 0!important;
    padding: 1rem 0 1rem 1.5rem;
    position: fixed;
    width: 100%;
    z-index: 1202;
    @media (min-width: 26.625rem) {
      border-top: none;
      bottom: initial;
      box-shadow: none;
      padding: initial;
      position: initial;
      width: initial;
    }
}
#ccc-dismiss-button {
  background-color: transparent!important;
  span {
    background-color: transparent!important;
  }
}
.civic_cookie--ws_style {
  background-color: #0c1439;
  h2 {
    color: white;
    font-family: Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue',
      Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  }
  .civic_cookie__button {
    &:hover {
      background-color: #000006;
    }
  }
  .civic_cookie__button--primary {
    background-color: #cb1977;
    color: white;
    &:hover {
      background-color: #980044;
    }
  }
}
.civic_cookie--coll_style {
  background-color: #1257f2;
  h2 {
    color: white;
    font-family: korolev, Inter, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI',
      'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
      'Segoe UI Symbol';
  }
  .civic_cookie__button {
    &:hover {
      background-color: #0024bf;
    }
  }
  .civic_cookie__button--primary {
    background-color: #f53a27;
    color: white;
    &:hover {
      background-color: #c20700;
    }
  }
}
.civic_cookie--rc_style {
  background-color: #ee961b;
  .civic_cookie__button {
    &:hover {
      background-color: #0024bf;
    }
  }
  .civic_cookie__button--primary {
    background-color: #ee2842;
    color: white;
    &:hover {
      background-color: #bb000f;
    }
  }
}
.civic_cookie__overlay {
  background-color: rgba(0, 0, 0, 0.75);
  bottom: 0;
  display: block;
  height: 100%;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 12000;
}
.civic_cookie__banner {
  bottom: 0;
  color: white;
  height: 90%;
  display: flex;
  align-items: center;
  position: fixed;
  padding: 0rem 2rem;
  width: 100%;
  z-index: 12001;
  a {
    color: white;
  }
  @media (min-width: 50rem) {
    height: 50vh;
    padding: 0rem 10%;
  }
}
.civic_cookie__headline {
  font-size: 2rem;
  text-transform: uppercase;
  font-weight: 800;
  line-height: 1;
  margin-bottom: 0;
  @media (min-width: 50rem) {
    width: 28rem;
    font-size: 2.75rem;
    font-weight: 800;
    line-height: 1;
  }
}
.civic_cookie__subheadline {
  font-weight: bold;
  margin: 1rem 0;
}
.civic_cookie__grid_body {
  @media (min-width: 50rem) {
    display: grid;
    gap: 1.75rem;
    grid-template-columns: 66% 33%;
  }
}
.civic_cookie__grid_list {
  @media (min-width: 50rem) {
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 0.9375rem 0.625rem;
  }
}
.civic_cookie__bulletlist {
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 1.25rem;
}
.civic_cookie__button {
  border-radius: 0.25rem;
  border: 0.0625rem solid white;
  color: white;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: bold;
  padding: 0.75rem 1.25rem;
  text-align: center;
  text-decoration: none;
  @media (min-width: 37.5rem) {
    width: 100%;
  }
}
.civic_cookie__button--primary {
  color: red;
  background-color: white;
  margin-bottom: 1rem;
}
.civic_cookie__bold_text {
  font-size: 0.875rem;
  font-weight: bold;
  margin: 0.75rem 0;
}