/* Footer */
footer {
    background: var(--tradewind-color);
    color: var(--white-color);
    position: relative;
    padding-bottom: 1px;
    .container {
        max-width: 960px;
        display: flex;
        font-size: 0.8rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
        flex-wrap: wrap;
        &.ticker-active{
            margin-bottom: 100px;
        }
    }
}

footer ul {
    list-style: none;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding-left: 10px;
    display: block;
    font-size: 1rem;
}
footer ul li.menuItem a {
    color: var(--white-color);
    display:inline-block;
    line-height: 1.2;
}
footer ul li.menuItem {
    transition: all .4s ease;
}
footer ul li.menuItem:hover {
    padding-left: 8px;
}
footer .footerColumn ul li{
    padding:0;
    line-height: 36px;
    font-size: 1rem;
}
footer .footerTitle{
    text-decoration: underline;
}

footer .formSection {
    padding: 0 1rem;
    max-width: 640px;
    margin: 0 auto;
}
footer form.form > div,
footer .form__field {
    order: 2;
}
footer .formSection .container {
    padding: 0;
}
footer .notHero .form.two-cols > div:first-child {
    order:0;
}
footer .notHero .form.two-cols > div.submit {
    order: 1;
    margin: 0;
}
@media only screen and (min-width: 768px) {
    footer .formSection {
        padding: 0;
    }
}

.footer-bottom-row{
    padding:1rem 0;
    margin-top:20px;
    border-top:1px solid #fff;
}
.footer-bottom-row > div{
    float:left;
}
.footer-bottom-row > div:not(.copyright) {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.footer-bottom-row > div:first-child {
    justify-content: flex-start;
}
.footer-bottom-row > div:first-child ul{
    margin: 0;
}
.footer-bottom-row > div ul{
    float:left;
}
.footer-bottom-row > div ul li{
    float:left;
    padding: 0 0.5rem;
}
.footer-bottom-row > div ul li{
    border-right: 1px solid #fff;
}
.footer-bottom-row > div ul li:last-of-type{
    border-right: 0;
}
.footer-bottom-row > div:first-of-type ul{
    float:right;
}
.footer-bottom-row > div:first-of-type ul li{
    float:left;
}
.footer-bottom-row .copyright{
    text-align:center;
}
@media only screen and (min-width: 768px) {
    footer .container {
        justify-content: center;
        padding-top: 4rem;
        padding-bottom: 4rem;
    }
    footer ul{
        /* display: flex; */
        margin: 0 auto;
        padding: 0;
        font-size: 0.8rem;
    }
    footer .copyright {
        padding: 0 .5rem;
    }
    footer ul li:nth-child(2) {
        /* border-left: 1px solid var(--white-color);
        border-right: 1px solid var(--white-color); */
    }
    .footer-bottom-row {
        margin-top: 50px;
        padding:10px 0 0;
    }
}
@media only screen and (max-width: 767px) {
    footer .container {
        padding: 2rem 0 1rem;
    }
    footer .copyright{
        order:2;
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 1rem 0;
    }
    footer ul li:last-child {
        border-right: none;
    }
    footer .footerColumn{
        margin-bottom:20px;
    }
    .footer-bottom-row ul{
        padding-left: 10px;
    }
    .footer-bottom-row ul li{
        border-left: 1px solid var(--white-color);
        width:100%;
    }
    .footer-bottom-row > div ul li:first-of-type {
        border-right: 0;
    }
}

/* WS Footer */
.ws footer {
    background: var(--butterfly-color);
}
.ws footer:before {
    content: '';
    width: 130px;
    height: 130px;
    top: -66px;
    left: -65px;
    position: absolute;
    background: var(--bunting-color);
    border-radius: 50%;
}
.ws footer.orange:before {
    background: var(--orange-color);
}
.ws footer.secondary:before {
    background: var(--secondary-color);
}
@media only screen and (max-width: 767px) {
    .ws footer:before {
        z-index: -1;
    }
}

/* Coll footer */
.coll footer {
    background-color: var(--black-color)
}
.coll footer .circle {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 120px;
    overflow: hidden;
}
@media only screen and (min-width: 768px) {
    .coll footer .circle {
        width: 250px;
    }
}
.coll footer .circle svg {
    transform: translate(-50%,50%)
}
.coll footer .circle svg path {
    fill: var(--white-color);
}

/* Rise footer */
.rc footer {
    background-color: var(--terracotta-color);
}
@media only screen and (min-width: 768px) {
    .rc footer .circle {
        width: 250px;
    }
}