.header {
    position: relative;

    &.noHero {
        height: 60px;
    }

    &__logo {
        position: absolute;
        top: 2rem;
        left: 1rem;
        z-index: 1000;
        line-height: 1;
        transform: translateY(-50%);
        display: flex;
        align-items: center;
        transition: all .3s ease;
        width: auto;
        max-width: 80%;
        z-index: 100;
        text-decoration: none;

        img {
            width: 80px;
            transition: all .5s ease;
        }

        .header__logo-mobile {
            width: 36px;
        }
    }

    @media only screen and (min-width: 768px) {
        &.noHero {
            height: 80px;
        }

        &__logo {
            top: 2.5rem;
            left: 2rem;
        }

        &__logo img {
            width: 127px;
        }
    }
}

.coll .header{
    &__logo {
        .header__logo-mobile {
            width: 140px;
        }
    }
}

.header__buttons {
    position: fixed;
    top: .5rem;
    right: 3.5rem;
    z-index: 1003;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.header__buttons .btn {
    border-width: 2px;
}

.header__buttons a {
    margin-left: .5rem;
}

@media only screen and (max-width: 767px) {
    .header__button.fixed {
        max-width: 150px;
        right: 4.5rem;
        font-size: .85rem;
        height: 40px;
        top: 1.1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 1rem;
    }

    .header__buttons .btn {
        padding: .8rem;
        font-size: 0.875rem;
    }
}

@media only screen and (min-width: 768px) {
    .header__buttons {
        top: 1rem;
        right: 4.5rem;
    }
}

.header__logo>div {
    transition: all .5s ease;
    margin-left: 10px;
    color: var(--white-color);
    font-size: .85rem;
}

.header__logo .primary-color {
    color: var(--primary-color);
}

@media only screen and (min-width: 768px) {
    .header__logo>div {
        font-size: 1rem;
    }
}

.header__button:hover {
    cursor: pointer;
}

.header__logo:hover {
    opacity: 1;
}

.header__logo:hover>div,
.header__logo:hover img {
    opacity: .9;
}

.header__logo svg {
    width: 200px;
    height: 30px;
    margin-right: 10px;
}

.coll .header__logo svg {
    width: 300px;
    max-width: 100%;
    height: auto;
    max-height: 50px;
    margin-top: 0;
    margin-right: 5px;
}
.coll .header__logo img {
    width: 250px;
    max-width: 100%;
    height: auto;
}

.header__logo .lottie_logo {
    width: 250px;
    max-width: 100%;
    margin-top: 0;
    margin-right: 5px;

    > .lottie_logo--render {
        width: 100%;
        height: 100%;
        max-height: 100%;
        max-width: 100%;
    }
}

.rc .header__logo svg {
    width: auto;
    min-width: 100px;
    max-width: 130px;
}

.header__logo .hideLogo svg {
    margin-left: -210px;
    transition: margin-left 2s;
}

.header__logo .fullLogoOn svg {
    margin-left: 0;
}

.coll .header__logo .primary-color,
.rc .header__logo .primary-color {
    color: var(--black-color);
}

.coll .header__logo .primary-color svg path {
    fill: var(--black-color);
}

.ws .header__logo {
    text-decoration: none;
    align-items: flex-end;
    overflow: hidden;
}

.ws .header__logo svg {
    width: 250px;
    height: auto;
    margin-right: 0;
}

.ws .header__logo .svg-logo {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}

.ws .header__logo img {
    width: 45px;
}

.ws .header__logo>div {
    font-size: .85rem;
}

.ws .header__logo svg.primary-logo path,
.ws .header__logo svg.primary-logo polygon,
.ws .header__logo svg.primary-logo g {
    fill: var(--bunting-color);
}

.ws .header__logo .primary-color {
    color: var(--bunting-color);
}

.ws .header__logo .svg-logo.primary-color svg path,
.ws .header__logo .svg-logo.primary-color svg polygon,
.ws .header__logo .svg-logo.primary-color svg polyline {
    fill: var(--bunting-color);
}

@media only screen and (min-width: 768px) {
    .ws .header__logo img {
        width: 60px;
    }

    .ws .header__logo>div {
        font-size: 1.2rem;
    }
}

.ws .header__logo.custom {
    align-items: flex-start;
}

.ws .header__logo.custom svg {
    width: 250px;
    height: auto;
}

@media only screen and (min-width: 768px) {
    .ws .header__logo.custom .hideLogo:not(.fullLogoOn) svg {
        margin-left: -250px;
        transition: all 2s;
    }

    .header__logo.custom .fullLogoOn svg {
        margin-left: 0;
    }
}

@media only screen and (max-width: 767px) {
    .ws .header__logo.custom {
        align-items: center;
    }

    .header__logo.custom svg {
        margin-left: -195px;
        margin-right: 0;
        height: 40px;
    }
}

.tickerbar {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 100px;
    color: var(--white-color);
    background-color: var(--primary-color);
    z-index: 1000;
    text-align: center;
    padding-top: 11px;
    font-weight: bold;
    font-size: 0.9rem;
}

.coll .tickerbar {
    border-top: 1px solid var(--bunting-color);
}

.rc .tickerbar {
    border-top: 1px solid var(--white-color);
}

.tickerbar .Countdown {
    display: inline-block;
}

.tickerbar .Countdown .Countdown-col-element {
    padding: 0 5px;
}

.tickerbar .tickerText {
    display: inline-flex;
    position: relative;
    top: 10px;
    right: 10px;
}

.FlipClock {
    display: inline-flex !important;
    width: 400px !important;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
}

.FlipClock .divider {
    margin: 0 !important;
}

.tickerbar .btn-pink {
    position: relative;
    top: 14px;
    left: 20px;
    padding: 0.8rem 1.5rem;
}

.FlipClock .flipUnitContainer {
    background-color: var(--bunting-color) !important;
}

.FlipClock .flipUnitContainer .upperCard {
    border: 0px solid var(--bunting-color) !important;
    border-bottom: 0px solid var(--bunting-color) !important;
}

.FlipClock .flipUnitContainer .lowerCard {
    border: 0px solid var(--bunting-color) !important;
    border-top: 0px solid var(--bunting-color) !important;
}

.FlipClock .flipUnitContainer .flipCard.unfold {
    background-color: var(--bunting-color) !important;
    border: 0px solid var(--bunting-color) !important;
    border-top: 0px solid var(--bunting-color) !important;
}

.FlipClock .flipUnitContainer .flipCard.fold {
    background-color: var(--bunting-color) !important;
    border: 0px solid var(--bunting-color) !important;
    border-top: 0px solid var(--bunting-color) !important;
}

@media only screen and (max-width: 768px) {
    .FlipClock {
        width: auto !important;
        justify-content: inherit !important;
        margin-left: 0px;
        margin-top: 35px;
        margin-top: 55px;
    }

    .tickerbar {
        height: 120px;
    }

    .tickerbar .tickerText {
        position: absolute;
        top: 5px;
        left: 10px;
        right: auto;
    }

    .tickerbar .btn-pink {
        position: relative;
        top: 14px;
        left: 10px;
        padding: 0.8rem 1rem;
    }
}