table {
    font-size: .85rem;

    tr {

        $tableColor: #FFC7A8;

        @for $i from 1 through 5 {
            &:nth-child(#{$i}) {
                background: lighten($tableColor, ($i * 5%))
            }
        }

        td {
            padding: .5rem .7rem;
        }
    }

    @media only screen and (min-width: 768px) {
        font-size: 1rem;
    }
}