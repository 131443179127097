.metaBox {
    $e: &;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 2rem 0;

    &-img {
        border-radius: 50%;
        width: 60px;
        height: 60px;
        background-size: cover;
        background-position: center center;
    }

    &-content {
        padding: 0 1rem;
    }

    &-author {
        margin-bottom: .5rem;
    }

    &-date {
        margin-top: .5rem;
        font-size: .85rem;
    }

    &-sponsor {
        line-height: 1;
        margin-top: .75rem;
        padding-top: .75rem;
        border-top: 1px solid;

        img {
            margin-top: .5rem;
        }
    }


    @media only screen and (min-width:768px) and (max-width:1279px) {
        margin: 3rem 0;

        &.withSponsor {
            align-items: center;

            #{$e}-content {
                display: flex;
                align-items: center;
                width: calc(100% - 60px);
            }

            #{$e}-author {
                margin-right: 2rem;
                padding-right: 2rem;
                border-right: 1px solid;

                margin-bottom: 0;
                padding-bottom: 0;
                border-bottom: none;
            }

            #{$e}-sponsor {
                margin: 0;
            }

            #{$e}-sponsor {
                height: 100%;
                justify-content: center;
                margin-top: 0;
                padding-top: 0;
                border-top: none;
            }
        }
    }

    @media only screen and (min-width:1280px) {
        position: absolute;
        right: 0;
        top: 10px;
        width: 250px;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        margin: 0;

        &-img {
            border-radius: 50%;
            width: 60px;
            height: 60px;
            background-size: cover;
            background-position: center center;
        }

        &-content {
            width: calc(100% - 60px);
            padding: 0 0 0 1rem;
        }

        &-author {
            margin-bottom: 1rem;
        }

        &-sponsor {
            margin-top: 1rem;
            padding-top: 1rem;

            img {
                margin-top: .5rem;
            }
        }
    }



    // authorPage
    &.authorPage {
        margin: 0 0 4rem;
        padding-bottom: 3rem;
        border-bottom: 1px solid $hit-gray;
        align-items: stretch;
        color: var(--dove-gray);
        position: relative;
        width: 100%;

        #{$e}-img {
            width: 120px;
            height: 120px;
        }

        #{$e}-content {
            padding: 1rem;
            width: calc(100% - 120px);
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }

        #{$e}-author {
            width: 50%;

            h2 {
                font-size: 1.5rem;
                margin-bottom: .5rem;
                font-weight: 400;
                color: var(--dove-gray);
            }
        }

        #{$e}-description {
            width: 50%;
        }
    }



    // form
    .formSection {
        width: 100%;

        .container {
            padding: 0;
        }

        h2 {
            font-size: 1.2rem;
            margin-bottom: .5rem;
        }

        .submit button {
            height: auto;
        }
    }

}