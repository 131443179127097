/* Background colors */
.primary-bg {
  background-color: var(--primary-color);
}

.secondary-bg {
  background-color: var(--secondary-color);
}

.coll .secondary-bg,
.coll .secondary-bg h2 {
  color: var(--white-color);
}

.purple-bg {
  background-color: var(--purple-color);
  color: var(--white-color);
}

.yellow-bg {
  background-color: var(--sun-color);
}

.blue-bg {
  background-color: var(--blue-color);
  color: var(--white-color);
}
.ns-blue-bg {
  background-color: var(--ns-blue-color);
  color: var(--white-color);
}
.ns-scarlet-bg {
  background-color: var(--ns-scarlet-color);
  color: var(--white-color);
}
.ns-violet-bg {
  background-color: var(--ns-violet-color);
  color: var(--white-color);
}

.orange-bg {
  background-color: var(--orange-color);
  h2 {
    color: var(--white-color);
  }
}

.black-bg {
  background-color: var(--black-color);
  color: var(--white-color);
  h2{
    color: var(--white-color) !important;
  }
}

.tundora-bg {
  background-color: var(--tundora-color);
}

.scorpion-bg {
  background-color: var(--scorpion-color);
}

.grey-bg {
  background-color: var(--grey-color);
}

.white-bg {
  background-color: var(--white-color);
}

.steel-blue-bg {
  background-color: var(--steel-blue-color);
}

.tradewind-bg {
  background-color: var(--tradewind-color);
}

.bunting-bg {
  background-color: var(--bunting-color);
}

.butterfly-bg {
  background-color: var(--butterfly-color);
}

.sun-bg {
  background-color: var(--sun-color);
}

.terracotta-bg {
  background-color: var(--terracotta-color);
}

.cedar-bg {
  background-color: var(--cedar-color);
}

.marshland-bg {
  background-color: var(--marshland-color);
}

.amaranth-bg {
  background-color: var(--amaranth-color);
}

.eminence-bg {
  background-color: var(--eminence-color);
}

.can-can-bg {
  background-color: var(--can-can-color);
}

.trendy-pink-bg {
  background-color: var(--trendy-pink-color);
}

// Venture colors
.venture-blue-bg {
  background: #3b50a2;
  background: -moz-linear-gradient(45deg, #3b50a2 0%, #09002a 100%);
  background: -webkit-linear-gradient(45deg, #3b50a2 0%, #09002a 100%);
  background: linear-gradient(45deg, #3b50a2 0%, #09002a 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#3b50a2', endColorstr='#09002a', GradientType=1);
  color: var(--white-color);
}

.venture-green-bg {
  background: #069992;
  background: -moz-linear-gradient(45deg, #069992 0%, #09002a 100%);
  background: -webkit-linear-gradient(45deg, #069992 0%, #09002a 100%);
  background: linear-gradient(45deg, #069992 0%, #09002a 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#069992', endColorstr='#09002a', GradientType=1);
  color: var(--white-color);
}

.venture-purple-bg {
  background: #69005d;
  background: -moz-linear-gradient(45deg, #69005d 0%, #08002a 100%);
  background: -webkit-linear-gradient(45deg, #69005d 0%, #08002a 100%);
  background: linear-gradient(45deg, #69005d 0%, #08002a 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#69005d', endColorstr='#08002a', GradientType=1);
  color: var(--white-color);
}

.venture-green-blue-bg {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#068b87+0,130f28+100 */
  background: rgb(6, 139, 135);
  /* Old browsers */
  background: -moz-linear-gradient(top, rgba(6, 139, 135, 1) 0%, rgba(19, 15, 40, 1) 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(6, 139, 135, 1) 0%, rgba(19, 15, 40, 1) 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(6, 139, 135, 1) 0%, rgba(19, 15, 40, 1) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#068b87', endColorstr='#130f28', GradientType=0);
  /* IE6-9 */
}

/* END Background colors */
