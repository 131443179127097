.modal {
  $e: &;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10015;
  transition: all 0.4s ease;
  padding: 1rem 0;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  &__close-btn {
    background: var(--white-color);
    line-height: 1;
    border-radius: 50%;
    width: 55px;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1001;
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 2.2rem;
    color: var(--bunting-color);
    transform-origin: center center;
    transition: all 0.4s ease;
    border: none;
    padding: 15px;
    svg {
      height:55px;
    }

    &:hover {
      cursor: pointer;
      transform: rotate(180deg);
    }
  }

  &__wrapper {
    position: relative;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    padding: 40px;
    min-height: 480px;
    max-width: 1240px;
    z-index: 2;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    overflow: hidden;

    img {
      max-width: 40%;
    }
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--bunting-color);
    opacity: 0.85;
    z-index: 1;
    border: none;
    margin: 0;
    padding: 0;

    &:hover {
      cursor: pointer;
    }
  }

  &__content {
    background: var(--white-color);
  }

  &.opened {
    opacity: 1;
    transition: all 0.2s ease;
  }

  &.closed {
    opacity: 0;
    transition: all 0.2s ease;
  }

  &.speaker-modal {
    .modal__wrapper {
      background: var(--white-color);
    }

    .modal__content {
      width: 70%;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      padding: 40px 60px;
      background: rgba(255, 255, 255, 0.7);
      opacity: 1;

      & > div {
        overflow-x: auto;
        height: 100%;
      }

      h3 {
        font-size: 2rem;
        opacity: 1;
        color: var(--primary-color);
      }

      p {
        color: #474747;
        line-height: 1.33;
      }
    }

    @media only screen and (max-width: 576px) {
      padding: 0;
      .modal__wrapper {
        width: 100%;
        height: 100%;
        border: 0;
        padding: 0;
        margin: 0;
        text-align: center;
        overflow: auto;
      }

      .modal__wrapper img {
        width: 100%;
        max-width: 100%;
        height: auto;
      }

      .modal__content {
        position: relative;
        width: 100%;
        height: auto;
        padding: 10px 20px;
        text-align: left;
        > div {
          overflow: hidden;
        }
      }

      .modal__content h3 {
        font-size: 1.5rem;
        margin-top: 0;
        margin-bottom: 10px;
      }

      .modal__content p {
        font-size: 0.9rem;
      }

      .modal__content .scroll {
        overflow: scroll;
        height: 36vh;
        display: block;
      }

      #{$e}__close-btn {
        position: fixed;
      }
    }
  }

  &.algolia-modal {
    overflow: auto;

    .modal__close-btn {
      top: 30px;
      height: 65px;
      background: transparent;
      color: var(--white-color);
    }

    .modal__wrapper {
      padding: 0;
      max-width: 960px;
    }

    .modal-top-bar {
      width: 100%;
      background-color: var(--growth-color);
      color: #fff;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 40px;
    }

    .modal-top-bar.ALPHA {
      background-color: var(--alpha-color);
    }

    .modal-top-bar.BETA {
      background-color: var(--beta-color);
    }

    .modal-top-bar.Exhibitor {
      background-color: var(--bunting-color);
    }

    .modal-content-area.Exhibitor .modal-pitch {
      font-size: 1.2rem;
      line-height: 1.2;
    }

    .modal-top-bar .modal-track {
      font-family: 'Inter', sans-serif;
      font-weight: 900;
      font-size: 2rem;
      margin: 0;
      color: #fff;
    }

    .modal-top-bar .close-modal {
      cursor: pointer;
      font-size: 3rem;
      line-height: 0;
      width: 30px;
      color: #fff;

      svg {
        max-width: 100%;
      }
    }

    .modal-top-bar .close-modal:hover {
      cursor: pointer;
    }

    .modal-content-area {
      background: var(--white-color);
      height: 100%;
      overflow: hidden;
    }

    .modal-content-area .modal-content-left {
      padding: 40px 10px 40px 40px;
      width: 55%;
      float: left;
      color: var(--bunting-color);
    }

    .modal-content-area .modal-content-left h3 {
      font-size: 2.5rem;
      margin: 10px 0;
    }

    .modal-content-area .modal-content-left .modal-content-img {
      text-align: center;
    }

    .modal-content-area .modal-content-left img {
      max-width: 220px;
      max-height: 25vh;
    }

    .modal-content-area .modal-content-left .modal-country {
      font-size: 1.2rem;
    }

    .modal-content-area .modal-content-left .modal-social-links {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    .modal-content-area .modal-content-left .modal-social-links .m-social {
      width: 50px;
      height: 50px;
      border-radius: 35px;
      color: #fff;
      font-family: Font awesome\5 Pro;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 2rem;
      margin: 15px 7.5px 0 7.5px;
      cursor: pointer;
    }

    .modal-content-area .modal-content-left .modal-social-links .m-social a {
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 50%;
      }
    }

    .modal-content-area .modal-content-left .modal-social-links .m-social:hover {
      cursor: pointer;
    }

    .modal-content-area .modal-content-left .modal-social-links .m-linkedin {
      background-color: #0077b5;
      text-decoration: none;
    }

    .modal-content-area .modal-content-left .modal-social-links .m-twitter {
      background-color: #1da1f2;
      text-decoration: none;
    }

    .modal-content-area .modal-content-left .modal-social-links .m-instagram {
      background-color: #c40093;
      text-decoration: none;
    }

    .modal-content-area .modal-content-left .modal-social-links .m-crunchbase {
      background-color: #146aff;
      a {
        svg {
          width: 70%;
        }
      }
    }

    .modal-content-area .modal-content-left .modal-social-links .m-angellist {
      background-color: #000;
      a {
        svg {
          width: 50%;
        }
      }
    }

    .modal-content-area .modal-content-left .modal-social-links .m-facebook {
      background-color: #4267b2;
      a {
        svg {
          width: 30%;
        }
      }
    }

    .modal-content-area .modal-content-left .modal-social-links .m-website {
      background-color: var(--primary-color);
      text-decoration: none;
    }

    .modal-content-area .modal-content-right {
      width: 45%;
      float: left;
      padding: 40px 40px 40px 10px;
      color: #474747;
    }

    @media only screen and (max-width: 767px) {
      align-items: flex-start;
      padding: 0;

      .modal__close-btn {
        position: fixed;
        top: 7px;
      }

      .modal__wrapper {
        height: 100%;
        padding: 80px 0 100px;
        border: none;
        margin: 0;
        width: 100%;
      }
      .modal-content-area {
        overflow-y: auto;
      }

      .modal-content-area .modal-content-left {
        padding: 20px 20px 0 20px;
        width: 100%;
      }

      .modal-content-area .modal-content-left img {
        max-height: 140px;
      }

      .modal-content-area .modal-content-left h3 {
        font-size: 1.3rem;
      }

      .modal-content-area .modal-content-left .modal-country {
        font-size: 1rem;
      }

      .modal-content-area .modal-content-right {
        padding: 20px;
        width: 100%;
      }

      .modal-top-bar {
        position: fixed;
        top: 0;
        left: 0;
      }

      .modal-content-area .modal-content-left .modal-social-links {
        position: fixed;
        bottom: 1px;
        left: 1px;
        width: calc(100% - 2px);
        height: 100px;
        display: flex;
        justify-content: space-around;
        background: #fff;
      }

      .modal-content-area .modal-content-left .modal-social-links .m-social {
            margin: 15px 2px 0 2px;
      }
    }

    @media only screen and (min-width: 768px) {
      .algolia-container li {
        margin-bottom: 1rem;
      }

      .modal-top-bar {
        height: 125px;
      }

      .modal-content-area .modal-content-left {
        .modal-social-links .m-social:first-of-type {
          margin-left: 0;
        }

        .modal-country {
          margin-bottom: 1rem;
        }
      }
    }
  }

  // Consent Modal
  @at-root .consent-modal {
    #{$e}__wrapper {
      padding: 0;
      max-width: 640px;
      display: flex;
      flex-direction: column;
    }
    #{$e}__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: var(--primary-color);
      padding: 2.5rem 2rem;
      .title {
        margin: 0;
        color: var(--white-color);
        font-size: 2rem;
      }
    }
    #{$e}__close-btn {
      position: initial;
      background: transparent;
      color: var(--white-color);
    }
    #{$e}__content {
      flex: 1;
      padding: 2.5rem 2rem;
      &-title {
        font-size: 2rem;
        margin: 0 0 2rem;
      }
      &-buttons {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 2rem;
        .btn {
          width: calc(50% - 1rem);
          border-radius: 4px;
          font-weight: 600;
          height: 62px;
        }
      }
      .checkbox {
        margin-bottom: 2rem;
        .checkboxField {
          margin-bottom: 0;
        }
        .checkmark {
          width: 22px;
          height: 22px;
        }
        label {
          font-size: 0.875rem;
        }
      }
      .footnotes {
        padding: 2rem 0 0;
        border-top: 2px solid var(--silver-color);
        color: var(--dusty-gray);
        font-size: 0.875rem;
        a {
          color: var(--dusty-gray);
        }
      }
    }
    div#{$e}__overlay {
      opacity: 1;
      &:hover {
        cursor: default;
      }
    }
  }
}

.ws .algolia-modal .modal-top-bar .modal-track {
  font-family: 'alverata', serif;
}

.coll .modal__content h3 {
  color: var(--black-color);
}
