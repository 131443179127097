.react-phone-number-input__row,
.PhoneInput {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: var(--white-color);
}
.react-phone-number-input__country.react-phone-number-input__country--native,
.PhoneInputCountry {
  position: relative;
}
.PhoneInputCountryIcon {
  height: 52px;
  width: 62px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}
.PhoneInputCountryIcon.react-phone-number-input__icon--international svg path {
  fill: var(--tundora-color);
}
.PhoneInputCountryIcon svg,
.PhoneInputCountryIcon img {
  width: 30px;
}
.PhoneInputCountryIcon:after {
  content: '';
  width: 0px;
  height: 0px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #2f2f2f;
  margin-left: 8px;
}
.PhoneInputCountryIcon:before {
  content: '';
  width: 1px;
  height: 100%;
  background: var(--grey-color);
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
select.react-phone-number-input__country-select,
.PhoneInputCountrySelect {
  position: absolute;
  left: 0;
  top: 0;
  width: 52px;
  height: 52px;
  background: transparent;
  border: none !important;
}

.PhoneInput .PhoneInputCountryIconImg {
  color: #2f2f2f;
}

.react-phone-number-input--focus .react-phone-number-input__country-select,
.react-phone-number-input__row input:focus {
  outline: none;
}
/* .react-phone-number-input--focus */
.react-phone-number-input__row input,
.PhoneInputInput {
  border: none !important;
  background: transparent;
}
.react-phone-number-input__row,
.PhoneInput {
  border: 1px solid var(--grey-color);
}
.react-phone-number-input--focus .react-phone-number-input__row,
.PhoneInput {
  border-color: var(--primary-color);
}

/* error */
.form__field.phone.error .react-phone-number-input {
  position: relative;
}
.form__field.phone.error .react-phone-number-input:after {
  font-family: 'Font Awesome 5 Pro';
  content: '\f06a';
  font-size: 24px;
  font-weight: 100;
  color: var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
}
.form__field.phone.error .react-phone-number-input__row {
  box-shadow: inset 0px 0px 0px 2px var(--primary-color);
  border-color: var(--primary-color);
}
.form__field.phone.error .react-phone-number-input__icon:before {
  height: 48px;
}
