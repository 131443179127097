/* START Colors -  Setting up each conf colors */
:root {
  --black-color: #000;
  --tundora-color: #3a3a3a;
  --scorpion-color: #5d5d5d;
  --pink-color: #e90d7f;
  --silver-color: #cccccc;
  --grey-color: #dcdfe1;
  --dove-gray: #717171;
  --dusty-gray: #999;
  --white-color: #fff;
  --white-color-hover: var(--grey-color);
  --steel-blue: #4577b7;
  --tradewind-color: #6eb8a4;
  --tradewind-color-hover: #5b9a89;
  --iron-color: #e7e7e8;
  --iron-color-hover: #eee;
  --concrete-color: #f3f3f3;
  --concrete-color-hover: #f6f6f6;
  --porcelain-color: #f3f5f6;
  --albaster-color: #f9f9f9;
  --albaster-color-hover: #f9f9f9;
  --alto-color: #d4d4d4;
  --alto-color-hover: #e0e0e0;
  --bombay-color: #afb1b4;

  --venture-purple: #932f90;
  --venture-purple-light: #a5689c;
  --venture-teal: #0b9a93;
  --venture-blue: #3c51a2;

  --ns-scarlet-color: #ff3700;
  --ns-blue-color: #0084ff;
  --ns-violet-color: #c300dd;
  --ns-orange-color: #ff9b1c;
  --ns-orange-color-hover: #ffac42;
  --ns-red-color: #ff3700;
}

$hit-gray: #a9b2bb;
$shark-gray: #292c30;
$alto: #d8d8d8;

$venture-purple: #09002a;

// Rise colors
.rc {
  --flamingo-color: #ef4343;
  --amaranth-color: #ee3d4c;
  --cardinal-color: #c42127;
  --terracotta-color: #a11d21;
  --cedar-color: #45151b;
  --marshland-color: #020302;

  --primary-color: var(--flamingo-color);
  --primary-color-hover: #f55e6a;
  --primary-color-link-hover: #ff5e6c;
  --secondary-color: var(--marshland-color);
  --secondary-color-hover: var(--cedar-color);

  --bunting-color: var(--black-color);
  --growth-color: #3c2c7a;
  --beta-color: #55bc79;
  --alpha-color: #ee9a22;
  --growth-gradient-color: radial-gradient(circle at left bottom, #f499b6 0%, #3c2c7a 50%);
  --beta-gradient-color: radial-gradient(circle at left bottom, #00434c 0%, #55bc79 50%);
  --alpha-gradient-color: radial-gradient(circle at left, #ea283a 0%, #ee9a22 100%);

  --eminence-color: #673a87;
  --can-can-color: #d58fac;
  --can-can-color-hover: #ce9ab0;
  --trendy-pink-color: #975a98;

  color: var(--black-color);
}

// END Rise colors

// Web Summit colors
.ws {
  --primary-color: #00aca8;
  --primary-color-hover: #5ac4c5;
  --secondary-color: #e90d7f;
  --secondary-color-hover: #f05894;
  --bunting-color: #0d153a;
  --blue-color: var(--bunting-color);
  --bunting-color-15: rgba(27, 30, 86, 0.15);
  --bunting-color-95: rgba(27, 30, 86, 0.95);
  --butterfly-color: #674ea0;
  --purple-color: var(--butterfly-color);
  --sun-color: #ff9923;
  --sun-color-hover: #fcb316;
  --orange-color: #f26f3c;
  --orange-color-hover: #f3865c;
  --growth-color: #654da0;
  --beta-color: #00b8bb;
  --alpha-color: #cb1977;
}

// END Web Summit colors

// Collision colors
.coll {
  // --primary-color: #f26f21;
  // --primary-color-hover: #ff904e;
  --primary-color: #f53a27;
  --primary-color-hover: #b72b1d;
  --secondary-color: #1257f2;
  --secondary-color-hover: #0d41b5;
  --bunting-color: #333;
  --orange-color: #f26f21;
  --orange-color-hover: #ff904e;
  --blue-color: #213f99;
  --purple-color: #474787;
  --butterfly-color: var(--purple-color);
  --growth-color: #474887;
  --beta-color: #21409a;
  --alpha-color: #ed1847;
  --green-color: #008445;

  color: var(--black-color);
}

// END Collision colors
/* END Colors */

// Vars
$border-radius: 4px;

*:focus {
  outline-color: var(--primary-color);
}

body,
html {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  /* Font varient */
  font-variant-ligatures: none;
  -webkit-font-variant-ligatures: none;
  /* Smoothing */
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
  color: var(--tundora-color);
}

html {
  overflow-x: hidden;
}

#root,
.app {
  height: 100%;
  width: 100%;
}

.overflow-hidden {
  overflow: hidden;
}

.disableScroll {
  overflow: auto;
}

* {
  box-sizing: border-box;
}

a {
  color: var(--secondary-color);
  transition: all 0.2s ease;
}

a:hover {
  opacity: 0.9;
}

.rc a {
  color: var(--primary-color);
}

h1 {
  font-size: 1.75rem;
  margin: 0 0 1rem;
}

@media only screen and (min-width: 768px) {
  h1 {
    font-size: 2.5rem;
    margin: 0 0 1.5rem;
  }
}

h2 {
  color: var(--primary-color);
  font-size: 1.5rem;
  line-height: initial;
  margin: 0 0 0.5rem;
}

@media only screen and (min-width: 768px) {
  h2 {
    font-size: 2.375rem;
    margin: 0 0 1.5rem;
  }
}

h3 {
  font-size: 1.375rem;
}

.ws h1,
.ws h2,
.ws h3 {
  color: var(--bunting-color);
}

.ws h2 {
  font-family: 'alverata', serif;
}

/* Collision styles */
.coll h2 {
  color: var(--secondary-color);
}

// Rise
.rc h2 {
  color: var(--black-color);
}

p {
  margin: 0 0 1rem;
  line-height: 1.4;
}

img {
  max-width: 100%;
}

/* Color classes */
.color-steel {
  color: var(--steel-blue);
}

/* Text alignments */
.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

/* Small screen alignment */
@media only screen and (max-width: 767px) {
  .text-small-left {
    text-align: left;
  }

  .text-small-center {
    text-align: center;
  }

  .text-small-right {
    text-align: right;
  }
}

.add-padding-top {
  padding-top: 8rem;
}

.no-padding-top {
  padding-top: 0 !important;
}

.no-padding-bottom {
  padding-bottom: 0 !important;
}

.no-margin-top {
  margin-top: 0 !important;
}

.no-margin-bottom {
  margin-bottom: 0 !important;
}

.narrow-section .container,
.narrow-section.container {
  max-width: 960px;
}

.container {
  position: relative;
  z-index: 1;
}

@media only screen and (max-width: 767px) {
  .container {
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .col-md {
    margin-bottom: 1rem;
  }

  .col-md:last-child {
    margin-bottom: 0;
  }
}

.loading:before {
  content: '\f110';
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  font-size: 1.5rem;
  transform: translate(-50%, -50%);
  font-family: 'Font Awesome 5 Pro';
  -webkit-animation: spin 1s ease infinite;
  -moz-animation: spin 1s ease infinite;
  animation: spin 1s ease infinite;
}

.loading input {
  opacity: 0.7;
}

.loading input:hover {
  cursor: initial;
  opacity: 0.7;
}

/* Gradients */
.rc .background-gradient__bl--light-blue-blue {
  background-image: radial-gradient(circle at bottom left, #4577b7 0%, #1a3865 70%);
}

.rc .background-gradient__br--green-green {
  background-image: radial-gradient(circle at bottom right, #6eb8a4 0%, #528b77 90%);
}

.rc .background-gradient__bl--green-green {
  background-image: radial-gradient(circle at bottom left, #6eb8a4 0%, #528b77 90%);
}

.rc .background-gradient__br--red-chocolate-amaranth {
  background-image: radial-gradient(circle at bottom right, #ee3d4c -10%, #3d0101 70%);
}

.rc .background-gradient__bl--red-chocolate-amaranth {
  background-image: radial-gradient(circle at bottom left, #ee3d4c -10%, #3d0101 70%);
}

.rc .background-gradient__br--red-chocolate-tamarillo {
  background-image: radial-gradient(circle at bottom right, #890d17 -10%, #3d0101 70%);
}

.rc .background-gradient__br--red-tamarillo-amaranth {
  background-image: radial-gradient(circle at bottom right, #890d17 0%, #ee3d4c 80%);
}

.rc .background-gradient__br--red-tamarillo-mauvelous {
  background-image: radial-gradient(circle at bottom right, #ef8790 0%, #890d17 70%);
}

.ws .background-gradient__bl--red-chocolate-amaranth {
  background-image: radial-gradient(circle at bottom left, #ee3d4c -10%, #3d0101 70%);
}

/* END Gradients */

/* Keyframes */
@keyframes spin {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes LeftToRight {
  0%,
  100% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(-5px);
  }
}

@keyframes LeftToRightBig {
  0%,
  100% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(-50px);
  }
}

@keyframes LeftToRightScale {
  0%,
  100% {
    transform: translateX(0);
  }

  50% {
    transform: translate(-2px, -5px) scale(1.05);
  }
}

@keyframes TopToBottom {
  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-5px);
  }
}

@keyframes TopToBottomFlickr {
  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-2px);
  }
}

@keyframes MagnifierTiltLeft {
  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-10px) translateX(10px) rotate(5.5deg);
  }
}

@keyframes MagnifierTiltRight {
  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-10px) translateX(10px) rotate(5.5deg);
  }
}

@keyframes ScaleUpAntenaDot {
  0%,
  100% {
    transform: scale(1);
  }

  50% {
    transform: translateY(-10%) translateX(-1%) scale(1.5);
  }
}

@keyframes ScaleWidth {
  0%,
  100% {
    transform: scaleX(1);
  }

  50% {
    transform: scaleX(0.5);
  }
}

@keyframes tilt {
  0%,
  100% {
    transform: translateY(2px);
  }

  50% {
    transform: translateY(0);
  }
}

@keyframes opacity1 {
  0% {
    opacity: 1;
  }

  25% {
    opacity: 0.6;
  }

  50% {
    opacity: 0.4;
  }

  75% {
    opacity: 0.2;
  }

  100% {
    opacity: 1;
  }
}

@keyframes opacity2 {
  0% {
    opacity: 1;
  }

  25% {
    opacity: 1;
  }

  50% {
    opacity: 0.6;
  }

  75% {
    opacity: 0.4;
  }

  100% {
    opacity: 0.2;
  }
}

@keyframes opacity3 {
  0% {
    opacity: 0.2;
  }

  25% {
    opacity: 1;
  }

  50% {
    opacity: 1;
  }

  75% {
    opacity: 0.6;
  }

  100% {
    opacity: 0.4;
  }
}

/* TO be moved into SVG component once we build one */
/* Animate SVGs */
.svg-animated {
  overflow: overlay;
}

.svg-animated .lefttorightscale {
  animation-name: LeftToRightScale;
  animation-duration: 2s;
  animation-fill-mode: both;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.svg-animated .lefttoright {
  animation-name: LeftToRight;
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.svg-animated .lefttorightslow {
  animation-name: LeftToRight;
  animation-duration: 2s;
  animation-fill-mode: both;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.svg-animated .lefttorightbig {
  animation-name: LeftToRightBig;
  animation-duration: 3s;
  animation-fill-mode: both;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.svg-animated .toptobottom {
  animation-name: TopToBottom;
  animation-duration: 1.5s;
  animation-fill-mode: both;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.svg-animated .toptobottomslow {
  animation-name: TopToBottom;
  animation-duration: 2.5s;
  animation-fill-mode: both;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.svg-animated .toptobottomflickr {
  animation-name: TopToBottomFlickr;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.svg-animated .magnifiertiltleft {
  animation-name: MagnifierTiltLeft;
  animation-duration: 1.5s;
  animation-fill-mode: both;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.svg-animated .magnifiertiltright {
  animation-name: MagnifierTiltRight;
  animation-duration: 1.5s;
  animation-fill-mode: both;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.svg-animated .scaleupantenadot {
  animation-name: ScaleUpAntenaDot;
  animation-duration: 2s;
  animation-fill-mode: both;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.svg-animated .rotate,
.svg-animated.rotate {
  animation-name: rotate;
  animation-duration: 1.5s;
  animation-fill-mode: both;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.svg-animated .rotateslow,
.svg-animated.rotateslow {
  animation-name: rotate;
  animation-duration: 60s;
  animation-fill-mode: both;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.svg-animated .scalewidth {
  transform-origin: center bottom;
  animation: ScaleWidth 1.5s linear forwards;
}

.svg-animated .opacity1 {
  animation-name: opacity1;
  animation-duration: 1.5s;
  animation-fill-mode: both;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.svg-animated .opacity2 {
  animation-name: opacity2;
  animation-duration: 1.5s;
  animation-fill-mode: both;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.svg-animated .opacity3 {
  animation-name: opacity3;
  animation-duration: 1.5s;
  animation-fill-mode: both;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.svg-animated .tilt {
  animation-name: tilt;
  animation-duration: 1.5s;
  animation-fill-mode: both;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

/* Breathing animation for gradients */
@-webkit-keyframes BreathingAnimation {
  0% {
    background-size: 100%;
  }

  50% {
    background-size: 300%;
  }

  100% {
    background-size: 100%;
  }
}

@-moz-keyframes BreathingAnimation {
  0% {
    background-size: 100%;
  }

  50% {
    background-size: 300%;
  }

  100% {
    background-size: 100%;
  }
}

@keyframes BreathingAnimation {
  0% {
    background-size: 100%;
  }

  50% {
    background-size: 300%;
  }

  100% {
    background-size: 100%;
  }
}

// Blink animation
@keyframes blink {
  0% {
    opacity: 0.5;
  }

  25% {
    opacity: 0.7;
  }

  50% {
    opacity: 1;
  }

  75% {
    opacity: 0.7;
  }

  100% {
    opacity: 0.5;
  }
}

/* Utility classes */

/* Padding */
.u-p-1 {
  padding: 1rem 1.2rem;
}

// Router transition
.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
}

// Mapwize iframe
.mapwize {
  width: 100%;
  height: 500px;
  border: 1px solid var(--secondary-color);
}

.image-text-modal-slider__content {
  .tito-primary {
    .tito-tickets-button {
      background: var(--white-color);
      color: var(--secondary-color);
      border: 1px solid var(--secondary-color);
      font-weight: normal;
    }
  }
}

// Collision 2021
html .coll,
body .coll {
  font-family: 'Inter', sans-serif;
}

.coll {
  .btn {
    border-radius: 4px;
    font-size: 0.75rem;
    padding: 0.7rem 1.5rem;
  }

  .secondary-bg {
    a {
      color: var(--white-color);
    }
  }

  .formSection {
    &.primary-bg,
    &.secondary-bg {
      // form color overides
      .form__field.richText * {
        color: var(--white-color);
      }
      .notHero .form .form__field .form__field-label {
        color: var(--white-color);
      }
      .notHero .form .form__field select,
      .notHero .form .form__field input,
      .notHero .form .form__field textarea {
        color: var(--white-color);
        border: 1px solid var(--white-color);
      }
      .form__field .floatingLabel__label {
        color: var(--white-color);
      }
      .notHero .form__field .focused + .floatingLabel__label {
        color: var(--white-color);
      }
      input::placeholder {
        color: var(--white-color);
        opacity: 0.7;
      }
      .form__field.radio [type='radio']:checked + label,
      .form__field.radio [type='radio']:not(:checked) + label {
        color: var(--white-color);
      }
      .notHero .form__field.radio [type='radio']:checked + label:before,
      .notHero .form__field.radio [type='radio']:not(:checked) + label:before {
        border: 2px solid var(--white-color);
      }
      .notHero .form__field.radio [type='radio']:checked + label:after,
      .notHero .form__field.radio [type='radio']:not(:checked) + label:after {
        background: var(--white-color);
      }
      .notHero .form .submit {
        margin: 0;
      }
      .notHero .form .submit button {
        background: var(--white-color);
      }
      .notHero .form .submit button:hover {
        background: var(--white-color-hover);
      }
    }
    &.primary-bg {
      .notHero .form .form__field select,
      .notHero .form .form__field input,
      .notHero .form .form__field textarea {
        outline-color: var(--primary-color);
        background: var(--primary-color);
      }
      .notHero .form .submit button {
        color: var(--primary-color);
      }
      .notHero .form .submit button:hover {
        color: var(--primary-color-hover);
      }
    }
    &.secondary-bg {
      .notHero .form .form__field select,
      .notHero .form .form__field input,
      .notHero .form .form__field textarea {
        outline-color: var(--secondary-color);
        background: var(--secondary-color);
      }
      .notHero .form .submit button {
        color: var(--secondary-color);
      }
      .notHero .form .submit button:hover {
        color: var(--secondary-color-hover);
      }
    }
    &.white-bg {
      h2 {
        color: var(--primary-color);
      }
    }
    &.black-bg {
      .notHero .form .form__field .form__field-label {
        color: var(--white-color);
      }
      .form__field.radio [type='radio']:checked + label,
      .form__field.radio [type='radio']:not(:checked) + label {
        color: var(--white-color);
      }
      .notHero .form__field.radio [type='radio']:checked + label:before,
      .notHero .form__field.radio [type='radio']:not(:checked) + label:before {
        border: 2px solid var(--white-color);
      }
      .notHero .form__field.radio [type='radio']:checked + label:after,
      .notHero .form__field.radio [type='radio']:not(:checked) + label:after {
        background: var(--white-color);
      }
    }
  }

  h1,
  h2,
  h3 {
    font-family: 'korolev', sans-serif;
    text-transform: uppercase;
  }

  .black-bg h2 {
    color: var(--white-color);
  }

  .menu {
    background: #000;
    .menuItem a,
    .menuItem {
      color: var(--white-color);
      font-weight: normal;
    }
    .menu__social-icons li a {
      color: var(--white-color);
      &:hover {
        color: var(--white-color-hover);
      }
    }
  }

  .menu__ctas {
    .btn.btn-primary-transparent {
      color: var(--white-color);
    }
  }

  .two-columns-slider {
    .swiper-slide {
      h2 {
        font-family: 'korolev', sans-serif;
      }
    }
  }

  .logo-soup-slider h2 {
    font-weight: bold;
  }

  .video-section {
    h2 {
      color: #000;
    }
  }
}

@media only screen and (min-width: 768px) {
  .coll {
    .hero .container h1:not(.form__thank-you) {
      font-size: 2.8rem;
    }
    h1 {
      font-size: 2.8rem;
    }
    h2 {
      font-size: 2.6rem;
    }
    .speakers {
      .container {
        h2 {
          margin-left: -15px;
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .coll {
    .btn {
      font-size: 0.75rem !important;
      padding: 0.5rem 0.7rem !important;
    }
    .row {
      margin-left: 0;
    }
    .speakers {
      .container {
        h2 {
          padding-left: 1rem;
        }
      }
    }
  }
}
