/* Martix field */
.matrix {
    max-width: 600px;
    margin-bottom: 2rem;
    font-size: .75rem;
}
.matrix__row {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: stretch;
    width: 100%;
    border:1px solid var(--white-color);
}
.matrix__row.last {
    border-bottom: 2px solid var(--white-color);
}
.matrix__row .column {
    padding: .5rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}
@media only screen and (min-width: 768px) {
    .matrix {
        font-size: 1rem;
    }
    .matrix__row .column {
        padding: .5rem .7rem;
    }
}
.matrix__row.firstRow {
    border-left-color: transparent;
    border-right-color: var(--white-color);
    border-top-color: transparent;
}
.matrix__row.firstRow .column {
    border-top: 2px solid var(--white-color);
}
.matrix__row .column.first {
    flex: 0 0 30%;
    width: 30%;
    justify-content: flex-start;
    padding: 0 .75rem;
}
.matrix__row .column input {
    width: 25px;
    height: 25px;
    margin: 0;
    padding: 0;
    -moz-appearance: radio;
}

/* error */
.matrix__row.error {
    border-color: var(--secondary-color);
}
.matrix__row.error .column {
    border-right: 1px solid var(--secondary-color);
    border-left: 1px solid var(--secondary-color);
}
.matrix__row .column,
.matrix__row.firstRow .column,
.matrix__row.error.firstRow .column {
    border-right: 1px solid var(--white-color);
    border-left: 1px solid var(--white-color);
}
.matrix__row.firstRow .column.first,
.matrix__row.firstRow.error .column.first {
    border-left: none;
    border-top: none;
}
.matrix__row.firstRow + .matrix__row.error {
    border-top-width: 2px;
}