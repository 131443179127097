@media only screen and (max-width: 780px) {
    .backgroundShape.square {
        max-width: 150px;
        max-height: 150px;
    }
}

.backgroundShape.square.primary svg path {
    fill: var(--primary-color);
}
.backgroundShape.square.secondary svg path {
    fill: var(--secondary-color);
}
.backgroundShape.square.white svg path {
    fill: var(--white-color);
}
.backgroundShape.square.black svg path {
    fill: var(--black-color);
}
.backgroundShape.square.blue svg path {
    fill: var(--blue-color);
}