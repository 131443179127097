.steps-parent{
	position: relative;
	color: var(--bunting-color);
	background-color: var(--white-color);
	min-height:0;
	overflow: hidden;
	padding-top: 2rem;
    padding-bottom: 2rem;
}

.steps-parent .row-fixed-width {
	margin-top: 0;
}

.steps-parent h2{
	margin-top:0;
	color: var(--bunting-color);
	font-size: 1.5rem;
    margin-bottom: 1rem;
}
.steps-parent.black-bg h2 {
	color: var(--white-color);
}
.ws .steps-parent h2{
	font-family: 'alverata', serif;
}

.steps-parent .intro-text{
	display: block;
	font-size: 1rem;
}

.steps-parent__title-wrap {
	margin-bottom: 1rem;
}

.row-fixed-width{
	margin-top:20px;
}

.steps-block-wrapper{
  	overflow-x: auto;
	-webkit-overflow-scrolling: touch;
	-ms-overflow-style: -ms-autohiding-scrollbar;
	display: flex;
	align-items: flex-start;
	flex-wrap: nowrap;
}
.steps-block-wrapper.four .steps-block {
	width: 25%;
	min-width: 300px;
}
.steps-block-wrapper.four .steps-block svg {
	max-width: 100%;
}
.steps-block-wrapper.four .steps-block .steps-image {
	right: -25px;
}

.steps-block{
	width:33.3%;
	min-width: 400px;
	min-height:300px;
	flex: 0 0 auto;
	display:flex;
	justify-content: left;
	flex-wrap: wrap;
	flex-direction: row;
	padding: 0 40px;
	position: relative;
}

.steps-block .image-block{
	position: relative;
	min-height: 250px;
	width: 100%;
}

.steps-block .image-block img{
	max-height:240px;
}

.steps-block .steps-image{
	height: 60px;
	width: 50px;
	display: block;
	content: '';
	position: absolute;
	top: 100px;
	right: 0;
	animation-name: LeftToRight;
    animation-duration: 5s;
	animation:infinite;
}
.steps-block .steps-image g{
	stroke: var(--primary-color);
}

@keyframes LeftToRight {
    0% { left: 0;}
    50%{ left : -10px;}
    100%{ left: 0;}
}

.steps-block:last-of-type .image-block:after{
	display:none;
}

.steps-block span{
	padding-right:30px;
}

.steps-block h3{
	width:100%;
}

@media screen and (max-width: 767px) {
	.steps-block{
		padding: 1rem;
	}
}
@media screen and (min-width: 768px) {
	.steps-parent {
		padding-top:4rem;
		padding-bottom:4rem;
	}
	.steps-parent h2 {
		font-size: 2.5rem;
		margin-top: 30px;
		margin-bottom: 1.5rem;
	}
	.steps-parent .intro-text {
		margin-top:30px;
		line-height: 1.33;
		font-size: 24px;
	}
	.steps-parent__title-wrap {
		margin-bottom: 2rem;
	}
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
	.steps-parent h2 {
		font-size:2rem;
	}
	.steps-parent .intro-text {
		font-size:1.2rem;
	}
}

@media screen and (max-width: 520px) {
	.steps-block{
		max-width: 300px;
		width: auto;
		min-width: auto;
	}
	.steps-block .image-block svg{
		width:70%;	
	}
	.steps-block-wrapper.four .steps-block .steps-image {
		right: 25px;
	}
}

/* Bg variants */
.steps-parent.purple-bg,
.steps-parent.purple-bg h3,
.steps-parent.purple-bg h2,
.steps-parent.blue-bg,
.steps-parent.blue-bg h3,
.steps-parent.blue-bg h2,
.steps-parent.orange-bg,
.steps-parent.orange-bg h3,
.steps-parent.orange-bg h2,
.steps-parent.red-tamarillo-mauvelous,
.steps-parent.red-tamarillo-mauvelous h3,
.steps-parent.red-tamarillo-mauvelous h2  {
	color: var(--white-color);
}

.steps-parent.yellow-bg,
.steps-parent.yellow-bg h3,
.steps-parent.yellow-bg h2,
.steps-parent.white-bg,
.steps-parent.white-bg h3,
.steps-parent.white-bg h2 {
	color: var(--bunting-color);
}
.coll .steps-parent.white-bg
.coll .steps-parent.white-bg h3,
.coll .steps-parent.white-bg h2 {
	color: var(--secondary-color);
}