.agendaView {
  $e: &;
  position: relative;
  &_dates {
    padding: 1rem 1.2rem;
    background: var(--primary-color);
    transition: all 0.4s ease;
    .btn {
      padding: 0.3rem 0.5rem;
      margin-right: 0.5rem;
      font-size: 0.85rem;
      @media only screen and (min-width: 768px) {
        padding: 0.3rem 1.2rem;
        font-size: 1rem;
      }
      font-weight: 600;
      text-transform: capitalize;
      border-radius: 14px;
      background: rgba(255, 255, 255, 0.2);
      color: var(--white-color);
      border: 1px solid transparent;
      &:focus,
      &:hover {
        outline: none;
        border-color: var(--primary-color);
        color: var(--white-color);
      }
      &.active {
        background: var(--primary-color);
        color: var(--white-color);
      }
    }
  }

  @at-root .ws {
    #{$e} .tabs {
      border-bottom-color: var(--bunting-color);
      li.active:after {
        background-color: var(--bunting-color);
      }
    }
    #{$e}_dates {
      background: var(--bunting-color);
    }
  }

  &_list {
    background-color: var(--albaster-color);
    position: relative;
    @media only screen and (min-width: 768px) {
      min-height: 450px;
    }
    &:empty {
      ~ .timeslot__overlay {
        display: none;
      }
      &:after {
        content: 'There are no timeslots related to your search. Please try searching for a different term or use different filter.';
        width: 100%;
        padding: 2rem 4rem 2rem;
        font-size: 1rem;
        display: block;
        text-align: center;
      }
    }
    &Date {
      padding: 1rem 1.2rem;
      font-size: 1.2rem;
      color: var(--primary-color);
      background-color: var(--concrete-color);
      border-bottom: 1px solid #ccc;
      @media only screen and (min-width: 768px) {
        padding: 1.5rem;
        font-size: 1.25rem;
      }
    }
    &Group {
      display: flex;
    }
    @media only screen and (min-width: 768px) {
      &Timeslots,
      &Date {
        width: 60%;
      }
    }
  }

  .tabs {
    background: var(--white-color);
    padding-top: 1rem;
    transition: all 0.4s ease;
    height: 76px;
    @media only screen and (max-width: 768px) {
      .tab {
        width: 180px;
      }
    }
  }

  .timeslotGroup_timeslots {
    @media only screen and (max-width: 768px) {
      width: calc(100% - 80px)
    }
  }

  .timeslot__overlay {
    position: absolute;
    top: 139px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    max-width: initial;
    &.opened {
      z-index: 10000;
      @media only screen and (max-width: 768px) {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2147483601;
      }
      @media only screen and (min-width: 768px) {
        position: absolute;
        width: 40%;
        animation: none;
        height: calc(100% - 139px);
      }
    }
    .timeslot__overlay-content,
    .timeslot__overlay-bookTicket {
      width: 100%;
      max-width: 100%;
      border: none;
    }
    @media only screen and (min-width: 768px) {
      .timeslot__overlay-close {
        display: none;
      }
    }
    .timeslot__overlay-top svg path {
      fill: var(--primary-color);
    }
    .timeslot__overlay-topics > div:not(.timeslot__content-title) {
      background: var(--primary-color);
    }
  }

  &.tabsFixed {
    padding-top: 139px;

    .tabs,
    .swiper-container {
      position: fixed;
      z-index: 10;
      top: 0;
      left: 0;
      width: 100%;
    }
    .swiper-container {
      z-index: 10017;
      background: var(--white-color);
    }

    #{$e}_dates {
      position: fixed;
      z-index: 10;
      top: 76px;
      left: 0;
      width: 100%;
    }

    .timeslot__overlay {
      z-index: 10016;
      @media only screen and (min-width: 768px) {
        &.opened {
          width: 40%;
          height: calc(100% - 139px);
          position: fixed;
          top: 139px;
        }
        z-index: 10000;
      }
      .timeslot__overlay-content > div {
        width: 100%;
        max-width: 100%;
      }
    }
  }

  &.underSched {
    .timeslot__overlay-content > div {
      position: absolute;
      bottom: 0;
      padding-bottom: 4rem;
      transition: all 0.2s ease;
    }
    .timeslot__overlay.opened {
      position: absolute;
      z-index: 9;
    }
  }

  .swiper-container {
    margin-top: 1rem;
  }

  .swiper-slide {
    width: 150px;
    @media only screen and (min-width: 768px) {
      width: 220px;
    }
    button {
      height: 60px;
      width: 100%;
      border: none;
      position: relative;
      outline: none;
      background: var(--grey-color);
      border-radius: 50%;
      font-size: 0.85rem;
      display: flex;
      align-items: center;
      justify-content: center;
      &:before,
      &:after {
        content: '';
        position: absolute;
        top: 0;
        height: 100%;
        width: 80%;
        background: var(--grey-color);
        z-index: -1;
      }
      &:before {
        left: -10px;
        transform: skew(-20deg);
        border-top-left-radius: 10px;
      }
      &:after {
        right: -10px;
        transform: skew(20deg);
        background: var(--grey-color);
        border-top-right-radius: 10px;
      }
      .font-app-icons {
        font-family: 'app-icons';
        margin-right: 5px;
      }
    }
    &.active {
      z-index: 4;
      button {
        background: var(--bunting-color);
        color: var(--white-color);
        &:before,
        &:after {
          background: var(--bunting-color);
          z-index: -1;
        }
      }
    }
  }
}
