.htmlBlock {
    padding: 1rem 0;
    position: relative;
    overflow: hidden;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    z-index: 1;
}
.htmlBlock.vertical-center {
    display: flex;
    align-items: center;
}
.htmlBlock.vertical-bottom {
    align-items: flex-end;
    display: flex;
}
.htmlBlock h2 {
    color: inherit;
}


.htmlBlock .container {
    max-width: 960px;
}

@media only screen and (min-width: 768px) {
    .htmlBlock {
        padding: 2rem 0;
    }
    .htmlBlock .container.wide {
        max-width: 1240px;
    }
}

@media only screen and (min-width: 768px) {
    .ws .htmlBlock h1 {
        font-size: 2.5rem;
    }
}

.rc .htmlBlock.background-shape {
    padding: 8rem 0 3rem;
}

.ws .htmlBlock h2 {
    font-family: 'alverata', serif;
}
@media only screen and (min-width: 768px) {
    .rc .htmlBlock.background-shape {
        padding: 10rem 0 4rem;
    }
    .htmlBlock {
        padding: 4rem 0;
    }
}


.htmlBlock .container.plainTextPage {
    margin: initial;
    padding: 0 2rem;
    max-width: 100%;
}
.htmlBlock.plain .backgroundShape,
.htmlBlock.plain .dot-container{
    opacity: .1;
}
@media only screen and (min-width: 768px) {
    .htmlBlock .container.plainTextPage {
        margin: initial;
        padding: 0 2rem;
        max-width: 75%;
    }
    .htmlBlock.plain .backgroundShape,
    .htmlBlock.plain .dot-container{
        opacity: 1
    }
}
