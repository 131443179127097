.logoBlock-parent{
    padding-top: 70px;
    padding-bottom: 70px;
    position: relative;
}

.logoBlock-parent .logo-item{
	text-align: center;
	margin:40px 0;
	display: flex;
    align-items: center;
    justify-content: center;
}

.logoBlock-parent .logo-item img{
	max-width: 70%;
}

@media (min-width: 768px) {
    .logoBlock-parent .col-md-3 {
        -ms-flex: 0 0 20%;
        flex: 0 0 20%;
        max-width: 20%;
    }
}

@media (max-width: 576px) {
    .logoBlock-parent .col-sm-6 {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }
}

/* bg variant */
.logoBlock-parent.secondary-bg h2,
.logoBlock-parent.purple-bg h2,
.logoBlock-parent.blue-bg h2,
.logoBlock-parent.orange-bg h2 {
    color: var(--white-color);
}

.logoBlock-parent.yellow-bg h2 {
    color: var(--bunting-color);
}