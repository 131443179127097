.blog {
  &.container {
    padding-top: 1rem;
  }

  // Desktop styles
  @media only screen and (min-width: 768px) {
    &.container {
      padding-top: 3rem;
    }
  }
}
.featuredAuthors {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 1rem 0;
  @media only screen and (min-width: 768px) {
    margin: 2rem 0;
  }
  h2 {
    width: 100%;
    font-size: 1.25rem;
    margin-bottom: 0;
  }
  .metaBox {
    margin: 1rem 0;
    align-items: center;
    width: auto;
    position: relative;
  }
}
.quoteAuthor {
  text-align: right;
  color: var(--primary-color);
  margin-right: 3.5em;
  margin-top: -1em;
  font-size: 1.375rem;
  font-weight: bold;
}
